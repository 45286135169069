define('ui/components/workload-row/component', ['exports', 'ui/components/workload-row/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    scope: Ember.inject.service(),
    session: Ember.inject.service(),

    layout: _template.default,
    model: null,
    tagName: '',
    subMatches: null,
    expanded: null,

    canExpand: true,
    showInstanceCount: true,
    showImage: true,

    showLabelRow: Ember.computed.or('model.displayUserLabelStrings.length'),
    actions: {
      toggle: function toggle() {
        this.sendAction('toggle');
      }
    }
  });
});