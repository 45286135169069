define('ui/components/container/form-security/component', ['exports', 'shared/utils/parse-unit', 'shared/utils/util', 'ui/components/container/form-security/template'], function (exports, _parseUnit, _util, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var GPU_KEY = 'nvidia.com/gpu';

  exports.default = Ember.Component.extend({
    layout: _template.default,

    classNames: ['accordion-wrapper'],

    // Inputs
    instance: null,
    service: null,
    editing: true,
    isSidekick: null,

    // ----------------------------------
    capabilityChoices: null,
    // ----------------------------------
    memoryMode: 'unlimited',
    // unlimited, set
    memoryMb: null,
    // Memory
    memoryReservationMb: null,
    // ----------------------------------
    cpuMode: 'unlimited',
    // unlimited, set
    cpuMillis: null,
    // CPU
    cpuReservationMillis: null,
    // ----------------------------------
    gpuReservation: null,
    limits: Ember.computed.alias('instance.resources.limits'),
    requests: Ember.computed.alias('instance.resources.requests'),

    init: function init() {
      this._super.apply(this, arguments);

      if (!Ember.get(this, 'instance.resources')) {
        Ember.set(this, 'instance.resources', {
          requests: {},
          limits: {}
        });
      }

      if (!Ember.get(this, 'instance.resources.requests')) {
        Ember.set(this, 'instance.resources.requests', {});
      }

      if (!Ember.get(this, 'instance.resources.limits')) {
        Ember.set(this, 'instance.resources.limits', {});
      }

      this.initCapability();
      this.initMemory();
      this.initGpu();
      this.initCpu();
    },


    actions: {
      modifyCapabilities: function modifyCapabilities(type, select) {
        var options = Array.prototype.slice.call(select.target.options, 0);
        var selectedOptions = [];

        options.filterBy('selected', true).forEach(function (cap) {
          return selectedOptions.push(cap.value);
        });

        Ember.set(this, 'instance.' + type, selectedOptions);
      }
    },

    // ----------------------------------
    privilegedChanged: Ember.observer('instance.privileged', 'instance.capAdd.[]', function () {
      if (Ember.get(this, 'instance.privileged') || Ember.get(this, 'instance.capAdd') && Ember.get(this, 'instance.capAdd').indexOf('SYS_ADMIN') > -1) {
        Ember.set(this, 'instance.allowPrivilegeEscalation', true);
      }
    }),

    memoryDidChange: Ember.observer('memoryMb', 'memoryMode', function () {
      Ember.run.next(this, 'updateMemory');
    }),

    memoryReservationChanged: Ember.observer('memoryReservationMb', function () {
      var mem = Ember.get(this, 'memoryReservationMb');

      if (isNaN(mem) || mem <= 0) {
        var requests = Ember.get(this, 'instance.resources.requests');

        delete requests['memory'];
      } else {
        Ember.set(this, 'instance.resources.requests.memory', mem + 'Mi');
      }
    }),

    cpuDidChange: Ember.observer('cpuMillis', 'cpuMode', function () {
      Ember.run.next(this, 'updateCpu');
    }),

    cpuReservationChanged: Ember.observer('cpuReservationMillis', function () {
      var cpu = Ember.get(this, 'cpuReservationMillis');

      if (isNaN(cpu) || cpu <= 0) {
        var requests = Ember.get(this, 'instance.resources.requests');

        delete requests['cpu'];
      } else {
        Ember.set(this, 'instance.resources.requests.cpu', cpu + 'm');
      }
    }),

    updateGpu: Ember.observer('gpuReservation', function () {
      var gpu = Ember.get(this, 'gpuReservation');

      var requests = Ember.get(this, 'instance.resources.requests');
      var limits = Ember.get(this, 'instance.resources.limits');

      if (isNaN(gpu) || gpu <= 0) {
        delete requests[GPU_KEY];
        delete limits[GPU_KEY];
      } else {
        requests[GPU_KEY] = '' + gpu;
        limits[GPU_KEY] = '' + gpu;
      }
    }),

    // 2) has CAP_SYS_ADMIN
    allowPrivilegeEscalationDisabled: Ember.computed('instance.privileged', 'instance.capAdd.[]', function () {
      return Ember.get(this, 'instance.privileged') || Ember.get(this, 'instance.capAdd') && Ember.get(this, 'instance.capAdd').indexOf('SYS_ADMIN') > -1;
    }),

    // ----------------------------------
    // Capability
    initCapability: function initCapability() {
      Ember.set(this, 'instance.capAdd', Ember.get(this, 'instance.capAdd') || []);
      Ember.set(this, 'instance.capDrop', Ember.get(this, 'instance.capDrop') || []);
      var choices = Ember.get(this, 'store').getById('schema', 'container').optionsFor('capAdd').sort();

      Ember.set(this, 'capabilityChoices', choices);
    },


    // ----------------------------------
    // AllowPrivilegeEscalation
    // It is true always when the container is:
    // 1) run as Privileged
    // ----------------------------------
    initMemory: function initMemory() {
      var mem = Ember.get(this, 'instance.resources.limits.memory');
      var memReservation = Ember.get(this, 'instance.resources.requests.memory');

      if (memReservation) {
        Ember.set(this, 'memoryReservationMb', (0, _parseUnit.parseSi)(memReservation, 1024) / 1048576);
      } else {
        Ember.set(this, 'memoryReservationMb', '');
      }

      if (mem) {
        Ember.set(this, 'memoryMb', (0, _parseUnit.parseSi)(mem, 1024) / 1048576);
        Ember.set(this, 'memoryMode', 'set');
      } else {
        Ember.set(this, 'memoryMb', 128);
        Ember.set(this, 'memoryMode', 'unlimited');
      }
      this.updateMemory();
      this.memoryReservationChanged();
    },
    updateMemory: function updateMemory() {
      var mem = parseInt(Ember.get(this, 'memoryMb'), 10);
      var memoryMode = Ember.get(this, 'memoryMode');

      // Memory
      if (memoryMode === 'unlimited' || isNaN(mem) || mem <= 0) {
        var limits = Ember.get(this, 'instance.resources.limits');

        delete limits['memory'];

        return;
      }

      Ember.set(this, 'instance.resources.limits.memory', mem + 'Mi');
    },


    // ----------------------------------
    initCpu: function initCpu() {
      var cpu = Ember.get(this, 'instance.resources.limits.cpu');
      var cpuReservation = Ember.get(this, 'instance.resources.requests.cpu');

      Ember.set(this, 'cpuReservationMillis', (0, _util.convertToMillis)(cpuReservation));

      if (cpu) {
        Ember.set(this, 'cpuMillis', (0, _util.convertToMillis)(cpu));
        Ember.set(this, 'cpuMode', 'set');
      } else {
        Ember.set(this, 'cpuMillis', 1000);
        Ember.set(this, 'cpuMode', 'unlimited');
      }
      this.updateCpu();
      this.cpuReservationChanged();
    },
    updateCpu: function updateCpu() {
      var cpu = parseInt(Ember.get(this, 'cpuMillis'), 10);
      var cpuMode = Ember.get(this, 'cpuMode');

      if (cpuMode === 'unlimited' || isNaN(cpu) || cpu <= 0) {
        var limits = Ember.get(this, 'instance.resources.limits');

        delete limits['cpu'];

        return;
      }

      Ember.set(this, 'instance.resources.limits.cpu', cpu + 'm');
    },


    // ----------------------------------
    // GPU
    initGpu: function initGpu() {
      var gpu = (Ember.get(this, 'instance.resources.limits') || {})[GPU_KEY];

      Ember.set(this, 'gpuReservation', gpu);
      this.updateGpu();
    }
  });
});