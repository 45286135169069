define('ui/mixins/principal-reference', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    principalIdReference: Ember.computed('userPrincipalId', 'groupPrincipalId', function () {
      if (Ember.get(this, 'userPrincipalId.length') > 0) {
        return Ember.get(this, 'userPrincipalId');
      }

      if (Ember.get(this, 'groupPrincipalId.length') > 0) {
        return Ember.get(this, 'groupPrincipalId');
      }

      if (Ember.get(this, 'userId.length') > 0) {
        // TODO temp fix until craig switches PRTB/CRTP to use principalId. userId is only set for local users and only when the user creates a cluster.
        return 'local://' + Ember.get(this, 'userId');
      }
    })
  });
});