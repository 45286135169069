define('ui/mixins/stripped-name', ['exports', 'shared/mixins/stripped-name'], function (exports, _strippedName) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _strippedName.default;
    }
  });
});