define('ui/initializers/extend-ember-input', ['exports', 'ui/mixins/safe-style'], function (exports, _safeStyle) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize() /* application */{
    // Allow style to be bound on inputs
    Ember.TextField.reopen(_safeStyle.default);
    Ember.TextArea.reopen(_safeStyle.default);
    Ember.Checkbox.reopen(_safeStyle.default);

    // Disable iOS auto-capitalization
    //
    Ember.TextField.reopen({
      attributeBindings: ['autocapitalize', 'spellcheck', 'autocomplete'],
      autocomplete: 'off',
      autocapitalize: 'none'
    });
  }

  exports.default = {
    name: 'extend-ember-input',
    initialize: initialize
  };
});