define('ui/authenticated/project/help/controller', ['exports', 'ui/utils/constants'], function (exports, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    settings: Ember.inject.service(),
    scope: Ember.inject.service(),

    modelError: false,
    modelResolved: false,
    hasHosts: true,
    docsLink: Ember.computed.alias('settings.docsBase'),

    latestAnnouncement: Ember.computed('model.announcements', function () {
      if (this.get('model.announcements.topics')) {
        var sorted = this.get('model.announcements.topics').sortBy('id');
        var announcement = sorted[sorted.length - 1];

        return {
          title: announcement.title,
          link: this.get('forumsLink') + '/t/' + announcement.slug,
          created: announcement.created_at
        };
      }
    }),

    modelObserver: function () {
      if (this.get('model.resolved')) {
        // @@TODO@@ - need to add some error handling
        this.set('modelResolved', true);
      }

      if (this.get('model.error')) {
        this.set('modelError', true);
      }
    }.observes('model'),

    forumsLink: _constants.default.EXT_REFERENCES.FORUM,
    companyLink: _constants.default.EXT_REFERENCES.COMPANY,
    githubLink: _constants.default.EXT_REFERENCES.GITHUB
  });
});