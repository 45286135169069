define('ui/authenticated/project/config-maps/index/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var headers = exports.headers = [{
    name: 'state',
    sort: ['sortState', 'name', 'id'],
    type: 'string',
    searchField: 'displayState',
    translationKey: 'generic.state',
    width: 125
  }, {
    name: 'name',
    sort: ['name', 'id'],
    translationKey: 'generic.name'
  }, {
    name: 'namespace',
    translationKey: 'generic.namespace',
    searchField: 'namespace.displayName',
    sort: ['namespace.displayName', 'name', 'id']
  }, {
    name: 'keys',
    translationKey: 'configMapsPage.table.keys',
    searchField: 'keys',
    sort: ['firstKey', 'name', 'id']
  }, {
    classNames: 'text-right pr-20',
    name: 'created',
    translationKey: 'generic.created',
    sort: ['created:desc', 'name', 'id'],
    searchField: false,
    type: 'string',
    width: 150
  }];

  exports.default = Ember.Controller.extend({
    projectController: Ember.inject.controller('authenticated.project'),

    queryParams: ['sortBy'],
    sortBy: 'name',
    resource: ['configmap'],

    headers: headers,

    group: Ember.computed.alias('projectController.group'),
    groupTableBy: Ember.computed.alias('projectController.groupTableBy'),

    rows: function () {
      return Ember.get(this, 'model.configMaps').filterBy('type', 'configMap');
    }.property('model.configMaps.[].type')
  });
});