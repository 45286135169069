define('ui/components/volume-source/source-config-map/component', ['exports', 'ui/components/volume-source/source-config-map/template', 'shared/mixins/volume-source'], function (exports, _template, _volumeSource) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_volumeSource.default, {
    layout: _template.default,
    field: 'configMap',

    specific: false,
    defaultMode: null,

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      if (!!Ember.get(this, 'config.items')) {
        Ember.set(this, 'specific', true);
      }

      var modeStr = Ember.get(this, 'config.defaultMode');

      if (modeStr) {
        Ember.set(this, 'defaultMode', new Number(modeStr).toString(8));
      } else {
        Ember.set(this, 'defaultMode', '400');
      }
    },


    specificDidChange: Ember.observer('specific', function () {
      if (!Ember.get(this, 'specific')) {
        Ember.set(this, 'config.items', null);
      }
    }),

    modeDidChange: Ember.observer('defaultMode', function () {
      var octal = Ember.get(this, 'defaultMode') || '0';

      Ember.set(this, 'config.defaultMode', parseInt(octal, 8));
    })
  });
});