define('ui/components/identity-avatar/component', ['exports', 'ui/components/identity-avatar/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    identity: null,
    link: true,
    size: 35,

    classNames: ['gh-avatar'],
    attributeBindings: ['aria-label:identity.name'],

    avatarSrc: Ember.computed.alias('identity.avatarSrc'),
    url: Ember.computed.alias('identity.profileUrl')
  });
});