define('ui/models/typedocumentation', ['exports', '@rancher/ember-api-store/utils/normalize', '@rancher/ember-api-store/models/resource'], function (exports, _normalize, _resource) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var TypeDocumentation = _resource.default.extend();

  TypeDocumentation.reopenClass({
    mangleIn: function mangleIn(data) {
      // Pass IDs through the type normalizer so they will match the case in other places like store.find('schema',normalizeType('thing'))
      data.id = (0, _normalize.normalizeType)(data.id);

      return data;
    }
  });

  exports.default = TypeDocumentation;
});