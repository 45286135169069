define('ui/instance-initializers/theme', ['exports', 'ui/utils/constants'], function (exports, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(instance) {
    var session = instance.lookup('service:session');
    var userTheme = instance.lookup('service:user-theme');
    var theme = session.get(_constants.default.PREFS.THEME) || _constants.default.THEME.DEFAULT;

    userTheme.setTheme(theme, false);
  }

  exports.default = {
    name: 'theme',
    initialize: initialize
  };
});