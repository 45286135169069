define('ui/utils/navigation-tree', ['exports', 'shared/utils/navigation-tree'], function (exports, _navigationTree) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'bulkAdd', {
    enumerable: true,
    get: function () {
      return _navigationTree.bulkAdd;
    }
  });
  Object.defineProperty(exports, 'getProjectId', {
    enumerable: true,
    get: function () {
      return _navigationTree.getProjectId;
    }
  });
  Object.defineProperty(exports, 'getClusterId', {
    enumerable: true,
    get: function () {
      return _navigationTree.getClusterId;
    }
  });
  Object.defineProperty(exports, 'addItem', {
    enumerable: true,
    get: function () {
      return _navigationTree.addItem;
    }
  });
  Object.defineProperty(exports, 'removeId', {
    enumerable: true,
    get: function () {
      return _navigationTree.removeId;
    }
  });
  Object.defineProperty(exports, 'get', {
    enumerable: true,
    get: function () {
      return _navigationTree.get;
    }
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _navigationTree.default;
    }
  });
});