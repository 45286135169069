define('ui/authenticated/project/security/members/new/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    globalStore: Ember.inject.service(),
    roleTemplateService: Ember.inject.service('roleTemplate'),

    model: function model() {
      var gs = Ember.get(this, 'globalStore');
      var pid = this.paramsFor('authenticated.project');

      return Ember.RSVP.hash({
        project: gs.find('project', pid.project_id, { forceReload: true }),
        roles: Ember.get(this, 'roleTemplateService').get('allFilteredRoleTemplates'),
        roleBindings: gs.findAll('projectRoleTemplateBinding')
      });
    }
  });
});