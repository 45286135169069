define('ui/authenticated/project/config-maps/new/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['id'],
    id: null,

    actions: {
      back: function back() {
        this.transitionToRoute('authenticated.project.config-maps');
      }
    }
  });
});