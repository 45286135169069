define('ui/components/namespace-list/component', ['exports', 'ui/components/namespace-list/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.headers = undefined;
  var headers = exports.headers = [{
    name: 'state',
    sort: ['sortState', 'displayName'],
    searchField: 'displayState',
    translationKey: 'generic.state',
    width: 120
  }, {
    name: 'name',
    sort: ['sortName', 'id'],
    searchField: 'displayName',
    translationKey: 'namespacesPage.table.name.label'
  }, {
    name: 'project',
    sort: ['project.sortName', 'id'],
    searchField: 'project.displayName',
    translationKey: 'namespacesPage.table.project.label'
  }, {
    classNames: 'text-right pr-20',
    name: 'created',
    sort: ['created', 'id'],
    searchField: false,
    translationKey: 'namespacesPage.table.created.label',
    width: 250
  }];

  exports.default = Ember.Component.extend({
    scope: Ember.inject.service(),

    layout: _template.default,
    sortBy: 'name',
    descending: false,
    headers: headers,
    rows: Ember.computed.alias('model')
  });
});