define('ui/components/form-node-affinity/component', ['exports', 'ui/components/form-node-affinity/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    intl: Ember.inject.service(),

    layout: _template.default,

    model: null,

    rules: null,
    editing: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.initRules();
    },


    actions: {
      addRule: function addRule() {
        var rule = Ember.Object.create({});

        Ember.get(this, 'rules').pushObject(rule);
      },
      removeRule: function removeRule(rule) {
        Ember.get(this, 'rules').removeObject(rule);
      }
    },

    rulesChanged: Ember.observer('rules.@each.matchExpressions', function () {
      var out = (Ember.get(this, 'rules') || []).filter(function (rule) {
        return rule.matchExpressions && rule.matchExpressions.length > 0;
      });

      this.sendAction('changed', { nodeSelectorTerms: out });
    }),

    initRules: function initRules() {
      var rules = [];

      (Ember.get(this, 'model.nodeSelectorTerms') || []).forEach(function (term) {
        rules.push({ matchExpressions: term.matchExpressions });
      });

      Ember.set(this, 'rules', rules);
    }
  });
});