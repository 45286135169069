define('ui/components/cru-storage-class/component', ['exports', 'shared/mixins/view-new-edit', 'ui/components/cru-storage-class/template', 'ui/models/storageclass', 'shared/mixins/child-hook'], function (exports, _viewNewEdit, _template, _storageclass, _childHook) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_viewNewEdit.default, _childHook.default, {
    intl: Ember.inject.service(),

    layout: _template.default,
    model: null,

    titleKey: 'cruStorageClass.title',

    didReceiveAttrs: function didReceiveAttrs() {
      Ember.set(this, 'wasRecycle', Ember.get(this, 'primaryResource.reclaimPolicy') === 'Recycle');
    },


    actions: {
      updateParams: function updateParams(map) {
        Ember.set(this, 'primaryResource.parameters', map);
      },
      updateOptions: function updateOptions(ary) {
        Ember.set(this, 'primaryResource.mountOptions', ary);
      }
    },

    paramsComponent: Ember.computed('primaryResource.provisioner', function () {
      var provisioner = Ember.get(this, 'primaryResource.provisioner');
      var entry = (0, _storageclass.getProvisioners)().findBy('value', provisioner);
      var component = 'generic';

      if (entry && entry.component) {
        component = entry.component;
      }

      return 'storage-class/provisioner-' + component;
    }),

    provisionerChoices: Ember.computed('intl.locale', function () {
      var intl = Ember.get(this, 'intl');
      var out = (0, _storageclass.getProvisioners)().map(function (p) {
        var entry = Object.assign({}, p);
        var key = 'storageClass.' + entry.name + '.title';

        if (intl.exists(key)) {
          entry.label = intl.t(key);
          entry.priority = 1;
        } else {
          entry.label = entry.name;
          entry.priority = 2;
        }

        return entry;
      });

      return out.sortBy('priority', 'label');
    }),
    willSave: function willSave() {
      var _arguments = arguments;

      var self = this;
      var sup = this._super;

      return this.applyHooks().then(function () {
        return sup.apply.apply(sup, [self].concat(Array.prototype.slice.call(_arguments)));
      });
    },
    doneSaving: function doneSaving() {
      this.sendAction('cancel');
    }
  });
});