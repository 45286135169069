define('ui/mixins/intl-placeholder', ['exports', 'shared/mixins/intl-placeholder'], function (exports, _intlPlaceholder) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _intlPlaceholder.default;
    }
  });
});