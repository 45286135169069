define('ui/authenticated/project/registries/detail/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(params) {
      var all = this.modelFor('authenticated.project.registries');

      var registry = all.projectDockerCredentials.findBy('id', params.registry_id);

      if (registry) {
        return registry;
      }

      registry = all.namespacedDockerCredentials.findBy('id', params.registry_id);
      if (registry) {
        return registry;
      }

      return Ember.get(this, 'store').find('dockerCredential', params.registry_id);
    }
  });
});