define('ui/mixins/state-counts', ['exports', 'shared/mixins/state-counts'], function (exports, _stateCounts) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _stateCounts.default;
    }
  });
});