define('ui/initializers/inject-application', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(application) {
    // Injects all Ember components & models (for actions) with the application controler, for modals
    application.inject('component', 'application', 'controller:application');
    application.inject('model', 'application', 'controller:application');
  }

  exports.default = {
    name: 'inject-application',
    initialize: initialize
  };
});