define('ui/custom-drivers/cluster-drivers/controller', ['exports', 'nodes/custom-drivers/cluster-drivers/controller'], function (exports, _controller) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _controller.default;
    }
  });
});