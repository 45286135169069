define('ui/components/table-labels/component', ['exports', 'shared/mixins/manage-labels', 'ui/components/table-labels/template'], function (exports, _manageLabels, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_manageLabels.default, {
    layout: _template.default,
    model: null,

    sortBy: 'kind',
    showKind: true,
    descending: true,
    labelIntlSec: 'labelsSection',
    headers: [{
      name: 'key',
      sort: ['key'],
      translationKey: 'labelsSection.key',
      width: '350'
    }, {
      name: 'value',
      sort: ['value', 'key'],
      translationKey: 'labelsSection.value'
    }],

    labelSource: Ember.computed.alias('model'),
    didReceiveAttrs: function didReceiveAttrs() {
      this.initLabels(this.get('labelSource'));
    },

    labelsObserver: Ember.observer('model', function () {
      this.initLabels(this.get('labelSource'));
    })

  });
});