define('ui/components/container/form-networking/component', ['exports', 'ui/components/container/form-networking/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,

    // Inputs
    instance: null,
    service: null,
    errors: null,
    editing: null,

    initHostAliasesArray: [],
    initOptionsArray: [],

    classNames: ['accordion-wrapper'],

    init: function init() {
      this._super.apply(this, arguments);
      this.initHostAliases();
      this.initOptions();
    },


    actions: {
      hostAliasesChanged: function hostAliasesChanged(hostAliases) {
        var out = [];

        hostAliases.filter(function (alias) {
          return alias.value && alias.key;
        }).forEach(function (alias) {
          out.push({
            hostnames: [alias.value],
            ip: alias.key
          });
        });
        Ember.set(this, 'service.hostAliases', out);
      },
      optionsChanged: function optionsChanged(options) {
        var out = [];

        options.filter(function (option) {
          return Ember.get(option, 'key') && Ember.get(option, 'value');
        }).forEach(function (option) {
          out.push({
            name: Ember.get(option, 'key'),
            value: Ember.get(option, 'value')
          });
        });

        var dnsConfig = Ember.get(this, 'service.dnsConfig');

        if (!dnsConfig) {
          Ember.set(this, 'service.dnsConfig', { options: out });
        } else {
          Ember.set(this, 'service.dnsConfig.options', out);
        }
      },
      updateNameservers: function updateNameservers(nameservers) {
        var dnsConfig = Ember.get(this, 'service.dnsConfig');

        if (!dnsConfig) {
          Ember.set(this, 'service.dnsConfig', { nameservers: nameservers });
        } else {
          Ember.set(this, 'service.dnsConfig.nameservers', nameservers);
        }
      },
      updateSearches: function updateSearches(searches) {
        var dnsConfig = Ember.get(this, 'service.dnsConfig');

        if (!dnsConfig) {
          Ember.set(this, 'service.dnsConfig', { searches: searches });
        } else {
          Ember.set(this, 'service.dnsConfig.searches', searches);
        }
      }
    },

    initHostAliases: function initHostAliases() {
      var _this = this;

      var aliases = Ember.get(this, 'service.hostAliases');

      Ember.set(this, 'initHostAliasesArray', []);
      (aliases || []).forEach(function (alias) {
        (alias.hostnames || []).forEach(function (hostname) {
          Ember.get(_this, 'initHostAliasesArray').push({
            key: alias.ip,
            value: hostname
          });
        });
      });
    },
    initOptions: function initOptions() {
      var _this2 = this;

      var options = Ember.get(this, 'service.dnsConfig.options');

      Ember.set(this, 'initOptionsArray', []);
      (options || []).forEach(function (option) {
        Ember.get(_this2, 'initOptionsArray').push({
          key: Ember.get(option, 'name'),
          value: Ember.get(option, 'value')
        });
      });
    }
  });
});