define('ui/models/publicendpoint', ['exports', '@rancher/ember-api-store/models/resource', 'shared/utils/constants'], function (exports, _resource, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function portMatch(ports, equals, endsWith) {
    if (!Ember.isArray(ports)) {
      ports = [ports];
    }

    if (!Ember.isArray(equals)) {
      equals = [equals];
    }

    if (!Ember.isArray(endsWith)) {
      endsWith = [endsWith];
    }

    for (var i = 0; i < ports.length; i++) {
      var port = ports[i];

      if (equals.includes(port)) {
        return true;
      }

      for (var j = 0; j < endsWith.length; j++) {
        var suffix = '' + endsWith[j];
        var portStr = '' + port;

        if (portStr !== suffix && portStr.endsWith(suffix)) {
          return true;
        }
      }
    }

    return false;
  }

  var PublicEndpoint = _resource.default.extend({
    globalStore: Ember.inject.service(),
    scope: Ember.inject.service(),
    settings: Ember.inject.service(),

    portProto: Ember.computed('port', 'protocol', function () {
      var out = Ember.get(this, 'port') + '/' + Ember.get(this, 'protocol').toLowerCase();

      return out;
    }),

    // ip:port
    endpoint: Ember.computed('port', 'addresses', 'allNodes', 'isIngress', 'hostname', function () {
      var addresses = Ember.get(this, 'addresses');
      var allNodes = Ember.get(this, 'allNodes');
      var hostname = Ember.get(this, 'hostname') || '';

      var out = '';

      if (Ember.get(this, 'isIngress') && hostname !== '') {
        out = hostname;
      } else if (addresses && addresses.length) {
        out = addresses[0];
      } else if (allNodes) {
        var globalStore = Ember.get(this, 'globalStore');
        var nodes = globalStore.all('node').filterBy('clusterId', Ember.get(this, 'scope.currentCluster.id'));
        var node = nodes.findBy('externalIpAddress');

        if (node) {
          out = Ember.get(node, 'externalIpAddress');
        } else {
          node = nodes.findBy('ipAddress');
          if (node) {
            out = Ember.get(node, 'ipAddress');
          }
        }
      }

      if (out) {
        out += ':' + Ember.get(this, 'port');
      }

      return out;
    }),

    // port[/udp]
    displayEndpoint: Ember.computed('port', 'protocol', 'path', function () {
      var path = Ember.get(this, 'path') || '';

      if (path && path !== '/') {
        return path;
      }

      var out = '';

      out += Ember.get(this, 'port');
      var proto = Ember.get(this, 'protocol').toLowerCase();

      out += '/' + proto;

      return out;
    }),

    linkEndpoint: Ember.computed('isTcpish', 'isMaybeSecure', 'displayEndpoint', 'port', 'isIngress', 'path', function () {
      var path = Ember.get(this, 'path') || '';

      if (Ember.get(this, 'isTcpish') && Ember.get(this, 'port') > 0) {
        var out = Ember.get(this, 'endpoint');

        if (Ember.get(this, 'isMaybeSecure')) {
          out = 'https://' + out.replace(/:443$/, '');
        } else {
          out = 'http://' + out.replace(/:80$/, '');
        }

        if (Ember.get(this, 'isIngress')) {
          out = out + path;
        }

        return out;
      }
    }),

    isTcpish: Ember.computed('protocol', function () {
      var proto = Ember.get(this, 'protocol').toLowerCase();

      return ['tcp', 'http', 'https'].includes(proto);
    }),

    isMaybeSecure: Ember.computed('port', 'protocol', function () {
      var proto = Ember.get(this, 'protocol').toLowerCase();

      return portMatch([Ember.get(this, 'port')], [443, 8443], '443') || proto === 'https';
    }),

    isIngress: Ember.computed('ingressId', function () {
      return Ember.get(this, 'ingressId') !== '' && Ember.get(this, 'ingressId') !== null;
    }),

    isReady: Ember.computed('hostname', function () {
      var xip = Ember.get(this, 'settings.' + _constants.default.SETTING.INGRESS_IP_DOMAIN);
      var hostname = Ember.get(this, 'hostname') || '';

      if (Ember.get(this, 'isIngress')) {
        if (xip === hostname) {
          return false;
        }
      }

      return true;
    })
  });

  exports.default = PublicEndpoint;
});