define('ui/authenticated/cluster/nodes/index/controller', ['exports', 'ui/components/node-row/component'], function (exports, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    growl: Ember.inject.service(),
    prefs: Ember.inject.service(),
    scope: Ember.inject.service(),
    capabilities: Ember.inject.service(),

    queryParams: ['sortBy'],
    sortBy: 'name',
    searchText: '',
    headers: _component.headersCluster,

    extraSearchFields: ['displayUserLabelStrings', 'requireAnyLabelStrings'],

    actions: {
      scaleDownPool: function scaleDownPool(id) {
        Ember.get(this, 'model.cluster').send('scaleDownPool', id);
      },
      scaleUpPool: function scaleUpPool(id) {
        Ember.get(this, 'model.cluster').send('scaleUpPool', id);
      },
      editCluster: function editCluster() {
        Ember.get(this, 'model.cluster').send('edit');
      }
    },

    groupByKey: Ember.computed('model.cluster.nodePools.length', function () {
      if (Ember.get(this, 'model.cluster.nodePools.length')) {
        return 'nodePoolId';
      }

      return null;
    }),

    rows: Ember.computed('model.nodes.@each.clusterId', function () {
      return Ember.get(this, 'model.nodes').filterBy('clusterId', Ember.get(this, 'model.cluster.id'));
    })
  });
});