define('ui/models/globalrolebinding', ['exports', '@rancher/ember-api-store/models/resource', '@rancher/ember-api-store/utils/denormalize'], function (exports, _resource, _denormalize) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _resource.default.extend({
    canRemove: false,
    globalRole: (0, _denormalize.reference)('globalRoleId', 'globalRole')
  });
});