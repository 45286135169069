define('ui/authenticated/project/security/members/edit/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    globalStore: Ember.inject.service(),

    model: function model(params) {
      var store = Ember.get(this, 'globalStore');

      return Ember.RSVP.hash({
        role: store.find('projectroletemplatebinding', params.role_id),
        roles: store.find('roletemplate', null, {
          filter: {
            hidden: false,
            context: 'cluster'
          }
        }),
        policies: store.find('podsecuritypolicytemplate')
      });
    }
  });
});