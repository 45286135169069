define('ui/authenticated/cluster/storage/persistent-volumes/new/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    actions: {
      back: function back() {
        this.transitionToRoute('authenticated.cluster.storage.persistent-volumes');
      }
    }
  });
});