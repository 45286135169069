define('ui/models/persistentvolumeclaim', ['exports', '@rancher/ember-api-store/models/resource', '@rancher/ember-api-store/utils/denormalize', 'shared/utils/parse-unit'], function (exports, _resource, _denormalize, _parseUnit) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var PersistentVolumeClaim = _resource.default.extend({
    clusterStore: Ember.inject.service(),
    type: 'persistentVolumeClaim',
    canEdit: false,

    storageClass: (0, _denormalize.reference)('storageClassId', 'storageClass', 'clusterStore'),
    persistentVolume: (0, _denormalize.reference)('volumeId', 'persistentVolume', 'clusterStore'),
    namespace: (0, _denormalize.reference)('namespaceId', 'namespace', 'clusterStore'),

    sizeBytes: Ember.computed('status.capacity.storage', function () {
      var str = Ember.get(this, 'status.capacity.storage');

      if (str) {
        return (0, _parseUnit.parseSi)(str, 1024);
      }
    }),

    displaySize: Ember.computed('sizeBytes', function () {
      var bytes = Ember.get(this, 'sizeBytes');

      if (bytes) {
        return (0, _parseUnit.formatSi)(bytes, 1024, 'iB', 'B');
      }
    })
  });

  exports.default = PersistentVolumeClaim;
});