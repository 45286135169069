define('ui/catalog-tab/launch/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['appId', 'appName', 'stackId', 'upgrade', 'catalog', 'namespaceId', 'clone'],
    stackId: null,
    upgrade: null,
    showName: true,
    catalog: null,
    namespaceId: null,
    appId: null,
    appName: null,

    parentRoute: 'catalog-tab',

    actions: {
      cancel: function cancel() {
        this.send('goToPrevious', 'apps-tab.index');
      }
    }
  });
});