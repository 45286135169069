define('ui/initializers/polyfill-svg', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize() /* application*/{
    // http://jointjs.com/blog/get-transform-to-element-polyfill.html
    if (!SVGElement.prototype.getTransformToElement) {
      SVGElement.prototype.getTransformToElement = function (toElement) {
        return toElement.getScreenCTM().inverse().multiply(this.getScreenCTM());
      };
    }
  }

  exports.default = {
    name: 'polyfill-svg',
    initialize: initialize
  };
});