define('ui/custom-drivers/index/route', ['exports', 'nodes/custom-drivers/index/route'], function (exports, _route) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _route.default;
    }
  });
});