define('ui/apps-tab/detail/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    catalog: Ember.inject.service(),
    store: Ember.inject.service(),

    model: function model(params) {
      var store = Ember.get(this, 'store');

      return Ember.RSVP.hash({ app: store.find('app', Ember.get(params, 'app_id')) });
    }
  });
});