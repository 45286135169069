define('ui/update-password/controller', ['exports', 'ui/utils/constants'], function (exports, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    router: Ember.inject.service(),
    access: Ember.inject.service(),
    settings: Ember.inject.service(),

    showCurrent: null,

    firstLogin: Ember.computed.alias('access.firstLogin'),

    init: function init() {
      this._super.apply(this, arguments);
      Ember.set(this, 'showCurrent', !Ember.get(this, 'access.userCode.password'));
    },


    currentPassword: Ember.computed('', function () {
      return Ember.get(this, 'access.userCode.password') || null;
    }),

    complete: function complete(success) {
      var backTo = Ember.get(this, 'session').get(_constants.default.SESSION.BACK_TO);
      var router = Ember.get(this, 'router');

      if (success) {
        if (Ember.get(this, 'firstLogin')) {
          var value = Ember.get(this, 'model.optIn') ? 'in' : 'out';

          Ember.get(this, 'settings').set(_constants.default.SETTING.TELEMETRY, value);
        }

        Ember.get(this, 'access').set('firstLogin', false);
        Ember.get(this, 'access').set('userCode', null);

        if (backTo && backTo !== window.location.origin) {
          // console.log('Going back to', backTo);
          window.location.href = backTo;
        } else {
          // console.log('Replacing Authenticated');
          router.replaceWith('authenticated');
        }
      }
    }
  });
});