define('ui/components/modal-drain-node/component', ['exports', 'shared/mixins/modal-base', 'ui/components/modal-drain-node/template', 'async'], function (exports, _modalBase, _template, _async) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_modalBase.default, {
    growl: Ember.inject.service(),

    layout: _template.default,
    classNames: ['large-modal'],

    aggressive: false,
    usePodGracePeriod: true,
    gracePeriod: 30,
    unlimitedTimeout: false,
    timeout: 60,

    resources: Ember.computed.alias('modalService.modalOpts.resources'),

    actions: {
      drain: function drain() {
        var aggressive = Ember.get(this, 'aggressive');

        var opts = {
          deleteLocalData: aggressive,
          force: aggressive,
          ignoreDaemonSets: true,
          gracePeriod: Ember.get(this, 'usePodGracePeriod') ? -1 : Ember.get(this, 'gracePeriod'),
          timeout: Ember.get(this, 'unlimitedTimeout') ? 0 : Ember.get(this, 'timeout')
        };

        var resources = Ember.get(this, 'resources').slice();

        (0, _async.eachLimit)(resources, 5, function (resource, cb) {
          if (!resource) {
            return cb();
          }

          resource.doAction('drain', opts).finally(cb);
        });

        this.send('cancel');
      }
    }
  });
});