define('ui/store-reset/service', ['exports', 'shared/store-reset/service'], function (exports, _service) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _service.default;
    }
  });
});