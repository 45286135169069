define('ui/containers/index/route', ['exports', 'ui/utils/constants'], function (exports, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    globalStore: Ember.inject.service(),

    model: function model() {
      var store = this.get('store');
      var globalStore = this.get('globalStore');

      return Ember.RSVP.hash({
        workloads: store.findAll('workload'),
        pods: store.findAll('pod'),
        nodes: globalStore.findAll('node')
      });
    },


    setDefaultRoute: Ember.on('activate', function () {
      Ember.set(this, 'session.' + _constants.default.SESSION.CONTAINER_ROUTE, 'containers');
      Ember.set(this, 'session.' + _constants.default.SESSION.PROJECT_ROUTE, undefined);
    })
  });
});