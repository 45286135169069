define('ui/authenticated/project/secrets/index/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    prefs: Ember.inject.service(),
    scope: Ember.inject.service(),
    projectController: Ember.inject.controller('authenticated.project'),

    queryParams: ['sortBy'],
    sortBy: 'name',
    resource: ['namespacedsecret', 'secret'],

    headers: [{
      name: 'state',
      sort: ['sortState', 'name', 'id'],
      type: 'string',
      searchField: 'displayState',
      translationKey: 'generic.state',
      width: 125
    }, {
      name: 'name',
      sort: ['name', 'id'],
      translationKey: 'generic.name'
    }, {
      name: 'namespace',
      translationKey: 'generic.namespace',
      searchField: 'namespace.displayName',
      sort: ['namespace.displayName', 'name', 'id']
    }, {
      name: 'keys',
      translationKey: 'secretsPage.table.keys',
      searchField: 'keys',
      sort: ['firstKey', 'name', 'id']
    }, {
      classNames: 'text-right pr-20',
      name: 'created',
      translationKey: 'generic.created',
      sort: ['created:desc', 'name', 'id'],
      searchField: false,
      type: 'string',
      width: 150
    }],

    group: Ember.computed.alias('projectController.group'),
    groupTableBy: Ember.computed.alias('projectController.groupTableBy'),

    rows: function () {
      var proj = Ember.get(this, 'model.projectSecrets').filterBy('type', 'secret');
      var ns = Ember.get(this, 'model.namespacedSecrets').filterBy('type', 'namespacedSecret');
      var out = proj.concat(ns);

      return out;
    }.property('model.projectSecrets.[].type', 'model.namespacedSecrets.[].type')
  });
});