define('ui/components/node-selector/component', ['exports', 'ui/components/node-selector/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,

    title: null,
    rules: null,
    ruleArray: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.initRuleArray();
    },


    actions: {
      addRule: function addRule(custom) {
        var newRule = custom ? { custom: '' } : {
          key: '',
          operator: '=',
          value: ''
        };

        this.get('ruleArray').pushObject(newRule);
      },
      removeRule: function removeRule(rule) {
        this.get('ruleArray').removeObject(rule);
      }
    },

    inputChanged: Ember.observer('ruleArray.@each.{key,value,operator,custom}', function () {
      var _this = this;

      this.set('rules', this.get('ruleArray').filter(function (r) {
        return _this.isRuleValid(r);
      }).map(function (r) {
        return _this.convertRule(r);
      }));
    }),

    initRuleArray: function initRuleArray() {
      var rules = this.get('rules') || [];
      var ruleArray = rules.map(function (rule) {
        return { custom: rule };
      });

      this.set('ruleArray', ruleArray);
    },
    isRuleValid: function isRuleValid(rule) {
      if (rule.operator === 'Exists' || rule.operator === 'DoesNotExist') {
        return rule.key;
      } else {
        return rule.custom || rule.value && rule.key && rule.operator;
      }
    },
    convertRule: function convertRule(rule) {
      if (rule.custom) {
        return rule.custom;
      }
      switch (rule.operator) {
        case 'Exists':
          return rule.key;
        case 'DoesNotExist':
          return '!' + rule.key;
        case 'In':
          return rule.key + ' in (' + rule.value + ')';
        case 'NotIn':
          return rule.key + ' not in (' + rule.value + ')';
        default:
          return rule.key + ' ' + rule.operator + ' ' + rule.value;
      }
    }
  });
});