define('ui/components/resource-quota-select/component', ['exports', 'ui/utils/constants', 'ui/components/resource-quota-select/template'], function (exports, _constants, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,

    resourceChoices: null,
    allResourceChoices: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.initResourceChoices();
    },


    currentQuotaDidChange: Ember.observer('currentQuota.@each.key', function () {
      var _this = this;

      Ember.set(this, 'resourceChoices', Ember.get(this, 'allResourceChoices').filter(function (choice) {
        return _this.doesExist(choice);
      }));
    }),

    doesExist: function doesExist(choice) {
      return Ember.get(choice, 'value') === Ember.get(this, 'quota.key') || !(Ember.get(this, 'currentQuota') || []).findBy('key', Ember.get(choice, 'value'));
    },
    initResourceChoices: function initResourceChoices() {
      var _this2 = this;

      var choices = [];

      _constants.default.RESOURCE_QUOTAS.forEach(function (key) {
        choices.push({
          label: 'formResourceQuota.resources.' + key,
          value: key
        });
      });

      Ember.set(this, 'allResourceChoices', choices);

      Ember.set(this, 'resourceChoices', choices.filter(function (choice) {
        return _this2.doesExist(choice);
      }));

      if (Ember.get(this, 'resourceChoices.length') && !Ember.get(this, 'quota.key')) {
        Ember.run.next(function () {
          Ember.set(_this2, 'quota.key', Ember.get(_this2, 'resourceChoices.firstObject.value'));
        });
      }
    }
  });
});