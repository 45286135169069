define('ui/components/settings/table-rows/component', ['exports', 'ui/utils/constants', 'ui/components/settings/table-rows/template'], function (exports, _constants, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var TABLE_COUNTS = [{ value: '10' }, { value: '25' }, { value: '50' }, { value: '100' }, { value: '250' }, { value: '500' }, { value: '1000' }];

  exports.default = Ember.Component.extend({
    prefs: Ember.inject.service(),
    layout: _template.default,
    tableCounts: TABLE_COUNTS,
    selectedCount: null,

    perPage: Ember.computed.alias('prefs.tablePerPage'),
    init: function init() {
      this._super.apply(this, arguments);
      this.set('selectedCount', '' + this.get('perPage'));
    },


    actions: {
      save: function save(cb) {
        this.set('prefs.' + _constants.default.PREFS.TABLE_COUNT, parseInt(this.get('selectedCount'), 10));
        cb(true);
        this.sendAction('done');
      }
    }
  });
});