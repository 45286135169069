define('ui/components/dns-row/component', ['exports', 'ui/components/dns-row/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    scope: Ember.inject.service(),
    session: Ember.inject.service(),

    layout: _template.default,
    model: null,
    tagName: ''
  });
});