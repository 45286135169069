define('ui/components/settings/theme-toggle/component', ['exports', 'ui/utils/constants', 'ui/components/settings/theme-toggle/template'], function (exports, _constants, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    prefs: Ember.inject.service(),
    userTheme: Ember.inject.service('user-theme'),

    layout: _template.default,
    actions: {
      changeTheme: function changeTheme(theme) {
        var userTheme = this.get('userTheme');
        var currentTheme = userTheme.getTheme();

        if (theme !== currentTheme) {
          userTheme.setTheme(theme);
        }
      }
    },

    theme: Ember.computed('prefs.' + _constants.default.PREFS.THEME, function () {
      return this.get('prefs.' + _constants.default.PREFS.THEME);
    })

  });
});