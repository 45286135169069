define('ui/utils/load-script', ['exports', 'shared/utils/load-script'], function (exports, _loadScript) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'loadScript', {
    enumerable: true,
    get: function () {
      return _loadScript.loadScript;
    }
  });
});