define('ui/models/target', ['exports', '@rancher/ember-api-store/models/resource', '@rancher/ember-api-store/utils/denormalize'], function (exports, _resource, _denormalize) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _resource.default.extend({
    project: (0, _denormalize.reference)('projectId'),

    projectName: Ember.computed('project', function () {
      return Ember.get(this, 'project.displayName');
    }),

    clusterName: Ember.computed('project.cluster', function () {
      return Ember.get(this, 'project.cluster.displayName');
    }),

    clusterId: Ember.computed('projectId', function () {
      var projectId = this.projectId;


      return projectId.split(':')[0];
    }),

    appLink: Ember.computed('projectId', 'appId', function () {
      var projectId = this.projectId;


      if (projectId) {
        return projectId.split(':')[1] + ':' + this.appId;
      }

      return null;
    })

  });
});