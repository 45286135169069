define('ui/components/new-password/component', ['exports', 'ui/components/new-password/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    newPassword: null,
    confirmPassword: null,
    passwordOkay: false,
    passwordOut: null,
    passwordsMatch: Ember.computed('newPassword', 'confirmPassword', function () {
      if (this.get('confirmPassword')) {
        if (this.get('newPassword') === this.get('confirmPassword')) {
          this.set('passwordOut', this.get('newPassword'));
          this.set('passwordOkay', true);

          return true;
        } else {
          this.set('passwordOut', null);
          this.set('passwordOkay', false);

          return false;
        }
      } else {
        this.set('passwordOkay', false);

        return true;
      }
    })
  });
});