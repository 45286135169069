define('ui/components/cru-volume/component', ['exports', 'shared/mixins/view-new-edit', 'ui/components/cru-volume/template', 'ui/models/volume'], function (exports, _viewNewEdit, _template, _volume) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_viewNewEdit.default, {
    intl: Ember.inject.service(),

    layout: _template.default,
    model: null,
    sourceName: null,

    titleKey: 'cruVolume.title',

    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      var selectedSource = (Ember.get(this, 'sourceChoices') || []).find(function (source) {
        return !!Ember.get(_this, 'primaryResource.' + Ember.get(source, 'value'));
      });

      if (selectedSource) {
        Ember.set(this, 'sourceName', Ember.get(selectedSource, 'name'));
      }
    },


    actions: {
      updateParams: function updateParams(key, map) {
        var _this2 = this;

        (0, _volume.getSources)('ephemeral').forEach(function (source) {
          if (source.value === key) {
            Ember.set(_this2, 'primaryResource.' + key, map);
          } else {
            Ember.set(_this2, 'primaryResource.' + source.value, null);
          }
        });
      }
    },

    headerToken: Ember.computed('scope', function () {
      var k = 'cruPersistentVolumeClaim.define.';

      k += Ember.get(this, 'mode');

      return k;
    }),

    sourceChoices: Ember.computed('intl.locale', function () {
      var intl = Ember.get(this, 'intl');
      var skip = ['host-path', 'secret'];
      var out = (0, _volume.getSources)('ephemeral').map(function (p) {
        var entry = Object.assign({}, p);
        var key = 'volumeSource.' + entry.name + '.title';

        if (skip.includes(entry.name)) {
          entry.priority = 0;
        } else if (intl.exists(key)) {
          entry.label = intl.t(key);
          if (p.persistent) {
            entry.priority = 2;
          } else {
            entry.priority = 1;
          }
        } else {
          entry.label = entry.name;
          entry.priority = 3;
        }

        return entry;
      });

      return out.filter(function (x) {
        return x.priority > 0;
      }).sortBy('priority', 'label');
    }),

    sourceComponent: Ember.computed('sourceName', function () {
      var name = Ember.get(this, 'sourceName');
      var sources = (0, _volume.getSources)('ephemeral');
      var entry = sources.findBy('name', name);

      if (entry) {
        return {
          component: 'volume-source/source-' + name,
          field: entry.value
        };
      }
    }),

    willSave: function willSave() {
      var vol = Ember.get(this, 'primaryResource');
      var entry = (0, _volume.getSources)('ephemeral').findBy('name', Ember.get(this, 'sourceName'));

      if (!entry) {
        var errors = [];
        var intl = Ember.get(this, 'intl');

        errors.push(intl.t('validation.required', { key: intl.t('cruVolume.source.label') }));
        Ember.set(this, 'errors', errors);

        return false;
      }

      var ok = this._super.apply(this, arguments);

      if (ok) {
        var out = Ember.Object.create({ name: Ember.get(vol, 'name') });

        Ember.set(out, entry.value, Ember.get(vol, entry.value));

        this.sendAction('doSave', { volume: out });
        this.doneSaving();
      }

      return false;
    },
    doneSaving: function doneSaving() {
      this.sendAction('done');
    }
  });
});