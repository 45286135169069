define('ui/components/container/new-edit/component', ['exports', 'ui/utils/errors', 'shared/mixins/new-or-edit', 'ui/utils/debounce', 'ui/utils/constants', 'shared/mixins/child-hook', 'shared/mixins/manage-labels', 'ui/components/container/new-edit/template'], function (exports, _errors2, _newOrEdit, _debounce, _constants, _childHook, _manageLabels, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_newOrEdit.default, _childHook.default, {
    clusterStore: Ember.inject.service(),
    intl: Ember.inject.service(),
    prefs: Ember.inject.service(),
    scope: Ember.inject.service(),
    settings: Ember.inject.service(),

    layout: _template.default,
    tagName: 'form',

    isUpgrade: false,
    service: null,
    launchConfig: null,
    launchConfigIndex: null,

    namespace: null,
    scale: 1,
    scaleMode: null,

    serviceLinksArray: null,
    isRequestedHost: null,
    upgradeOptions: null,
    separateLivenessCheck: false,

    // Errors from components
    commandErrors: null,
    volumeErrors: null,
    networkingErrors: null,
    secretsErrors: null,
    readyCheckErrors: null,
    liveCheckErrors: null,
    schedulingErrors: null,
    securityErrors: null,
    scaleErrors: null,
    imageErrors: null,
    portErrors: null,
    namespaceErrors: null,
    labelErrors: null,
    annotationErrors: null,

    // ----------------------------------
    userLabels: null,

    advanced: false,
    header: '',
    isSidekick: Ember.computed.equal('scaleMode', 'sidekick'),
    init: function init() {
      window.nec = this;
      this._super.apply(this, arguments);

      if (Ember.get(this, 'launchConfig') && !Ember.get(this, 'launchConfig.environmentFrom')) {
        Ember.set(this, 'launchConfig.environmentFrom', []);
      }

      var service = Ember.get(this, 'service');

      if (!Ember.get(this, 'isSidekick') && service && !Ember.get(service, 'scheduling')) {
        Ember.set(service, 'scheduling', { node: {} });
      }

      if (!Ember.get(this, 'isSidekick')) {
        Ember.setProperties(this, {
          description: Ember.get(this, 'service.description'),
          scale: Ember.get(this, 'service.scale'),
          scheduling: Ember.get(this, 'service.scheduling')
        });
      } else {
        Ember.set(this, 'description', Ember.get(this, 'launchConfig.description'));
      }
      Ember.set(this, 'name', Ember.get(this, 'launchConfig.name'));

      var namespaceId = null;

      namespaceId = Ember.get(this, 'service.namespaceId');

      if (namespaceId) {
        var namespace = Ember.get(this, 'clusterStore').getById('namespace', namespaceId);

        if (namespace) {
          Ember.set(this, 'namespace', namespace);
        }
      }

      if (!Ember.get(this, 'separateLivenessCheck')) {
        var ready = Ember.get(this, 'launchConfig.readinessProbe');
        var live = Ember.get(this, 'launchConfig.livenessProbe');
        var readyStr = JSON.stringify(ready);
        var liveStr = JSON.stringify(live);

        if (readyStr !== liveStr) {
          Ember.set(this, 'separateLivenessCheck', true);
        }
      }

      if (!Ember.get(this, 'isSidekick')) {
        this.labelsChanged();
      }
    },
    didInsertElement: function didInsertElement() {
      var input = this.$("INPUT[type='text']")[0];

      if (input) {
        input.focus();
      }
    },


    actions: {
      setImage: function setImage(uuid) {
        Ember.set(this, 'launchConfig.image', uuid);
      },
      setLabels: function setLabels(section, labels) {
        Ember.set(this, section + 'Labels', labels);
      },
      setRequestedHostId: function setRequestedHostId(hostId) {
        Ember.set(this, 'launchConfig.requestedHostId', hostId);
      },
      setUpgrade: function setUpgrade(upgrade) {
        Ember.set(this, 'upgradeOptions', upgrade);
      },
      done: function done() {
        this.sendAction('done');
      },
      cancel: function cancel() {
        this.sendAction('cancel');
      },
      toggleSeparateLivenessCheck: function toggleSeparateLivenessCheck() {
        Ember.set(this, 'separateLivenessCheck', !Ember.get(this, 'separateLivenessCheck'));
      },
      removeSidekick: function removeSidekick(idx) {
        var ary = Ember.get(this, 'primaryService.secondaryLaunchConfigs');

        ary.removeAt(idx);
      }
    },

    // Labels
    labelsChanged: (0, _debounce.debouncedObserver)('userLabels.@each.{key,value}', function () {
      var out = (0, _manageLabels.flattenLabelArrays)(Ember.get(this, 'userLabels'));

      Ember.set(this, 'service.labels', out);
    }),

    updateHeader: function () {
      var _this = this;

      var args = {};
      var k = 'newContainer.';

      k += (Ember.get(this, 'isUpgrade') ? 'upgrade' : 'add') + '.';
      if (Ember.get(this, 'isSidekick')) {
        var svc = Ember.get(this, 'service');

        if (svc && Ember.get(svc, 'id')) {
          k += 'sidekickName';
          args = { name: Ember.get(this, 'service.displayName') };
        } else {
          k += 'sidekick';
        }
      } else if (Ember.get(this, 'isGlobal')) {
        k += 'globalService';
      } else {
        k += 'service';
      }

      Ember.run.next(function () {
        if (_this.isDestroyed || _this.isDestroying) {
          return;
        }

        Ember.set(_this, 'header', Ember.get(_this, 'intl').t(k, args));
      });
    }.observes('isUpgrade', 'isSidekick', 'isGlobal', 'service.displayName', 'intl.locale').on('init'),

    // ----------------------------------
    // ----------------------------------
    // Save
    // ----------------------------------
    validate: function validate() {
      var pr = Ember.get(this, 'primaryResource');
      var errors = pr.validationErrors() || [];
      var lc = Ember.get(this, 'launchConfig');

      var quotaErrors = lc.validateQuota(Ember.get(this, 'namespace'));

      errors.pushObjects(quotaErrors);

      if (Ember.get(quotaErrors, 'length') > 0) {
        Ember.setProperties(this, {
          advanced: true,
          securitySectionExpanded: true
        });
      }

      (Ember.get(this, 'service.secondaryLaunchConfigs') || []).forEach(function (slc) {
        slc.validationErrors().forEach(function (err) {
          errors.push(Ember.get(slc, 'displayName') + ': ' + err);
        });
      });

      // Errors from components
      errors.pushObjects(Ember.get(this, 'commandErrors') || []);
      errors.pushObjects(Ember.get(this, 'volumeErrors') || []);
      errors.pushObjects(Ember.get(this, 'networkingErrors') || []);
      errors.pushObjects(Ember.get(this, 'secretsErrors') || []);
      errors.pushObjects(Ember.get(this, 'readyCheckErrors') || []);
      errors.pushObjects(Ember.get(this, 'liveCheckErrors') || []);
      errors.pushObjects(Ember.get(this, 'schedulingErrors') || []);
      errors.pushObjects(Ember.get(this, 'securityErrors') || []);
      errors.pushObjects(Ember.get(this, 'scaleErrors') || []);
      errors.pushObjects(Ember.get(this, 'imageErrors') || []);
      errors.pushObjects(Ember.get(this, 'portErrors') || []);
      errors.pushObjects(Ember.get(this, 'namespaceErrors') || []);
      errors.pushObjects(Ember.get(this, 'labelErrors') || []);
      errors.pushObjects(Ember.get(this, 'annotationErrors') || []);

      errors = errors.uniq();

      if (Ember.get(errors, 'length')) {
        Ember.set(this, 'errors', errors);

        if (Ember.get(this, 'isSidekick') && !Ember.get(this, 'isUpgrade')) {
          Ember.get(pr, 'secondaryLaunchConfigs').pop();
        }

        return false;
      }

      Ember.set(this, 'errors', null);

      return true;
    },
    willSave: function willSave() {
      var _this2 = this,
          _arguments = arguments;

      var intl = Ember.get(this, 'intl');
      var pr = void 0;
      var nameResource = void 0;
      var lc = Ember.get(this, 'launchConfig');
      var name = (Ember.get(this, 'name') || '').trim().toLowerCase();
      var service = Ember.get(this, 'service');

      var readinessProbe = Ember.get(lc, 'readinessProbe');

      if (!Ember.get(this, 'separateLivenessCheck')) {
        if (readinessProbe) {
          var livenessProbe = Object.assign({}, readinessProbe);

          Ember.set(livenessProbe, 'successThreshold', 1);
          Ember.set(lc, 'livenessProbe', livenessProbe);
        } else {
          Ember.set(lc, 'livenessProbe', null);
        }
      }
      var uid = Ember.get(lc, 'uid');

      if (uid === '') {
        Ember.set(lc, 'uid', null);
      }

      if (Ember.get(this, 'isSidekick')) {
        var _errors = [];

        if (!service) {
          _errors.push(Ember.get(this, 'intl').t('newContainer.errors.noSidekick'));
          Ember.set(this, 'errors', _errors);

          return false;
        }

        if (!name) {
          _errors.push(intl.t('validation.required', { key: intl.t('formNameDescription.name.label') }));
          Ember.set(this, 'errors', _errors);

          return false;
        }

        pr = service;
        nameResource = lc;

        var slc = Ember.get(pr, 'secondaryLaunchConfigs');

        if (!slc) {
          slc = [];
          Ember.set(pr, 'secondaryLaunchConfigs', slc);
        }

        var lci = Ember.get(this, 'launchConfigIndex');

        if (lci === undefined || lci === null) {
          // If it's a new sidekick, add it to the end of the list
          lci = slc.length;
        } else {
          lci = parseInt(lci, 10);
        }

        var duplicate = pr.containers.find(function (x, idx) {
          return idx !== lci + 1 && Ember.get(x, 'name').toLowerCase() === name;
        });

        if (duplicate) {
          _errors.push(intl.t('newContainer.errors.duplicateName', {
            name: name,
            service: Ember.get(duplicate, 'displayName')
          }));
          Ember.set(this, 'errors', _errors);

          return false;
        }

        slc[lci] = lc;

        Ember.set(lc, 'name', name);
        Ember.set(pr, 'containers', [pr.containers[0]]);
        pr.containers.pushObjects(slc);
      } else {
        service.clearConfigsExcept(Ember.get(this, 'scaleMode') + 'Config');
        if (Ember.get(this, 'scaleMode') === 'statefulSet' && !Ember.get(service, 'statefulSetConfig.serviceName')) {
          Ember.set(service, 'statefulSetConfig.serviceName', name);
        }
        pr = service;
        nameResource = pr;
        Ember.set(pr, 'scale', Ember.get(this, 'scale'));
        var containers = Ember.get(pr, 'containers');

        if (!containers) {
          Ember.set(pr, 'containers', []);
        } else {
          Ember.set(lc, 'name', name);
          containers[0] = lc;
        }
      }

      nameResource.setProperties({
        name: name,
        description: Ember.get(this, 'description')
      });

      Ember.set(this, 'primaryResource', pr);
      Ember.set(this, 'originalPrimaryResource', pr);

      var errors = [];

      if (!Ember.get(this, 'namespace.name')) {
        errors.push(intl.t('validation.required', { key: intl.t('generic.namespace') }));
        Ember.set(this, 'errors', errors);

        return false;
      }

      Ember.set(pr, 'namespaceId', Ember.get(this, 'namespace.id') || '__placeholder__');
      var self = this;
      var sup = this._super;

      pr.updateTimestamp();

      return this.applyHooks('_beforeSaveHooks').then(function () {
        Ember.set(pr, 'namespaceId', Ember.get(_this2, 'namespace.id'));

        return _this2.applyHooks('_volumeHooks').then(function () {
          return sup.apply.apply(sup, [self].concat(Array.prototype.slice.call(_arguments)));
        }).catch(function (err) {
          Ember.set(_this2, 'errors', [_errors2.default.stringify(err)]);
        });
      }).catch(function (err) {
        Ember.set(_this2, 'errors', [_errors2.default.stringify(err)]);
      });
    },
    doneSaving: function doneSaving() {
      if (!Ember.get(this, 'isUpgrade')) {
        var scaleMode = Ember.get(this, 'scaleMode');

        if (scaleMode === 'sidekick') {
          // Remember sidekick as service since you're not
          // likely to want to add many sidekicks in a row
          scaleMode = 'deployment';
        }
        Ember.set(this, 'prefs.' + _constants.default.PREFS.LAST_SCALE_MODE, scaleMode);
        Ember.set(this, 'prefs.' + _constants.default.PREFS.LAST_IMAGE_PULL_POLICY, Ember.get(this, 'launchConfig.imagePullPolicy'));
        Ember.set(this, 'prefs.' + _constants.default.PREFS.LAST_NAMESPACE, Ember.get(this, 'namespace.id'));
      }
      this.sendAction('done');
    }
  });
});