define('ui/authenticated/cluster/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    wasReady: true,
    watchReady: Ember.observer('model.isReady', function () {
      var _this = this;

      var wasReady = Ember.get(this, 'wasReady');
      var isReady = Ember.get(this, 'model.isReady');

      Ember.set(this, 'wasReady', isReady);

      if (isReady && !wasReady) {
        Ember.run.next(function () {
          _this.send('becameReady');
        });
      }
    })
  });
});