define('ui/not-found/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    language: Ember.inject.service('user-language'),

    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);

      return this.get('language').initLanguage();
    },
    redirect: function redirect() {
      var url = this._router.location.formatURL('/not-found');

      if (window.location.pathname !== url) {
        this.transitionTo('not-found');
      }
    }
  });
});