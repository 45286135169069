define('ui/instance-initializers/intl', ['exports', 'ui/utils/intl/missing-message'], function (exports, _missingMessage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function initialize(instance) {
    var intl = instance.lookup('service:intl');
    var adapter = intl.get('adapter');

    adapter.reopen({
      _lookup: adapter.lookup,
      lookup: function lookup(locales, key) {
        locales = Ember.makeArray(locales || Ember.get(this, 'locale'));

        if (locales[0] === 'none') {
          return (0, _missingMessage.default)(key, locales);
        } else if (key) {
          return this._lookup(locales, key);
        } else {
          return this._lookup(locales, 'generic.missing');
        }
      }
    });

    // @TODO use regular t with htmlSafe instead
    intl.reopen({
      tHtml: function tHtml(key) {
        for (var _len = arguments.length, args = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
          args[_key - 1] = arguments[_key];
        }

        var _args = _slicedToArray(args, 1),
            options = _args[0];

        var translation = this.findTranslationByKey(key, options && options.locale);

        return this.formatHtmlMessage.apply(this, [translation].concat(_toConsumableArray(args)));
      }
    });
  }

  exports.default = {
    name: 'intl',
    after: 'ember-intl',
    initialize: initialize
  };
});