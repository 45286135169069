define('ui/authenticated/project/config-maps/detail/edit/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      var original = this.modelFor('authenticated.project.config-maps.detail');

      Ember.set(this, 'originalModel', original);

      return original.clone();
    },
    setupController: function setupController(controller /* , model*/) {
      this._super.apply(this, arguments);
      Ember.set(controller, 'originalModel', this.modelFor('authenticated.project.config-maps.detail'));
    }
  });
});