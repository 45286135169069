define('ui/components/pod-row/component', ['exports', 'ui/utils/constants', 'ui/components/pod-row/template'], function (exports, _constants, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    scope: Ember.inject.service(),
    session: Ember.inject.service(),

    layout: _template.default,
    model: null,
    showStats: false,
    bulkActions: true,
    expandPlaceholder: false,
    scalePlaceholder: false,
    cpuMax: null,
    memoryMax: null,
    storageMax: null,
    networkMax: null,
    showActions: true,
    tagName: '',
    expanded: null,

    containers: Ember.computed.alias('model.containers'),
    actions: {
      toggle: function toggle() {
        this.sendAction('toggle');
      }
    },

    canExpand: Ember.computed('expandPlaceholder', 'model.containers', function () {
      return Ember.get(this, 'expandPlaceholder') && Ember.get(this, 'model.containers.length') > 1;
    }),

    statsAvailable: Ember.computed('model.{state,healthState}', function () {
      return _constants.default.ACTIVEISH_STATES.indexOf(this.get('model.state')) >= 0 && this.get('model.healthState') !== 'started-once';
    })

  });
});