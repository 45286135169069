define('ui/mixins/cattle-transitioning-resource', ['exports', 'shared/mixins/cattle-transitioning-resource'], function (exports, _cattleTransitioningResource) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _cattleTransitioningResource.default;
    }
  });
});