define('ui/models/localauthconfig', ['exports', '@rancher/ember-api-store/models/resource'], function (exports, _resource) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var LocalAuthConfig = _resource.default.extend({ type: 'localauthConfig' });

  exports.default = LocalAuthConfig;
});