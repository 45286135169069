define('ui/models/mountentry', ['exports', '@rancher/ember-api-store/models/resource', '@rancher/ember-api-store/utils/denormalize'], function (exports, _resource, _denormalize) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _resource.default.extend({
    intl: Ember.inject.service(),
    isReadWrite: Ember.computed.equal('permission', 'rw'),
    isReadOnly: Ember.computed.equal('permission', 'ro'),

    instance: (0, _denormalize.reference)('instanceId'),
    volume: (0, _denormalize.reference)('volumeId'),

    displayVolumeName: Ember.computed('volumeName', function () {
      var name = this.get('volumeName');

      if (name.match(/^[0-9a-f]{64}$/)) {
        return (name.substr(0, 12) + '&hellip;').htmlSafe();
      }

      return name;
    }),

    displayPermission: Ember.computed('permission', function () {
      var permission = this.get('permission');
      var out = null;
      var intl = this.get('intl');

      switch (permission) {
        case 'ro':
          out = intl.findTranslationByKey('formVolumeRow.opts.ro');
          break;
        case 'rw':
          out = intl.findTranslationByKey('formVolumeRow.opts.rw');
          break;
        default:
          out = permission;
          break;
      }

      return out;
    })
  });
});