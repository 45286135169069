define('ui/components/modal-edit-host/component', ['exports', 'shared/mixins/manage-labels', 'shared/mixins/new-or-edit', 'ui/utils/constants', 'ui/utils/debounce', 'shared/mixins/modal-base', 'ui/components/modal-edit-host/template'], function (exports, _manageLabels, _newOrEdit, _constants, _debounce, _modalBase, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  exports.default = Ember.Component.extend(_modalBase.default, _newOrEdit.default, _manageLabels.default, {
    layout: _template.default,
    classNames: ['large-modal'],
    model: null,
    editing: true,

    ips: null,
    requireAny: null,
    requiredIfAny: _defineProperty({}, _constants.default.LABEL.SYSTEM_TYPE, ''),
    readonlyLabels: [_constants.default.LABEL.PER_HOST_SUBNET],
    systemLabels: null,
    userLabels: null,
    customName: null,

    originalModel: Ember.computed.alias('modalService.modalOpts'),
    init: function init() {
      this._super.apply(this, arguments);
      this.set('model', this.get('originalModel').clone());
      this.initLabels(this.get('model.labels'), null, [_constants.default.LABEL.SCHED_IPS, _constants.default.LABEL.REQUIRE_ANY]);

      if (this.get('model.name')) {
        this.set('customName', this.get('model.name'));
      }

      var ips = [];
      var str = this.getLabel(_constants.default.LABEL.SCHED_IPS);

      if (str) {
        ips = str.split(/\s*,\s*/).filter(function (x) {
          return x.length;
        });
      }
      this.set('ips', ips);

      this.set('requireAny', this.getLabel(_constants.default.LABEL.REQUIRE_ANY));
    },


    actions: {
      setUserLabels: function setUserLabels(labels) {
        this.set('userLabels', labels);
      }
    },

    customNameObserver: Ember.on('init', Ember.observer('customName', function () {
      var cn = this.get('customName');

      if (cn && cn.length > 0) {
        this.set('primaryResource.name', cn);
      } else {
        this.set('primaryResource.name', null);
      }
    })),

    ipsChanged: function () {
      var ips = (this.get('ips') || []).map(function (x) {
        return x.trim();
      }).filter(function (x) {
        return x.length;
      });

      this.setLabel(_constants.default.LABEL.SCHED_IPS, ips.join(', '));
    }.observes('ips.[]'),

    requireAnyChanged: function () {
      var any = this.get('requireAny');

      this.setLabel(_constants.default.LABEL.REQUIRE_ANY, any || undefined);
    }.observes('requireAny'),

    mergeAllLabels: (0, _debounce.debouncedObserver)('systemLabels.@each.{key,value}', 'userLabels.@each.{key,value}', function () {
      var out = (0, _manageLabels.flattenLabelArrays)(this.get('systemLabels'), this.get('userLabels'));

      this.set('model.labels', out);
    }),

    updateLabels: function updateLabels(labels) {
      this.set('systemLabels', labels);
    },
    doneSaving: function doneSaving() {
      this.send('cancel');
    }
  });
});