define('ui/models/node', ['exports', '@rancher/ember-api-store/models/resource', 'shared/utils/util', 'ui/utils/constants', 'ui/mixins/state-counts', '@rancher/ember-api-store/utils/denormalize', 'shared/mixins/resource-usage', 'shared/mixins/grafana'], function (exports, _resource, _util, _constants, _stateCounts, _denormalize, _resourceUsage, _grafana) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var UNSCHEDULABLE_KEYS = ['node-role.kubernetes.io/etcd', 'node-role.kubernetes.io/controlplane'];
  var UNSCHEDULABLE_EFFECTS = ['NoExecute', 'NoSchedule'];

  var CONTAINERD = 'containerd://';

  var Node = _resource.default.extend(_grafana.default, _stateCounts.default, _resourceUsage.default, {
    modalService: Ember.inject.service('modal'),
    settings: Ember.inject.service(),
    prefs: Ember.inject.service(),
    router: Ember.inject.service(),
    globalStore: Ember.inject.service(),
    clusterStore: Ember.inject.service(),
    intl: Ember.inject.service(),

    type: 'node',

    grafanaDashboardName: 'Nodes',
    grafanaResourceId: Ember.computed.alias('ipAddress'),

    cluster: (0, _denormalize.reference)('clusterId', 'cluster'),
    nodePool: (0, _denormalize.reference)('nodePoolId'),

    displayIp: Ember.computed.or('externalIpAddress', 'ipAddress'),

    init: function init() {
      this._super.apply(this, arguments);
      this.defineStateCounts('arrangedInstances', 'instanceStates', 'instanceCountSort');
    },


    availableActions: Ember.computed('links.{nodeConfig}', 'actionLinks.{cordon,uncordon,drain}', function () {
      var l = Ember.get(this, 'links');
      var a = Ember.get(this, 'actionLinks') || {};

      var out = [{
        label: 'action.cordon',
        icon: 'icon icon-pause',
        action: 'cordon',
        enabled: !!a.cordon,
        bulkable: true
      }, {
        label: 'action.uncordon',
        icon: 'icon icon-play',
        action: 'uncordon',
        enabled: !!a.uncordon,
        bulkable: true
      }, {
        label: 'action.drain',
        icon: 'icon icon-snapshot',
        action: 'drain',
        enabled: !!a.drain,
        bulkable: true
      }, {
        label: 'action.stopDrain',
        icon: 'icon icon-stop',
        action: 'stopDrain',
        enabled: !!a.stopDrain,
        bulkable: true
      }, { divider: true }, {
        label: 'action.nodeConfig',
        icon: 'icon icon-download',
        action: 'nodeConfig',
        enabled: !!l.nodeConfig
      }, { divider: true }];

      return out;
    }),

    displayName: Ember.computed('name', 'nodeName', 'requestedHostname', 'id', function () {
      var name = Ember.get(this, 'name');

      if (name) {
        return name;
      }

      name = Ember.get(this, 'nodeName');
      if (name) {
        if (name.match(/[a-z]/i)) {
          name = name.replace(/\..*$/, '');
        }

        return name;
      }

      name = Ember.get(this, 'requestedHostname');
      if (name) {
        return name;
      }

      return '(' + Ember.get(this, 'id') + ')';
    }),

    rolesArray: Ember.computed('etcd', 'controlPlane', 'worker', function () {
      var _this = this;

      return ['etcd', 'controlPlane', 'worker'].filter(function (x) {
        return !!Ember.get(_this, x);
      });
    }),

    displayRoles: Ember.computed('intl.locale', 'rolesArray.[]', function () {
      var intl = Ember.get(this, 'intl');
      var roles = Ember.get(this, 'rolesArray');

      if (roles.length >= 3) {
        return [intl.t('generic.all')];
      }

      return roles.map(function (role) {
        var key = 'model.machine.role.' + role;

        if (intl.exists(key)) {
          return intl.t(key);
        }

        return key;
      });
    }),

    sortRole: Ember.computed('rolesArray.[]', function () {
      var roles = Ember.get(this, 'rolesArray');

      if (roles.length >= 3) {
        return 1;
      }

      if (roles.includes('controlPlane')) {
        return 2;
      }

      if (roles.includes('etcd')) {
        return 3;
      }

      return 4;
    }),

    isUnschedulable: Ember.computed('taints.@each.{effect,key}', function () {
      var taints = Ember.get(this, 'taints') || [];

      return taints.some(function (taint) {
        return UNSCHEDULABLE_KEYS.includes(taint.key) && UNSCHEDULABLE_EFFECTS.includes(taint.effect);
      });
    }),

    osBlurb: Ember.computed('info.os.operatingSystem', function () {
      var out = Ember.get(this, 'info.os.operatingSystem') || '';

      out = out.replace(/\s+\(.*?\)/, ''); // Remove details in parens
      out = out.replace(/;.*$/, ''); // Or after semicolons
      out = out.replace('Red Hat Enterprise Linux Server', 'RHEL'); // That's kinda long

      return out;
    }),

    engineIcon: Ember.computed('info.os.dockerVersion', function () {
      if ((Ember.get(this, 'info.os.dockerVersion') || '').startsWith(CONTAINERD)) {
        return 'icon-container-d';
      }

      return 'icon-docker';
    }),

    engineBlurb: Ember.computed('info.os.dockerVersion', function () {
      var version = Ember.get(this, 'info.os.dockerVersion') || '';

      if (version.startsWith(CONTAINERD)) {
        version = version.substr(CONTAINERD.length);
      }

      var idx = version.indexOf('+');

      if (idx > 0) {
        version = version.substr(0, idx);
      }

      return version;
    }),

    //  or they will not be pulled in correctly.
    displayEndpoints: function () {
      var store = Ember.get(this, 'clusterStore');

      return (Ember.get(this, 'publicEndpoints') || []).map(function (endpoint) {
        if (!endpoint.service) {
          endpoint.service = store.getById('service', endpoint.serviceId);
        }

        endpoint.instance = store.getById('instance', endpoint.instanceId);

        return endpoint;
      });
    }.property('publicEndpoints.@each.{ipAddress,port,serviceId,instanceId}'),

    // If you use this you must ensure that services and containers are already in the store
    requireAnyLabelStrings: function () {
      return ((Ember.get(this, 'labels') || {})[_constants.default.LABEL.REQUIRE_ANY] || '').split(/\s*,\s*/).filter(function (x) {
        return x.length > 0 && x !== _constants.default.LABEL.SYSTEM_TYPE;
      });
    }.property('labels.' + _constants.default.LABEL.REQUIRE_ANY),
    actions: {
      activate: function activate() {
        return this.doAction('activate');
      },
      deactivate: function deactivate() {
        return this.doAction('deactivate');
      },
      cordon: function cordon() {
        return this.doAction('cordon');
      },
      uncordon: function uncordon() {
        return this.doAction('uncordon');
      },
      drain: function drain() {
        Ember.get(this, 'modalService').toggleModal('modal-drain-node', {
          escToClose: true,
          resources: [this]
        });
      },
      stopDrain: function stopDrain() {
        return this.doAction('stopDrain');
      },
      newContainer: function newContainer() {
        Ember.get(this, 'router').transitionTo('containers.run', { queryParams: { hostId: Ember.get(this, 'model.id') } });
      },
      edit: function edit() {
        Ember.get(this, 'modalService').toggleModal('modal-edit-host', this);
      },
      nodeConfig: function nodeConfig() {
        var url = this.linkFor('nodeConfig');

        if (url) {
          (0, _util.download)(url);
        }
      }
    }

  });

  Node.reopenClass({ defaultSortBy: 'name,hostname' });

  exports.default = Node;
});