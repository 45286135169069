define('ui/models/cloudcredential', ['exports', '@rancher/ember-api-store/models/resource'], function (exports, _resource) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var cloudCredential = _resource.default.extend({
    modal: Ember.inject.service(),
    type: 'cloudCredential',

    canClone: false,
    canEdit: true,

    isAmazon: Ember.computed.notEmpty('amazonec2credentialConfig'),
    isAzure: Ember.computed.notEmpty('azurecredentialConfig'),
    isDo: Ember.computed.notEmpty('digitaloceancredentialConfig'),
    isVMware: Ember.computed.notEmpty('vmwarevspherecredentialConfig'),

    displayType: Ember.computed('amazonec2credentialConfig', 'azurecredentialConfig', 'digitaloceancredentialConfig', 'vmwarevspherecredentialConfig', function () {
      var isAmazon = this.isAmazon,
          isAzure = this.isAzure,
          isDo = this.isDo,
          isVMware = this.isVMware;


      if (isAmazon) {
        return 'Amazon';
      } else if (isAzure) {
        return 'Azure';
      } else if (isDo) {
        return 'Digital Ocean';
      } else if (isVMware) {
        return 'VMware vSphere';
      }
    }),

    actions: {
      edit: function edit() {
        this.modal.toggleModal('modal-add-cloud-credential', {
          cloudCredential: this,
          mode: 'edit'
        });
      }
    }
  });

  exports.default = cloudCredential;
});