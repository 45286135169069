define('ui/components/namespace-resource-quota/component', ['exports', 'shared/utils/util', 'shared/utils/parse-unit', 'ui/components/namespace-resource-quota/template'], function (exports, _util, _parseUnit, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var defaultRadix = 10;
  var defaultIncrement = 1024;
  var defaultDivisor = 1048576;
  var defaultMultiplier = 3;

  exports.default = Ember.Component.extend({
    intl: Ember.inject.service(),

    layout: _template.default,
    limit: null,
    usedLimit: null,
    projectlimit: null,
    projectQuota: null,
    nsDefaultQuota: null,

    editing: null,
    isNew: null,

    quotaArray: null,

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.initQuotaArray();
      Ember.run.next(function () {
        _this.quotaDidChange();
      });
    },


    quotaDidChange: Ember.observer('quotaArray.@each.{key,value}', function () {
      var _this2 = this;

      var out = {};

      (Ember.get(this, 'quotaArray') || []).forEach(function (quota) {
        if (quota.key) {
          var value = parseInt(Ember.get(quota, 'value'), defaultRadix);
          var max = Ember.get(quota, 'max');
          var currentUse = Ember.get(quota, 'currentProjectUse.firstObject.value');

          if (value === undefined || value === null) {
            out[quota.key] = '';

            return;
          }

          if (value > max || currentUse + value > max) {
            value = Ember.set(quota, 'value', max - currentUse);
          }

          out[quota.key] = _this2.quotaWithUnits(quota, value);
        }
      });

      this.sendAction('changed', Object.keys(out).length ? out : null);
      this.updateLimits();
    }),

    quotaWithUnits: function quotaWithUnits(quota, value) {
      var readable = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

      var cpuNotation = readable ? 'milli CPUs' : 'm';
      var memNotation = readable ? 'MiB' : 'Mi';
      var storageNotation = readable ? 'GB' : 'Gi';

      if (quota.key === 'limitsCpu' || quota.key === 'requestsCpu') {
        return '' + value + cpuNotation;
      } else if (quota.key === 'limitsMemory' || quota.key === 'requestsMemory') {
        return '' + value + memNotation;
      } else if (quota.key === 'requestsStorage') {
        return '' + value + storageNotation;
      } else {
        return value;
      }
    },
    updateLimits: function updateLimits() {
      var _this3 = this;

      (Ember.get(this, 'quotaArray') || []).forEach(function (quota) {
        if (quota.key) {
          var intl = Ember.get(_this3, 'intl');
          var value = parseInt(Ember.get(quota, 'value'), defaultRadix) || 0;
          var usedValue = Ember.get(quota, 'currentProjectUse.firstObject.value');
          var newUse = Ember.get(quota, 'currentProjectUse.lastObject');
          var myNewUse = usedValue + value;
          var remaining = Ember.get(quota, 'max') - myNewUse > 0 ? Ember.get(quota, 'max') - myNewUse : 0;
          var newTotals = [{
            label: intl.t('formResourceQuota.table.resources.reserved'),
            value: _this3.quotaWithUnits(quota, usedValue, true)
          }, {
            label: intl.t('formResourceQuota.table.resources.namespace'),
            value: _this3.quotaWithUnits(quota, value, true)
          }, {
            label: intl.t('formResourceQuota.table.resources.available'),
            value: _this3.quotaWithUnits(quota, remaining, true)
          }, {
            label: intl.t('formResourceQuota.table.resources.max'),
            value: _this3.quotaWithUnits(quota, Ember.get(quota, 'max'), true)
          }];

          // if (remaining === 0) {
          //   set(newUse, 'color', 'bg-error');
          // } else {
          //   if (get(newUse, 'color') === 'bg-error') {
          //     set(newUse, 'color', 'bg-warning');
          //   }
          // }

          Ember.set(newUse, 'value', value);
          Ember.set(quota, 'totalLimits', newTotals);
        }
      });
    },
    initQuotaArray: function initQuotaArray() {
      var _this4 = this;

      var limit = this.limit,
          nsDefaultQuota = this.nsDefaultQuota,
          intl = this.intl;

      var used = Ember.get(this, 'usedLimit');
      var currentProjectLimit = Ember.get(this, 'projectLimit');
      var array = [];

      Object.keys(nsDefaultQuota).forEach(function (key) {
        if (key !== 'type' && typeof nsDefaultQuota[key] === 'string') {
          var value = void 0,
              currentProjectUse = void 0,
              totalLimits = void 0,
              remaining = void 0;
          var usedValue = '';
          var max = '';
          var newUse = null;
          var projectUse = Ember.get(used, key) || '0';

          if (limit && !limit[key]) {
            array.push({
              key: key,
              value: '',
              currentProjectUse: []
            });

            return;
          }

          value = limit && limit[key] ? limit[key] : nsDefaultQuota[key];

          switch (key) {
            case 'limitsCpu':
            case 'requestsCpu':
              value = (0, _util.convertToMillis)(value);
              usedValue = (0, _util.convertToMillis)(projectUse);
              max = (0, _util.convertToMillis)(Ember.get(currentProjectLimit, key));
              break;
            case 'limitsMemory':
            case 'requestsMemory':
              value = (0, _parseUnit.parseSi)(value, defaultIncrement) / defaultDivisor;
              usedValue = (0, _parseUnit.parseSi)(projectUse, defaultIncrement) / defaultDivisor;
              max = (0, _parseUnit.parseSi)(Ember.get(currentProjectLimit, key), defaultIncrement) / defaultDivisor;
              break;
            case 'requestsStorage':
              value = (0, _parseUnit.parseSi)(value) / Math.pow(defaultIncrement, defaultMultiplier);
              usedValue = (0, _parseUnit.parseSi)(projectUse) / Math.pow(defaultIncrement, defaultMultiplier);
              max = (0, _parseUnit.parseSi)(Ember.get(currentProjectLimit, key)) / Math.pow(defaultIncrement, defaultMultiplier);
              break;
            default:
              value = parseInt(value, defaultRadix);
              usedValue = parseInt(projectUse, defaultRadix);
              max = parseInt(Ember.get(currentProjectLimit, key), defaultRadix);
              break;
          }

          if (!Ember.get(_this4, 'isNew')) {
            usedValue = usedValue - value;
          }

          newUse = usedValue + value;

          remaining = max - newUse > 0 ? max - newUse : 0;

          currentProjectUse = [{
            // current use
            color: 'bg-primary',
            label: key,
            value: usedValue
          }, {
            // only need the new value here because progress-multi-bar adds this to the previous
            color: 'bg-info',
            label: key,
            value: value
          }];

          totalLimits = [{
            label: intl.t('formResourceQuota.table.resources.reserved'),
            value: _this4.quotaWithUnits(nsDefaultQuota[key], usedValue, true)
          }, {
            label: intl.t('formResourceQuota.table.resources.namespace'),
            value: _this4.quotaWithUnits(nsDefaultQuota[key], value, true)
          }, {
            label: intl.t('formResourceQuota.table.resources.available'),
            value: _this4.quotaWithUnits(nsDefaultQuota[key], remaining, true)
          }, {
            label: intl.t('formResourceQuota.table.resources.max'),
            value: _this4.quotaWithUnits(nsDefaultQuota[key], max, true)
          }];

          array.push({
            currentProjectUse: currentProjectUse,
            key: key,
            max: max,
            totalLimits: totalLimits,
            value: value
          });
        }
      });

      Ember.set(this, 'quotaArray', array);
    }
  });
});