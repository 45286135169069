define('ui/catalog-tab/index/controller', ['exports', 'ui/utils/platform'], function (exports, _platform) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    application: Ember.inject.controller(),
    catalogController: Ember.inject.controller('catalog-tab'),
    parentRoute: 'catalog-tab',
    launchRoute: 'catalog-tab.launch',
    category: Ember.computed.alias('catalogController.category'),

    actions: {
      filterAction: function filterAction(catalog) {
        var out = {
          catalogId: '',
          clusterCatalogId: '',
          projectCatalogId: ''
        };
        var scope = Ember.get(catalog, 'scope');
        var scopedId = scope + 'Id';

        out[scopedId] = Ember.get(catalog, 'catalogId');

        this.transitionToRoute(this.get('parentRoute'), { queryParams: out });
      },
      categoryAction: function categoryAction(category, catalogId) {
        this.transitionToRoute(this.get('launchRoute'), {
          queryParams: {
            category: category,
            catalogId: catalogId
          }
        });
      },
      launch: function launch(id, onlyAlternate) {
        if (onlyAlternate && !(0, _platform.isAlternate)(event)) {
          return false;
        }

        this.transitionToRoute(this.get('launchRoute'), id);
      },
      refresh: function refresh() {
        var catalogTab = Ember.getOwner(this).lookup('route:catalog-tab');

        catalogTab.send('refresh');
      }
    },
    catalogId: Ember.computed('catalogController.catalogId', 'catalogController.clusterCatalogId', 'catalogController.projectCatalogId', function () {
      var clusterCatalogId = Ember.get(this, 'catalogController.clusterCatalogId');
      var projectCatalogId = Ember.get(this, 'catalogController.projectCatalogId');
      var catalogId = Ember.get(this, 'catalogController.catalogId');
      var out = '';

      if (catalogId) {
        out = catalogId;
      }
      if (clusterCatalogId) {
        out = clusterCatalogId.split(':')[1];
      }
      if (projectCatalogId) {
        out = projectCatalogId.split(':')[1];
      }

      return out;
    })

  });
});