define('ui/authenticated/cluster/edit/route', ['exports', 'shared/utils/load-script'], function (exports, _loadScript) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    access: Ember.inject.service(),
    globalStore: Ember.inject.service(),
    roleTemplateService: Ember.inject.service('roleTemplate'),

    model: function model() {
      var globalStore = this.get('globalStore');
      var cluster = this.modelFor('authenticated.cluster');

      return Ember.RSVP.hash({
        originalCluster: cluster,
        cluster: cluster.clone(),
        kontainerDrivers: globalStore.findAll('kontainerDriver'),
        nodeTemplates: globalStore.findAll('nodeTemplate'),
        nodeDrivers: globalStore.findAll('nodeDriver'),
        psps: globalStore.findAll('podSecurityPolicyTemplate'),
        roleTemplates: Ember.get(this, 'roleTemplateService').get('allFilteredRoleTemplates'),
        users: globalStore.findAll('user'),
        clusterRoleTemplateBinding: globalStore.findAll('clusterRoleTemplateBinding'),
        me: Ember.get(this, 'access.principal')
      });
    },
    afterModel: function afterModel(model) {
      var _this = this;

      // load the css/js url here, if the url loads fail we should error the driver out
      // show the driver in the ui, greyed out, and possibly add error text "can not load comonent from url [put url here]"

      var kontainerDrivers = model.kontainerDrivers;

      var externalDrivers = kontainerDrivers.filter(function (d) {
        return d.uiUrl !== '' && d.state === 'active';
      });
      var promises = {};

      externalDrivers.forEach(function (d) {
        if (Ember.get(d, 'hasUi')) {
          var jsUrl = (0, _loadScript.proxifyUrl)(d.uiUrl, _this.get('app.proxyEndpoint'));
          var cssUrl = (0, _loadScript.proxifyUrl)(d.uiUrl.replace(/\.js$/, '.css'), Ember.get(_this, 'app.proxyEndpoint'));

          // skip setProperties cause of weird names
          Ember.set(promises, d.name + 'Js', (0, _loadScript.loadScript)(jsUrl, 'driver-ui-js-' + d.name));
          Ember.set(promises, d.name + 'Css', (0, _loadScript.loadStylesheet)(cssUrl, 'driver-ui-css-' + d.name));
        }
      });

      if (Ember.isEmpty(promises)) {
        return model;
      } else {
        return Ember.RSVP.hashSettled(promises).then(function (settled) {
          var allkeys = Object.keys(settled);

          allkeys.forEach(function (key) {
            if (Ember.get(settled, key + '.state') === 'rejected') {
              var tmp = key.indexOf('Js') > -1 ? key.replace(/\Js$/, '') : key.replace(/\Css$/, '');
              var match = kontainerDrivers.findBy('id', tmp);

              console.log('Error Loading External Component for: ', match);
              if (match && Ember.get(match, 'scriptError') !== true) {
                Ember.set(match, 'scriptError', Ember.get(_this, 'intl').t('clusterNew.externalError'));
              }
            }
          });
        }).finally(function () {
          return model;
        });
      }
    },
    setupController: function setupController(controller /* , model*/) {
      this._super.apply(this, arguments);
      Ember.set(controller, 'step', 1);
    },
    resetController: function resetController(controller, isExiting /* , transition*/) {
      if (isExiting) {
        controller.set('errors', null);
        controller.set('provider', null);
      }
    },


    queryParams: { provider: { refreshModel: true } }
  });
});