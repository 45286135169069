define('ui/mixins/subscribe', ['exports', 'shared/mixins/subscribe'], function (exports, _subscribe) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _subscribe.default;
    }
  });
});