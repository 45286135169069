define('ui/components/notifier/notifier-row/component', ['exports', 'ui/components/notifier/notifier-row/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    intl: Ember.inject.service(),
    layout: _template.default,

    model: null,
    tagName: 'TR',
    classNames: 'main-row',
    bulkActions: true,

    showNotifierValue: function () {
      var t = Ember.get(this, 'model.notifierType');

      return t === 'slack' || t === 'email';
    }.property('model.notifierType')
  });
});