define('ui/authenticated/project/container-log/controller', ['exports', 'ui/mixins/console'], function (exports, _console) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_console.default, {

    bootstrap: function () {
      var body = Ember.$('body');
      var application = Ember.$('#application');

      body.css('overflow', 'hidden');

      application.css('padding-bottom', '0');
    }.on('init')

  });
});