define('ui/components/page-footer/component', ['exports', 'ui/utils/constants', 'ui/components/page-footer/template'], function (exports, _constants, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    intl: Ember.inject.service(),

    settings: Ember.inject.service(),
    prefs: Ember.inject.service(),
    modalService: Ember.inject.service('modal'),

    layout: _template.default,
    tagName: 'footer',
    className: 'clearfix',

    projectId: Ember.computed.alias('cookies.' + _constants.default.COOKIE.PROJECT),

    init: function init() {
      this._super.apply(this, arguments);
      var settings = this.get('settings');

      var cli = {};

      Object.keys(_constants.default.SETTING.CLI_URL).forEach(function (key) {
        cli[key.toLowerCase()] = settings.get(_constants.default.SETTING.CLI_URL[key]);
      });

      this.setProperties({ cli: cli });
    },


    actions: {
      showAbout: function showAbout() {
        this.get('modalService').toggleModal('modal-about', { closeWithOutsideClick: true });
      },
      showWechat: function showWechat() {
        this.get('modalService').toggleModal('modal-wechat', { closeWithOutsideClick: true });
      }
    },
    showWechat: Ember.computed('intl.locale', function () {
      var locale = this.get('intl.locale');

      if (locale) {
        return locale[0] === 'zh-hans';
      }

      return false;
    }),

    githubBase: _constants.default.EXT_REFERENCES.GITHUB,
    forumBase: _constants.default.EXT_REFERENCES.FORUM,
    cnforumBase: _constants.default.EXT_REFERENCES.CN_FORUM,
    slackBase: _constants.default.EXT_REFERENCES.SLACK

  });
});