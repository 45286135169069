define('ui/authenticated/cluster/security/members/new/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    globalStore: Ember.inject.service(),
    roleTemplateService: Ember.inject.service('roleTemplate'),

    // need to get all roles, we should have two roles and custom like the global perms
    // cluster owner, cluster-member, custom
    model: function model() {
      var gs = Ember.get(this, 'globalStore');
      var cid = this.paramsFor('authenticated.cluster');

      return Ember.RSVP.hash({
        cluster: gs.find('cluster', cid.cluster_id, { forceReload: true }),
        roles: Ember.get(this, 'roleTemplateService').get('allFilteredRoleTemplates'),
        roleBindings: gs.findAll('clusterRoleTemplateBinding')
      });
    }
  });
});