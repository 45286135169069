define('ui/components/input-command/component', ['exports', 'ui/components/input-command/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.OPS = undefined;
  exports.reop = reop;
  exports.unparse = unparse;

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  var ShellQuote = window.ShellQuote;

  var OPS = exports.OPS = ['||', '&&', ';;', '|&', '&', ';', '(', ')', '|', '<', '>'];
  function reop(xs) {
    return xs.map(function (s) {
      if (OPS.includes(s)) {
        return { op: s };
      } else {
        return s;
      }
    });
  }

  function unparse(xs) {
    return xs.map(function (s) {
      if (s && (typeof s === 'undefined' ? 'undefined' : _typeof(s)) === 'object') {
        if (s.hasOwnProperty('pattern')) {
          return '"' + s.pattern + '"';
        } else {
          return s.op;
        }
      } else if (/["\s]/.test(s) && !/'/.test(s)) {
        return '\'' + s.replace(/(['\\])/g, '\\$1') + '\'';
      } else if (/["'\s]/.test(s)) {
        return '"' + s.replace(/(["\\$`!])/g, '\\$1') + '"';
      } else {
        return String(s).replace(/([\\$`()!#&*|])/g, '\\$1');
      }
    }).join(' ');
  }

  exports.default = Ember.TextField.extend({
    layout: _template.default,
    type: 'text',

    init: function init() {
      this._super.apply(this, arguments);

      var initial = this.get('initialValue') || '';

      if (Ember.isArray(initial)) {
        this.set('value', unparse(reop(initial)));
      } else {
        this.set('value', initial);
      }
    },


    valueChanged: function () {
      var out = ShellQuote.parse(this.get('value') || '').map(function (piece) {
        if ((typeof piece === 'undefined' ? 'undefined' : _typeof(piece)) === 'object' && piece) {
          if (piece.pattern) {
            return piece.pattern;
          } else if (piece.op) {
            return piece.op;
          } else {
            return '';
          }
        }

        return piece;
      });

      if (out.length) {
        this.sendAction('changed', out);
      } else {
        this.sendAction('changed', null);
      }
    }.observes('value')
  });
});