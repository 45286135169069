define('ui/helpers/uc-first', ['exports', 'shared/helpers/uc-first'], function (exports, _ucFirst) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _ucFirst.default;
    }
  });
});