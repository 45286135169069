define('ui/components/modal-pipeline-yaml/component', ['exports', 'pipeline/components/modal-pipeline-yaml/component'], function (exports, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _component.default;
    }
  });
});