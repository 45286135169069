define('ui/authenticated/cluster/storage/classes/index/route', ['exports', 'ui/utils/constants'], function (exports, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      var cluster = this.modelFor('authenticated.cluster');

      if (!Ember.get(cluster, 'isReady')) {
        this.transitionTo('authenticated.cluster.index');
      }

      return Ember.RSVP.hash({ storageClasses: Ember.get(this, 'clusterStore').findAll('storageClass') });
    },


    setDefaultRoute: Ember.on('activate', function () {
      Ember.set(this, 'session.' + _constants.default.SESSION.CLUSTER_ROUTE, 'authenticated.cluster.storage.classes');
    })
  });
});