define('ui/signup/controller', ['exports', '@rancher/ember-api-store/utils/fetch'], function (exports, _fetch) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    settings: Ember.inject.service(),

    emailSent: false,
    saving: false,
    saveDisabled: true,
    actions: {
      register: function register() {
        var _this = this;

        this.set('saving', true);

        (0, _fetch.default)('/register-new', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(this.get('model'))
        }).then(function () {
          _this.set('saving', false);
          _this.set('emailSent', true);
        }).catch(function (err) {
          if (err.status === 409) {
            _this.set('showReset', true);
          }
          _this.set('saving', false);
          _this.set('errors', [err.body.detail]);
        });
      },
      cancel: function cancel() {
        if (this.get('errors')) {
          this.set('errors', []);
        }
        this.transitionToRoute('login');
      }
    },
    validate: Ember.observer('model.name', 'model.email', function () {
      if (this.get('model.name') && this.get('model.email')) {
        if (this.get('errors')) {
          this.set('errors', []);
        }
        this.set('saveDisabled', false);
      } else {
        this.set('saveDisabled', true);
      }
    })
  });
});