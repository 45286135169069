define('ui/authenticated/project/secrets/route', ['exports', 'ui/utils/constants'], function (exports, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      var store = Ember.get(this, 'store');

      return Ember.RSVP.hash({
        projectSecrets: store.findAll('secret'),
        namespacedSecrets: store.findAll('namespacedSecret')
      });
    },


    setDefaultRoute: Ember.on('activate', function () {
      Ember.set(this, 'session.' + _constants.default.SESSION.PROJECT_ROUTE, 'authenticated.project.secrets');
    })
  });
});