define('ui/components/modal-kubeconfig/component', ['exports', 'shared/mixins/modal-base', 'ui/components/modal-kubeconfig/template'], function (exports, _modalBase, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_modalBase.default, {
    globalStore: Ember.inject.service(),
    access: Ember.inject.service(),
    growl: Ember.inject.service(),
    scope: Ember.inject.service(),
    settings: Ember.inject.service(),
    modalService: Ember.inject.service('modal'),

    layout: _template.default,
    classNames: ['modal-container', 'large-modal', 'alert'],
    kubeconfig: null,

    step: 1,

    downloadUrl: 'http://kubernetes.io/docs/user-guide/prereqs/',

    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      Ember.get(this, 'scope.currentCluster').doAction('generateKubeconfig').then(function (obj) {
        Ember.set(_this, 'kubeconfig', Ember.get(obj, 'config'));
        Ember.set(_this, 'step', 2);
      }).catch(function (err) {
        _this.get('growl').fromError('Error creating kubeconfig file', err);
        _this.get('modalService').toggleModal();
      });
    },
    didInsertElement: function didInsertElement() {
      this._super();
      Ember.run.next(this, function () {
        var btn = $('.close-kubeconfig')[0]; // eslint-disable-line

        if (btn) {
          btn.focus();
        }
      });
    }
  });
});