define('ui/components/container/form-upgrade-stateful-set/component', ['exports', 'ui/components/container/form-upgrade-stateful-set/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,

    didReceiveAttrs: function didReceiveAttrs() {
      var config = Ember.get(this, 'workloadConfig');
      var strategy = Ember.get(config, 'strategy');

      if (!strategy) {
        Ember.set(config, 'strategy', 'RollingUpdate');
      }
    }
  });
});