define('ui/components/modal-edit-setting/component', ['exports', 'shared/settings/service', 'shared/mixins/modal-base', 'ui/components/modal-edit-setting/template'], function (exports, _service, _modalBase, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var cmOpts = {
    autofocus: true,
    gutters: ['CodeMirror-lint-markers'],
    lineNumbers: true,
    lineWrapping: true,
    lint: true,
    mode: {
      name: 'javascript',
      json: true
    },
    theme: 'monokai',
    viewportMargin: Infinity
  };

  exports.default = Ember.Component.extend(_modalBase.default, {
    settings: Ember.inject.service(),
    growl: Ember.inject.service(),
    layout: _template.default,
    classNames: ['modal-edit-setting', 'span-8', 'offset-2'],

    codeMirrorOptions: cmOpts,
    value: null,
    formattedValue: null,
    removing: false,

    model: Ember.computed.alias('modalService.modalOpts'),

    init: function init() {
      this._super.apply(this, arguments);

      if (Ember.get(this, 'model.kind') === 'json') {
        Ember.set(this, 'formattedValue', JSON.stringify(JSON.parse(Ember.get(this, 'model.obj.value')), undefined, 2));
      } else {
        Ember.set(this, 'value', Ember.get(this, 'model.obj.value') || '');
      }
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      Ember.run.next(function () {
        if (_this.isDestroyed || _this.isDestroying) {
          return;
        }

        var elem = _this.$('.form-control')[0];

        if (elem) {
          setTimeout(function () {
            elem.focus();
          }, 250);
        }
      });
    },


    actions: {
      save: function save(btnCb) {
        var _this2 = this;

        Ember.get(this, 'settings').set((0, _service.normalizeName)(Ember.get(this, 'model.key')), Ember.get(this, 'value'));
        Ember.get(this, 'settings').one('settingsPromisesResolved', function () {
          btnCb(true);
          _this2.send('done');
        });
      },
      done: function done() {
        this.send('cancel');
        window.location.href = window.location.href;
      },
      updateJson: function updateJson(json) {
        Ember.set(this, 'value', json);
      }
    }
  });
});