define('ui/components/container/form-command/component', ['exports', 'ui/components/container/form-command/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    intl: Ember.inject.service(),

    layout: _template.default,

    // Inputs
    instance: null,
    errors: null,
    editing: true,
    service: null,
    isSidekick: null,
    scaleMode: null,

    // ----------------------------------
    terminal: null,
    statusClass: null,
    status: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.initTerminal();
      this.scaleModeDidChange();
    },


    terminalDidChange: Ember.observer('terminal.type', function () {
      var val = Ember.get(this, 'terminal.type');
      var stdin = val === 'interactive' || val === 'both';
      var tty = val === 'terminal' || val === 'both';

      Ember.set(this, 'instance.tty', tty);
      Ember.set(this, 'instance.stdin', stdin);
    }),

    scaleModeDidChange: Ember.observer('scaleMode', function () {
      var restartPolicy = Ember.get(this, 'service.restartPolicy');

      if (Ember.get(this, 'isJob')) {
        if (restartPolicy === 'Always') {
          Ember.set(this, 'service.restartPolicy', 'Never');
        }
      } else {
        Ember.set(this, 'service.restartPolicy', 'Always');
      }
    }),

    isJob: Ember.computed('scaleMode', function () {
      return Ember.get(this, 'scaleMode') === 'job' || Ember.get(this, 'scaleMode') === 'cronJob';
    }),

    // ----------------------------------
    // Terminal
    // 'both',
    initTerminal: function initTerminal() {
      var instance = Ember.get(this, 'instance');
      var tty = Ember.get(instance, 'tty');
      var stdin = Ember.get(instance, 'stdin');
      var out = {
        type: 'both',
        name: Ember.get(this, 'intl').tHtml('formCommand.console.both')
      };

      if (tty !== undefined || stdin !== undefined) {
        if (tty && stdin) {
          out.type = 'both';
          out.name = Ember.get(this, 'intl').tHtml('formCommand.console.both');
        } else if (tty) {
          out.type = 'terminal';
          out.name = Ember.get(this, 'intl').tHtml('formCommand.console.terminal');
        } else if (stdin) {
          out.type = 'interactive';
          out.name = Ember.get(this, 'intl').tHtml('formCommand.console.interactive');
        } else {
          out.type = 'none';
          out.name = Ember.get(this, 'intl').tHtml('formCommand.console.none');
        }
      }

      Ember.set(this, 'terminal', out);
      this.terminalDidChange();
    }
  });
});