define('ui/authenticated/cluster/storage/classes/new/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() /* params, transition*/{
      return this.get('clusterStore').createRecord({
        type: 'storageClass',
        provisioner: 'kubernetes.io/aws-ebs',
        reclaimPolicy: 'Delete',
        allowVolumeExpansion: false
      });
    }
  });
});