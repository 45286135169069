define('ui/authenticated/cluster/projects/index/route', ['exports', 'ui/utils/constants'], function (exports, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    globalStore: Ember.inject.service(),
    clusterStore: Ember.inject.service(),
    scope: Ember.inject.service(),

    shortcuts: { 'g': 'toggleGrouping' },
    model: function model() {
      var cluster = this.modelFor('authenticated.cluster');

      if (!Ember.get(cluster, 'isReady')) {
        this.transitionTo('authenticated.cluster.index');
      }

      return Ember.RSVP.hash({
        projects: Ember.get(this, 'globalStore').findAll('project'),
        namespaces: Ember.get(this, 'clusterStore').findAll('namespace')
      });
    },


    actions: {
      toggleGrouping: function toggleGrouping() {
        var _this = this;

        var choices = ['none', 'project'];
        var cur = this.get('controller.group');
        var neu = choices[(choices.indexOf(cur) + 1) % choices.length];

        Ember.run.next(function () {
          _this.set('controller.group', neu);
        });
      }
    },

    setDefaultRoute: Ember.on('activate', function () {
      Ember.set(this, 'session.' + _constants.default.SESSION.CLUSTER_ROUTE, 'authenticated.cluster.projects');
    })

  });
});