define('ui/initializers/touch', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize() /* application*/{
    // Add 'touch' or 'no-touch' to the <body> so CSS can depend on the device type.

    var body = $('BODY'); // eslint-disable-line

    if ('ontouchstart' in document.documentElement) {
      // Has touch, like an iPad
      body.addClass('touch');
    } else {
      // Does not have touch, like a desktop
      body.addClass('no-touch');
    }
  }

  exports.default = {
    name: 'touch',
    initialize: initialize
  };
});