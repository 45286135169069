define('ui/utils/util', ['exports', 'shared/utils/util'], function (exports, _util) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _util.default;
    }
  });
  Object.defineProperty(exports, 'arrayDiff', {
    enumerable: true,
    get: function () {
      return _util.arrayDiff;
    }
  });
  Object.defineProperty(exports, 'arrayIntersect', {
    enumerable: true,
    get: function () {
      return _util.arrayIntersect;
    }
  });
  Object.defineProperty(exports, 'filterByValues', {
    enumerable: true,
    get: function () {
      return _util.filterByValues;
    }
  });
  Object.defineProperty(exports, 'deepCopy', {
    enumerable: true,
    get: function () {
      return _util.deepCopy;
    }
  });
  Object.defineProperty(exports, 'download', {
    enumerable: true,
    get: function () {
      return _util.download;
    }
  });
  Object.defineProperty(exports, 'popupWindowOptions', {
    enumerable: true,
    get: function () {
      return _util.popupWindowOptions;
    }
  });
  Object.defineProperty(exports, 'escapeHtml', {
    enumerable: true,
    get: function () {
      return _util.escapeHtml;
    }
  });
  Object.defineProperty(exports, 'escapeRegex', {
    enumerable: true,
    get: function () {
      return _util.escapeRegex;
    }
  });
  Object.defineProperty(exports, 'addQueryParams', {
    enumerable: true,
    get: function () {
      return _util.addQueryParams;
    }
  });
  Object.defineProperty(exports, 'parseUrl', {
    enumerable: true,
    get: function () {
      return _util.parseUrl;
    }
  });
  Object.defineProperty(exports, 'absoluteUrl', {
    enumerable: true,
    get: function () {
      return _util.absoluteUrl;
    }
  });
  Object.defineProperty(exports, 'addAuthorization', {
    enumerable: true,
    get: function () {
      return _util.addAuthorization;
    }
  });
  Object.defineProperty(exports, 'ucFirst', {
    enumerable: true,
    get: function () {
      return _util.ucFirst;
    }
  });
  Object.defineProperty(exports, 'roundValue', {
    enumerable: true,
    get: function () {
      return _util.roundValue;
    }
  });
  Object.defineProperty(exports, 'strPad', {
    enumerable: true,
    get: function () {
      return _util.strPad;
    }
  });
  Object.defineProperty(exports, 'sortableNumericSuffix', {
    enumerable: true,
    get: function () {
      return _util.sortableNumericSuffix;
    }
  });
  Object.defineProperty(exports, 'timerFuzz', {
    enumerable: true,
    get: function () {
      return _util.timerFuzz;
    }
  });
  Object.defineProperty(exports, 'random32', {
    enumerable: true,
    get: function () {
      return _util.random32;
    }
  });
  Object.defineProperty(exports, 'randomStr', {
    enumerable: true,
    get: function () {
      return _util.randomStr;
    }
  });
  Object.defineProperty(exports, 'formatPercent', {
    enumerable: true,
    get: function () {
      return _util.formatPercent;
    }
  });
  Object.defineProperty(exports, 'formatMib', {
    enumerable: true,
    get: function () {
      return _util.formatMib;
    }
  });
  Object.defineProperty(exports, 'formatSecond', {
    enumerable: true,
    get: function () {
      return _util.formatSecond;
    }
  });
  Object.defineProperty(exports, 'formatKbps', {
    enumerable: true,
    get: function () {
      return _util.formatKbps;
    }
  });
  Object.defineProperty(exports, 'formatGB', {
    enumerable: true,
    get: function () {
      return _util.formatGB;
    }
  });
  Object.defineProperty(exports, 'constructUrl', {
    enumerable: true,
    get: function () {
      return _util.constructUrl;
    }
  });
  Object.defineProperty(exports, 'pluralize', {
    enumerable: true,
    get: function () {
      return _util.pluralize;
    }
  });
  Object.defineProperty(exports, 'uniqKeys', {
    enumerable: true,
    get: function () {
      return _util.uniqKeys;
    }
  });
  Object.defineProperty(exports, 'camelToTitle', {
    enumerable: true,
    get: function () {
      return _util.camelToTitle;
    }
  });
  Object.defineProperty(exports, 'isNumeric', {
    enumerable: true,
    get: function () {
      return _util.isNumeric;
    }
  });
});