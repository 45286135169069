define('ui/components/page-header-project/component', ['exports', 'ui/components/page-header-project/template', 'shared/mixins/throttled-resize', 'shared/utils/text-width', 'shared/utils/util'], function (exports, _template, _throttledResize, _textWidth, _util) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var ITEM_HEIGHT = 50;
  var BUFFER_HEIGHT = 150;
  var BUFFER_WIDTH = 150;
  var MIN_COLUMN_WIDTH = 200;
  var FONT = '13.5px "Prompt", "Helvetica Neue Light", "Helvetica Neue", "Helvetica", "Arial", sans-serif';
  var MOUSE_HISTORY = 5;
  var MOUSE_DELAY = 250;
  var SLOP = 50; // Extend the ends of the target triangle out by this many px

  exports.default = Ember.Component.extend(_throttledResize.default, {
    access: Ember.inject.service(),
    scope: Ember.inject.service(),
    globalStore: Ember.inject.service(),
    router: Ember.inject.service(),

    layout: _template.default,
    pageScope: null,

    tagName: 'LI',
    classNames: ['dropdown', 'nav-item', 'nav-cluster'],
    classNameBindings: ['hide'],

    searchInput: '',
    open: false,

    columnStyle: '',
    menuStyle: '',
    mousePoints: null,
    clusterEntry: null,
    activeClusterEntry: null,
    hoverEntry: null,
    hoverDelayTimer: null,
    delayPoint: null,
    leaveDelayTimer: null,

    boundClickMenu: null,
    boundClickItem: null,
    boundEnterCluster: null,

    project: Ember.computed.alias('scope.pendingProject'),
    cluster: Ember.computed.alias('scope.pendingCluster'),
    numClusters: Ember.computed.alias('byCluster.length'),

    init: function init() {
      this._super.apply(this, arguments);
      Ember.set(this, 'mousePoints', []);
      Ember.set(this, 'boundMouseMove', this.mouseMoved.bind(this));
      Ember.set(this, 'boundClickMenu', this.clickMenu.bind(this));
      Ember.set(this, 'boundClickItem', this.clickItem.bind(this));
      Ember.set(this, 'boundEnterCluster', this.enterCluster.bind(this));
      Ember.set(this, 'boundEnterScrollers', this.enterScrollers.bind(this));
      Ember.set(this, 'boundLeaveScrollers', this.leaveScrollers.bind(this));
    },


    actions: {
      onOpen: function onOpen() {
        var _this = this;

        Ember.set(this, 'open', true);
        this.onResize();

        Ember.run.next(function () {
          var menu = _this.$('.project-menu');
          var clusters = _this.$('.clusters');

          Ember.$(document).on('mousemove', _this.boundMouseMove);

          menu.on('click', _this.boundClickMenu);
          menu.on('click', 'LI', _this.boundClickItem);

          clusters.on('focus', 'LI', _this.boundEnterCluster);
          clusters.on('mouseenter', 'LI', _this.boundEnterCluster);

          _this.$('.clusters, .projects').on('mouseenter', _this.boundEnterScrollers);
          _this.$('.clusters, .projects').on('mouseleave', _this.boundLeaveScrollers);

          _this.$('.search INPUT')[0].focus();

          _this.$('.clusters UL')[0].scrollTop = 0;
          _this.$('.projects UL')[0].scrollTop = 0;

          var currentClusterId = Ember.get(_this, 'cluster.id');
          var currentProjectId = Ember.get(_this, 'project.id');

          if (currentClusterId) {
            var li = _this.$('.clusters LI[data-cluster-id="' + currentClusterId + '"]')[0];
            var entry = Ember.get(_this, 'byCluster').findBy('clusterId', currentClusterId);

            ensureVisible(li);
            Ember.set(_this, 'clusterEntry', entry);
            Ember.set(_this, 'activeClusterEntry', entry);
          }

          if (currentProjectId) {
            Ember.run.next(function () {
              var li = _this.$('.projects LI[data-project-id="' + currentProjectId + '"]')[0];

              ensureVisible(li);
            });
          }
        });
      },
      onClose: function onClose() {
        Ember.set(this, 'open', false);
        Ember.set(this, 'searchInput', '');
        Ember.set(this, 'hoverEntry', null);
        Ember.set(this, 'clusterEntry', null);
        Ember.set(this, 'activeClusterEntry', null);

        Ember.$(document).off('mousemove', this.boundMouseMove);
        this.$('.project-menu').off('click', this.boundClickMenu);
        this.$('.project-menu').off('click', 'LI', this.boundClickItem);
        this.$('.clusters').off('mouseenter', 'LI', this.boundEnterCluster);
        this.$('.clusters, .projects').off('mouseenter', this.boundEnterScrollers);
        this.$('.clusters, .projects').off('mouseleave', this.boundLeaveScrollers);
      }
    },

    twoLine: Ember.computed('pageScope', function () {
      return Ember.get(this, 'pageScope') === 'project';
    }),

    hide: Ember.computed('pageScope', function () {
      return Ember.get(this, 'pageScope') === 'user';
    }),

    projectChoices: Ember.computed('scope.allProjects.@each.{id,displayName,relevantState}', function () {
      return Ember.get(this, 'scope.allProjects').sortBy('displayName', 'id');
    }),

    maxProjects: Ember.computed('byCluster.@each.numProjects', function () {
      var counts = Ember.get(this, 'byCluster').map(function (x) {
        return x.projects.length;
      });

      return Math.max.apply(Math, _toConsumableArray(counts));
    }),

    byCluster: Ember.computed('scope.allClusters.@each.id', 'projectChoices.@each.clusterId', 'cluster.id', function () {
      var currentClusterId = Ember.get(this, 'cluster.id');
      var out = [];

      Ember.get(this, 'scope.allClusters').forEach(function (cluster) {
        getOrAddCluster(cluster);
      });

      Ember.get(this, 'projectChoices').forEach(function (project) {
        var cluster = Ember.get(project, 'cluster');
        var width = (0, _textWidth.default)(Ember.get(project, 'displayName'), FONT);

        if (!cluster) {
          return;
        }

        var entry = getOrAddCluster(cluster);

        entry.projects.push(project);
        entry.projectWidth = Math.max(entry.projectWidth, width);
      });

      out.forEach(function (entry) {
        entry.projects = entry.projects.sortBy('sortName');
      });

      return out.sortBy('cluster.sortName');

      function getOrAddCluster(cluster) {
        var clusterId = Ember.get(cluster, 'id');
        var entry = out.findBy('clusterId', clusterId);

        if (!entry) {
          entry = {
            clusterId: clusterId,
            cluster: cluster,

            width: (0, _textWidth.default)(Ember.get(cluster, 'displayName'), FONT),
            projectWidth: 0,
            projects: [],
            active: clusterId === currentClusterId
          };

          out.push(entry);
        }

        return entry;
      }
    }),

    clustersWidth: Ember.computed('byCluster.@each.width', function () {
      var widths = Ember.get(this, 'byCluster').map(function (x) {
        return Ember.get(x, 'width');
      });

      return Math.max.apply(Math, _toConsumableArray(widths));
    }),

    projectsWidth: Ember.computed('byCluster.@each.projectWidth', function () {
      var widths = Ember.get(this, 'byCluster').map(function (x) {
        return Ember.get(x, 'projectWidth');
      });

      return Math.max.apply(Math, _toConsumableArray(widths));
    }),

    clusterSearchResults: Ember.computed('searchInput', 'byCluster.[]', function () {
      var needle = Ember.get(this, 'searchInput');
      var out = [];

      Ember.get(this, 'byCluster').forEach(function (entry) {
        var cluster = Ember.get(entry, 'cluster');
        var name = Ember.get(cluster, 'displayName');

        var _highlightMatches = highlightMatches(needle, name),
            found = _highlightMatches.found,
            match = _highlightMatches.match;

        if (found) {
          out.push({
            cluster: cluster,
            searchMatch: match
          });
        }
      });

      return out;
    }),

    projectSearchResults: Ember.computed('searchInput', 'byCluster.[]', function () {
      var needle = Ember.get(this, 'searchInput');
      var out = [];

      Ember.get(this, 'projectChoices').forEach(function (project) {
        var name = Ember.get(project, 'displayName');

        var _highlightMatches2 = highlightMatches(needle, name),
            found = _highlightMatches2.found,
            match = _highlightMatches2.match;

        if (found) {
          out.push({
            project: project,
            cluster: Ember.get(project, 'cluster'),
            searchMatch: match
          });
        }
      });

      return out;
    }),

    mouseMoved: function mouseMoved(e) {
      var list = this.mousePoints;
      var x = e.pageX;
      var y = e.pageY;

      if (list.length) {
        var last = list[list.length - 1];

        if (last.x === x && last.y === y) {
          // Not a movement
          return;
        }
      }

      if (list.length >= MOUSE_HISTORY) {
        list.shift();
      }

      list.push({
        x: x,
        y: y
      });
    },
    clickMenu: function clickMenu(e) {
      if (e.target.tagName === 'INPUT') {
        e.stopPropagation();

        return;
      }
    },
    clickItem: function clickItem(e) {
      var tag = e.target.tagName;

      var li = Ember.$(e.target).closest('LI');

      if (!li) {
        return;
      }

      if (li.hasClass('not-ready')) {
        e.stopPropagation();
        e.preventDefault();

        return;
      }

      if (tag === 'A') {
        return;
      }

      var a = Ember.$('A', li)[0];

      if (!a) {
        return;
      }

      a.click();
    },
    enterCluster: function enterCluster(e) {
      if (Ember.get(this, 'searchInput')) {
        return;
      }

      clearTimeout(this.hoverDelayTimer);

      var $li = Ember.$(e.target).closest('LI');
      var id = $li.data('cluster-id');

      if (id) {
        var entry = Ember.get(this, 'byCluster').findBy('clusterId', id);

        this.maybeHover(entry);
      }
    },
    enterScrollers: function enterScrollers() {
      clearTimeout(this.leaveDelayTimer);
    },
    leaveScrollers: function leaveScrollers() {
      var _this2 = this;

      clearTimeout(this.hoverDelayTimer);

      Ember.set(this, 'leaveDelayTimer', setTimeout(function () {
        Ember.set(_this2, 'hoverEntry', null);
        Ember.set(_this2, 'clusterEntry', Ember.get(_this2, 'activeClusterEntry'));
      }, MOUSE_DELAY));
    },
    getHoverDelay: function getHoverDelay() {
      var entry = Ember.get(this, 'activeClusterEntry');
      var points = this.mousePoints;
      var $menu = this.$('.clusters');

      if (!entry) {
        // console.log('No entry');
        return 0;
      }

      if (!points.length) {
        // console.log('No points');
        return 0;
      }

      var prev = points[0];
      var now = points[points.length - 1];

      // Bounding box of the menu
      var offset = $menu.offset();
      var left = offset.left;
      var top = offset.top - SLOP;
      var right = left + $menu.outerWidth();
      var bottom = offset.top + $menu.outerHeight() + SLOP;
      var dp = this.delayPoint;

      if (dp && dp.x === now.x && dp.y === now.y) {
        // The mouse hasn't moved during the delay
        // console.log('No movement');
        return 0;
      }

      if (now.x < prev.x) {
        // The mouse is moving left
        // console.log('Moving left');
        return 0;
      }

      var nowSlope = slope(prev, now);
      var topSlope = slope(prev, {
        x: right,
        y: top
      }); // negative; 0,0 is top-left
      var botSlope = slope(prev, {
        x: right,
        y: bottom
      }); // positive

      var noMove = prev.x === now.x && prev.y === now.y;
      var topOk = nowSlope >= topSlope;
      var botOk = nowSlope <= botSlope;

      if (noMove || topOk && botOk) {
        // Moving towards submenu
        this.delayPoint = now;

        // console.log('Ok');
        return MOUSE_DELAY;
      }

      // console.log('Default');
      this.delayPoint = null;

      return 0;
    },
    maybeHover: function maybeHover(entry) {
      var _this3 = this;

      clearTimeout(this.hoverDelayTimer);

      var delay = this.getHoverDelay();

      if (delay) {
        this.hoverDelayTimer = setTimeout(function () {
          _this3.maybeHover(entry);
        }, delay);
      } else {
        var prev = Ember.get(this, 'hoverEntry');

        if (entry !== prev) {
          Ember.set(this, 'hoverEntry', entry);
          Ember.set(this, 'clusterEntry', entry);

          var scrollToId = void 0;

          entry.projects.forEach(function (project) {
            if (project.active) {
              scrollToId = project.id;
            }
          });

          if (scrollToId) {
            Ember.run.next(function () {
              var li = _this3.$('.projects LI[data-project-id="' + scrollToId + '"]')[0];

              ensureVisible(li);
            });
          }
        }
      }
    },
    onResize: function onResize() {
      if (!Ember.get(this, 'open')) {
        return;
      }

      var $window = Ember.$(window);
      var want = Math.max(Ember.get(this, 'numClusters'), Ember.get(this, 'maxProjects'));
      var roomFor = Math.ceil(($window.height() - BUFFER_HEIGHT) / (2 * ITEM_HEIGHT));

      var rows = Math.max(3, Math.min(want, roomFor));
      var height = rows * ITEM_HEIGHT;

      Ember.set(this, 'columnStyle', ('height: ' + height + 'px').htmlSafe());

      var cw = Math.max(MIN_COLUMN_WIDTH, Ember.get(this, 'clustersWidth') + 60); // 20px icon, 20px padding, 20px scrollbar
      var pw = Math.max(MIN_COLUMN_WIDTH, Ember.get(this, 'projectsWidth') + 60);

      want = cw + pw;
      roomFor = $window.width() - BUFFER_WIDTH;

      if (want > roomFor) {
        cw = Math.floor(cw * roomFor / want);
        pw = roomFor - cw;
      }

      Ember.set(this, 'menuStyle', ('grid-template-columns: ' + cw + 'px ' + pw + 'px').htmlSafe());
    }
  });


  function highlightMatches(needle, haystack) {
    // This is more complicated than it sounds because:
    // - Needle matches case-insensitive, but the return string should preseve the original haystack case
    // - The haystack has to be HTML escaped
    // - But the HTML entities like &lt; shouldn't appear as search results for "lt"
    // - And we're adding HTML to highlight the matches which needs to not be escaped
    //
    var placeholder = '~';
    var match = void 0;
    var found = false;
    var parts = [];

    needle = (needle || '').trim();
    haystack = (haystack || '').trim();

    // 1. If there's any occurrences of the placeholder in the string already, drop them.
    haystack = haystack.replace(placeholder, '', 'g');

    var re = new RegExp((0, _util.escapeRegex)(needle), 'i');

    // 2. Find and save all matches for the needle and replace with placeholder
    /* eslint-disable-next-line no-cond-assign */
    while (match = haystack.match(re)) {
      found = true;
      parts.push(match[0]);
      haystack = haystack.replace(re, placeholder);
    }

    if (!found) {
      return { found: found };
    }

    // 3. Escape the resulting string of unmatched chars and placeholders
    haystack = (0, _util.escapeHtml)(haystack);
    while (parts.length) {
      var token = parts.shift();

      // 4. Replace placeholders with (unescaped) highlight span and (escaped) matched chars
      haystack = haystack.replace(placeholder, '<span class="search-match">' + (0, _util.escapeHtml)(token) + '</span>');
    }

    // 5. Return as a safe string
    return {
      found: found,
      match: haystack.htmlSafe()
    };
  }

  function slope(a, b) {
    return round((b.y - a.y) / (b.x - a.x));
  }

  function round(n) {
    return Math.round(n * 10000) / 10000;
  }

  function ensureVisible(li) {
    var $li = Ember.$(li);
    var $ul = $li.closest('UL');
    var ul = $ul[0];

    if (!ul) {
      return;
    }

    var ulTop = $ul.scrollTop();
    var ulBottom = ulTop + $ul.outerHeight();
    var offset = $li.offset();

    if (!offset) {
      return;
    }

    var liTop = offset.top;
    var liBottom = liTop + $li.outerHeight();

    // console.log(`${ulTop} to ${ulBottom}, ${liTop} to ${liBottom}`);

    if (liTop < ulTop || liBottom > ulBottom) {
      ul.scrollTop = Math.max(0, liTop - (liBottom - liTop) / 2 + (ulBottom - ulTop) / 2);
    }
  }
});