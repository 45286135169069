define('ui/authenticated/cluster/storage/classes/index/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var headers = exports.headers = [{
    name: 'state',
    sort: ['sortState', 'displayName'],
    searchField: 'displayState',
    translationKey: 'generic.state',
    width: 120
  }, {
    name: 'name',
    sort: ['sortName', 'id'],
    searchField: 'displayName',
    translationKey: 'generic.name'
  }, {
    name: 'provisioner',
    sort: ['provisioner', 'name', 'id'],
    searchField: ['displayProvisioner', 'provisioner'],
    translationKey: 'storageClassPage.provisioner.label'
  }, {
    name: 'default',
    sort: ['isDefault', 'name', 'id'],
    searchField: null,
    translationKey: 'storageClassPage.default.label',
    width: 100
  }];

  exports.default = Ember.Controller.extend({
    queryParams: ['sortBy'],
    sortBy: 'name',
    headers: headers,
    rows: Ember.computed.alias('model.storageClasses')
  });
});