define('ui/components/modal-edit-certificate/component', ['exports', 'shared/mixins/new-or-edit', 'shared/mixins/modal-base', 'ui/components/modal-edit-certificate/template'], function (exports, _newOrEdit, _modalBase, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_modalBase.default, _newOrEdit.default, {
    intl: Ember.inject.service(),

    layout: _template.default,
    classNames: ['large-modal'],
    editing: true,
    model: null,
    originalModel: Ember.computed.alias('modalService.modalOpts'),
    init: function init() {
      this._super.apply(this, arguments);
      this.set('model', this.get('originalModel').clone());
    },
    manageModel: function manageModel() {
      var clone = this.get('originalModel');
      var model = this.get('model');

      if (clone.get('key') === model.get('key')) {
        delete model.key;
      }
    },
    validate: function validate() {
      var model = this.get('model');
      var errors = this.get('errors') || [];
      var intl = this.get('intl');

      // key is the only node that can be deleted safely
      this.manageModel();

      if (!model.cert) {
        errors.push(intl.t('validation.required', { key: 'cert' }));
      }

      if (model.get('name') === null) {
        errors.push(intl.t('validation.required', { key: 'name' }));
      }

      this.set('errors', null);

      return true;
    },
    doneSaving: function doneSaving() {
      this.send('cancel');
    }
  });
});