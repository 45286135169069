define('ui/verify-reset-password/controller', ['exports', '@rancher/ember-api-store/utils/fetch'], function (exports, _fetch) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    passwordRest: false,
    loading: false,
    password: null,
    canSend: false,
    actions: {
      resetPassword: function resetPassword() {
        var _this = this;

        var body = this.get('model');

        body.token = this.get('token');
        body.pw = this.get('password');
        this.set('loading', true);
        (0, _fetch.default)('/update-password', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(body)
        }).then(function () {
          _this.set('loading', false);
          window.location.href = '/';
        }).catch(function (err) {
          _this.set('loading', false);
          _this.set('errors', [err.body.detail]);
        });
      }
    }
  });
});