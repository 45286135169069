define('ui/components/container/form-image/component', ['exports', 'ui/components/container/form-image/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  // Remember the last value and use that for new one
  var lastContainer = 'ubuntu:xenial';

  exports.default = Ember.Component.extend({
    scope: Ember.inject.service(),

    layout: _template.default,
    // Inputs
    initialValue: null,
    errors: null,

    userInput: null,
    tagName: '',
    value: null,
    allPods: null,

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      Ember.set(this, 'allPods', Ember.get(this, 'store').all('pod'));

      var initial = Ember.get(this, 'initialValue') || '';

      if (!initial) {
        initial = lastContainer;
      }

      Ember.run.scheduleOnce('afterRender', function () {
        _this.send('setInput', initial);
        _this.userInputDidChange();
      });
    },


    actions: {
      setInput: function setInput(str) {
        Ember.set(this, 'userInput', str);
      }
    },

    userInputDidChange: Ember.observer('userInput', function () {
      var input = (Ember.get(this, 'userInput') || '').trim();
      var out;

      if (input && input.length) {
        lastContainer = input;
        out = input;
      } else {
        out = null;
      }

      Ember.set(this, 'value', out);
      this.sendAction('changed', out);
      this.validate();
    }),

    suggestions: Ember.computed('allPods.@each.containers', function () {
      var inUse = [];

      Ember.get(this, 'allPods').forEach(function (pod) {
        inUse.addObjects(pod.get('containers') || []);
      });

      inUse = inUse.map(function (obj) {
        return obj.get('image') || '';
      }).filter(function (str) {
        return !str.includes('sha256:') && !str.startsWith('rancher/');
      }).uniq().sort();

      return { 'Used by other containers': inUse };
    }),

    validate: function validate() {
      var errors = [];

      if (!Ember.get(this, 'value')) {
        errors.push('Image is required');
      }

      Ember.set(this, 'errors', errors);
    }
  });
});