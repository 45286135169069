define('ui/verify/controller', ['exports', '@rancher/ember-api-store/utils/fetch'], function (exports, _fetch) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    access: Ember.inject.service(),
    accountCreated: false,
    loading: false,
    canSend: false,

    actions: {
      createAcct: function createAcct() {
        var _this = this;

        var body = this.get('model');

        body.token = this.get('token');

        this.set('loading', true);

        (0, _fetch.default)('/create-user', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(body)
        }).then(function () {
          var code = body.email + ':' + body.pw;

          _this.get('access').login(code).then(function () {
            _this.transitionToRoute('authenticated');
            _this.set('loading', false);
          }).catch(function (err) {
            _this.set('saving', false);
            _this.set('errors', [err.body.detail]);
          });
        }).catch(function (err) {
          _this.set('saving', false);
          _this.set('errors', [err.body.detail]);
        });
      }
    }
  });
});