define('ui/components/volume-source/source-host-path/component', ['exports', 'ui/components/volume-source/source-host-path/template', 'shared/mixins/volume-source'], function (exports, _template, _volumeSource) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_volumeSource.default, {
    layout: _template.default,
    field: 'hostPath',

    kindChoices: Ember.computed(function () {
      var out = [{
        label: 'volumeSource.host-path.kind.Any',
        value: ''
      }];

      ['DirectoryOrCreate', 'FileOrCreate', 'Directory', 'File', 'Socket', 'CharDevice', 'BlockDevice'].forEach(function (value) {
        out.push({
          label: 'volumeSource.host-path.kind.' + value,
          value: value
        });
      });

      return out;
    })
  });
});