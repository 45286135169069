define('ui/components/credit-card', ['exports', 'ember-credit-card/components/credit-card'], function (exports, _creditCard) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _creditCard.default;
    }
  });
});