define('ui/components/new-edit-project/component', ['exports', 'ui/components/new-edit-project/template', 'ui/mixins/new-or-edit', 'shared/mixins/child-hook'], function (exports, _template, _newOrEdit, _childHook) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var M_CONFIG = {
    type: 'projectRoleTemplateBinding',
    subjectKind: '',
    userId: '',
    projectRoleTemplateId: '',
    projectId: ''
  };

  exports.default = Ember.Component.extend(_newOrEdit.default, _childHook.default, {
    intl: Ember.inject.service(),
    router: Ember.inject.service(),
    globalStore: Ember.inject.service(),
    layout: _template.default,
    memberConfig: M_CONFIG,
    model: null,
    podSecurityPolicyTemplateId: null,
    isNew: false,

    primaryResource: Ember.computed.alias('model.project'),
    secPolicy: Ember.computed.alias('model.project.defaultPodSecurityPolicyTemplateId'),
    policies: Ember.computed.alias('model.policies'),
    init: function init() {
      this._super.apply(this, arguments);
      var bindings = (Ember.get(this, 'model.project.projectRoleTemplateBindings') || []).slice();

      bindings = bindings.filter(function (x) {
        return Ember.get(x, 'name') !== 'creator';
      });
      Ember.set(this, 'memberArray', bindings);
      Ember.set(this, 'podSecurityPolicyTemplateId', Ember.get(this, 'model.project.podSecurityPolicyTemplateId'));
      if (Ember.isEmpty(Ember.get(this, 'primaryResource.id'))) {
        Ember.set(this, 'isNew', true);
      }
    },


    actions: {
      cancel: function cancel() {
        this.goBack();
      },
      expandFn: function expandFn() {},
      updateQuota: function updateQuota(quota) {
        var primaryResource = Ember.get(this, 'primaryResource');

        if (quota) {
          Ember.setProperties(primaryResource, quota);
        } else {
          Ember.setProperties(primaryResource, {
            resourceQuota: null,
            namespaceDefaultResourceQuota: null
          });
        }
      },
      updateContainerDefault: function updateContainerDefault(limit) {
        var primaryResource = Ember.get(this, 'primaryResource');

        Ember.set(primaryResource, 'containerDefaultResourceLimit', limit);
      }
    },

    pspDidChange: Ember.observer('podSecurityPolicyTemplateId', function () {
      Ember.set(this, 'model.project.podSecurityPolicyTemplateId', Ember.get(this, 'podSecurityPolicyTemplateId'));
    }),
    creator: Ember.computed('primaryResource.creatorId', function () {
      var cid = Ember.get(this, 'primaryResource.creatorId');
      var creator = null;

      if (Ember.get(this, 'editing')) {
        var users = Ember.get(this, 'model.users');

        creator = users.findBy('id', cid) || users.findBy('username', cid); // TODO 2.0 must do because first clusters and projects are given admin as the creator id which is not the admins userid
      } else {
        creator = Ember.get(this, 'model.me');
      }

      return creator;
    }),

    goBack: function goBack() {
      Ember.get(this, 'router').transitionTo('authenticated.cluster.projects.index');
    },
    validate: function validate() {
      this._super();

      var errors = Ember.get(this, 'errors') || [];

      var intl = Ember.get(this, 'intl');

      var resourceQuota = Ember.get(this, 'primaryResource.resourceQuota.limit') || {};
      var nsResourceQuota = Ember.get(this, 'primaryResource.namespaceDefaultResourceQuota.limit') || {};

      Object.keys(nsResourceQuota).forEach(function (key) {
        if (nsResourceQuota[key] && !resourceQuota[key]) {
          errors.push(intl.t('formResourceQuota.errors.projectLimitRequired', { resource: intl.t('formResourceQuota.resources.' + key) }));
        }
      });

      Object.keys(resourceQuota).forEach(function (key) {
        if (resourceQuota[key] && !nsResourceQuota[key]) {
          errors.push(intl.t('formResourceQuota.errors.nsDefaultLimitRequired', { resource: intl.t('formResourceQuota.resources.' + key) }));
        }
      });

      Ember.set(this, 'errors', errors);

      return Ember.get(this, 'errors.length') === 0;
    },
    didSave: function didSave() {
      var _this = this;

      var pr = Ember.get(this, 'primaryResource');
      var podSecurityPolicyTemplateId = Ember.get(this, 'podSecurityPolicyTemplateId') ? Ember.get(this, 'podSecurityPolicyTemplateId') : null;

      return pr.waitForCondition('BackingNamespaceCreated').then(function () {
        return _this.applyHooks().then(function () {
          pr.doAction('setpodsecuritypolicytemplate', { podSecurityPolicyTemplateId: podSecurityPolicyTemplateId }).then(function () {
            return pr;
          });
        });
      });
    },
    doneSaving: function doneSaving() {
      this.goBack();
    },
    doSave: function doSave(opt) {
      opt = opt || {};
      opt.qp = { '_replace': 'true' };

      return this._super(opt);
    }
  });
});