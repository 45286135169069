define('ui/mixins/modal-base', ['exports', 'shared/mixins/modal-base'], function (exports, _modalBase) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _modalBase.default;
    }
  });
});