define('ui/utils/parse-externalid', ['exports', 'shared/utils/parse-externalid'], function (exports, _parseExternalid) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'parseExternalId', {
    enumerable: true,
    get: function () {
      return _parseExternalid.parseExternalId;
    }
  });
  Object.defineProperty(exports, 'parseHelmExternalId', {
    enumerable: true,
    get: function () {
      return _parseExternalid.parseHelmExternalId;
    }
  });
});