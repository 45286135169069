define('ui/authenticated/prefs/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    modal: Ember.inject.service(),

    newPassword: null,

    actions: {
      done: function done() {
        window.history.back();
        // this.send('goToPrevious');
      },
      editPassword: function editPassword() {
        Ember.get(this, 'modal').toggleModal('modal-edit-password', { user: Ember.get(this, 'model.account') });
      }
    }
  });
});