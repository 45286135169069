define('ui/mixins/container-spark-stats', ['exports', 'shared/mixins/container-spark-stats'], function (exports, _containerSparkStats) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _containerSparkStats.default;
    }
  });
});