define('ui/authenticated/project/config-maps/detail/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(params) {
      var all = this.modelFor('authenticated.project.config-maps');

      var configMaps = all.configMaps.findBy('id', params.config_map_id);

      if (configMaps) {
        return configMaps;
      }

      return Ember.get(this, 'store').find('configMap', params.config_map_id);
    }
  });
});