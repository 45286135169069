define('ui/workload/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    beforeModel: function beforeModel() {
      if (window.ShellQuote) {
        return;
      } else {
        return emberAutoImportDynamic('shell-quote').then(function (module) {
          window.ShellQuote = module.default;

          return module.default;
        });
      }
    },
    model: function model(params) {
      return Ember.RSVP.hash({ workload: this.get('store').find('workload', params.workload_id) }).then(function (hash) {
        return Ember.Object.create(hash);
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      var lc = model.get('workload.containers.firstObject');

      controller.setProperties({ launchConfig: lc });
    }
  });
});