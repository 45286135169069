define('ui/utils/socket', ['exports', 'shared/utils/socket'], function (exports, _socket) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _socket.default;
    }
  });
});