define('ui/models/projectroletemplatebinding', ['exports', '@rancher/ember-api-store/models/resource', '@rancher/ember-api-store/utils/denormalize', 'ui/utils/constants', 'ui/mixins/principal-reference'], function (exports, _resource, _denormalize, _constants, _principalReference) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _resource.default.extend(_principalReference.default, {
    type: 'projectRoleTemplateBinding',
    canEdit: false,
    project: (0, _denormalize.reference)('projectId'),
    roleTemplate: (0, _denormalize.reference)('roleTemplateId'),
    user: (0, _denormalize.reference)('userId', 'user'),
    displayName: Ember.computed('name', 'id', function () {
      var name = Ember.get(this, 'name');

      if (name) {
        return name;
      }

      return '(' + Ember.get(this, 'id') + ')';
    }),
    isCustom: Ember.computed('roleTemplateId', function () {
      return !_constants.default.BASIC_ROLE_TEMPLATE_ROLES.includes(Ember.get(this, 'roleTemplateId'));
    }),

    principalId: Ember.computed('userPrincipalId', 'groupPrincipalId', function () {
      return Ember.get(this, 'groupPrincipalId') || Ember.get(this, 'userPrincipalId') || null;
    }),

    canRemove: Ember.computed('links.remove', 'name', function () {
      return !!Ember.get(this, 'links.remove') && Ember.get(this, 'name') !== 'creator';
    })
  });
});