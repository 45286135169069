define('ui/authenticated/project/secrets/new/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(params /* , transition*/) {
      if (Ember.get(params, 'id')) {
        return Ember.get(this, 'store').find(Ember.get(params, 'type'), Ember.get(params, 'id')).then(function (secret) {
          return secret.cloneForNew();
        });
      }

      return this.get('store').createRecord({ type: 'secret' });
    },
    resetController: function resetController(controller, isExiting /* , transition*/) {
      if (isExiting) {
        Ember.set(controller, 'id', null);
        Ember.set(controller, 'type', null);
      }
    }
  });
});