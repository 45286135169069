define('ui/utils/sort', ['exports', 'shared/utils/sort'], function (exports, _sort) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'insensitiveCompare', {
    enumerable: true,
    get: function () {
      return _sort.insensitiveCompare;
    }
  });
  Object.defineProperty(exports, 'sortInsensitiveBy', {
    enumerable: true,
    get: function () {
      return _sort.sortInsensitiveBy;
    }
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _sort.default;
    }
  });
});