define('ui/authenticated/project/project-catalogs/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    scope: Ember.inject.service(),
    modalService: Ember.inject.service('modal'),
    router: Ember.inject.service(),
    globalCatalogs: Ember.computed.alias('model.globalCatalogs'),
    filtered: Ember.computed.union('globalCatalogs', 'clusterCatalogs', 'projectCatalogs'),

    actions: {
      add: function add() {
        var record = Ember.get(this, 'globalStore').createRecord({
          type: 'projectcatalog',
          kind: 'helm',
          branch: 'master',
          projectId: Ember.get(this, 'scope.currentProject.id')
        });

        Ember.get(this, 'modalService').toggleModal('modal-edit-catalog', {
          model: record,
          scope: 'project'
        });
      },
      goBack: function goBack() {
        Ember.get(this, 'router').transitionTo('apps-tab');
      }
    },

    clusterCatalogs: Ember.computed('model.clusterCatalogs.@each.{clusterId,state,id}', function () {
      return Ember.get(this, 'model.clusterCatalogs').filterBy('clusterId', Ember.get(this, 'scope.currentCluster.id'));
    }),

    projectCatalogs: Ember.computed('model.projectCatalogs.@each.{clusterId,state,id}', function () {
      return Ember.get(this, 'model.projectCatalogs').filterBy('projectId', Ember.get(this, 'scope.currentProject.id'));
    })
  });
});