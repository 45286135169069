define('ui/authenticated/project/certificates/index/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    projectController: Ember.inject.controller('authenticated.project'),

    sortBy: 'name',
    headers: [{
      name: 'state',
      sort: ['sortState', 'name', 'id'],
      translationKey: 'generic.state',
      width: 125
    }, {
      name: 'name',
      sort: ['name', 'id'],
      translationKey: 'generic.name'
    }, {
      name: 'cn',
      searchField: ['cn'],
      sort: ['cn', 'id'],
      translationKey: 'certificatesPage.domainNames.labelText'
    }, {
      name: 'expires',
      sort: ['expiresDate', 'id'],
      translationKey: 'certificatesPage.expires',
      width: 120
    }],

    group: Ember.computed.alias('projectController.group'),
    groupTableBy: Ember.computed.alias('projectController.groupTableBy'),

    rows: function () {
      var proj = Ember.get(this, 'model.projectCerts').slice();
      var ns = Ember.get(this, 'model.namespacedCerts').slice();
      var out = proj.concat(ns);

      return out;
    }.property('model.projectCerts.[]', 'model.namespacedCerts.[]')
  });
});