define('ui/authenticated/cluster/projects/edit/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    globalStore: Ember.inject.service(),
    access: Ember.inject.service(),
    roleTemplateService: Ember.inject.service('roleTemplate'),

    model: function model(params) {
      var store = Ember.get(this, 'globalStore');

      return Ember.RSVP.hash({
        me: Ember.get(this, 'access.principal'),
        project: store.find('project', params.project_id),
        projectRoleTemplateBindings: store.find('projectRoleTemplateBinding'),
        projects: store.findAll('project'),
        psps: store.find('podSecurityPolicyTemplate'),
        roles: Ember.get(this, 'roleTemplateService').get('allFilteredRoleTemplates'),
        users: store.find('user', null, { forceReload: true })
      }).then(function (hash) {
        Ember.set(hash, 'project', Ember.get(hash, 'project').clone());

        return hash;
      });
    }
  });
});