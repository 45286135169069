define('ui/catalog-tab/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    access: Ember.inject.service(),
    catalog: Ember.inject.service(),
    scope: Ember.inject.service(),

    beforeModel: function beforeModel() {
      var _this = this;

      this._super.apply(this, arguments);

      return Ember.get(this, 'catalog').fetchUnScopedCatalogs().then(function (hash) {
        _this.set('catalogs', hash);
      });
    },
    model: function model(params) {
      var _this2 = this;

      var project = this.modelFor('authenticated.project').get('project');

      Ember.set(params, 'project', project);

      return Ember.get(this, 'catalog').fetchTemplates(params).then(function (res) {
        res.catalog.forEach(function (tpl) {
          var exists = project.get('apps').findBy('externalIdInfo.templateId', tpl.get('id'));

          tpl.set('exists', !!exists);
        });

        res.catalogs = Ember.get(_this2, 'catalogs');

        return res;
      });
    },
    resetController: function resetController(controller, isExiting /* , transition*/) {
      if (isExiting) {
        controller.setProperties({
          category: '',
          catalogId: '',
          projectCatalogId: '',
          clusterCatalogId: ''
        });
      }
    },
    deactivate: function deactivate() {
      // Clear the cache when leaving the route so that it will be reloaded when you come back.
      this.set('cache', null);
    },


    actions: {
      refresh: function refresh() {
        // Clear the cache so it has to ask the server again
        this.set('cache', null);
        this.refresh();
      }
    },

    queryParams: {
      category: { refreshModel: true },
      catalogId: { refreshModel: true },
      clusterCatalogId: { refreshModel: true },
      projectCatalogId: { refreshModel: true }
    }

  });
});