define('ui/mixins/verify-auth', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    access: Ember.inject.service(),
    cookies: Ember.inject.service(),
    modal: Ember.inject.service(),
    prefs: Ember.inject.service(),
    settings: Ember.inject.service(),
    globalStore: Ember.inject.service(),

    queryParams: {
      config: { refreshModel: false },
      code: { refreshModel: false },
      forward: { refreshModel: false },
      state: { refreshModel: false },
      authProvider: { refreshModel: false },
      error_description: { refreshModel: false },
      oauth_token: { refreshModel: false },
      oauth_verifier: { refreshModel: false },
      login: { refreshModel: false },
      errorCode: { refreshModel: false },
      errorMsg: { refreshModel: false }
    }

  });
});