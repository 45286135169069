define('ui/authenticated/prefs/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    session: Ember.inject.service(),
    scope: Ember.inject.service(),
    globalStore: Ember.inject.service(),

    // TODO 3.0?
    // beforeModel() {
    //   return loadScript('https://js.stripe.com/v2/').then(() => {
    //     Stripe.setPublishableKey(this.get('app.stripe.publishableKey'));
    //     return resolve();
    //   });
    // },

    model: function model() /* params, transition*/{
      return Ember.get(this, 'globalStore').find('user', null, {
        forceReload: true,
        filter: { me: true }
      }).then(function (user) {
        return Ember.Object.create({
          account: Ember.get(user, 'firstObject') // dont like this
          // stripeCards: null,
        });
      });

      // only need to populate the passwords for the account right now
      // return this.get('globalStore').find('password').then((/* pwds */) => {

      //   return this.get('globalStore').find('account', this.get('accountId')).then((resp) => {
      //     let stripeAccountId = null;
      //     if (resp.description && typeof resp.description === 'object') {
      //       stripeAccountId = JSON.parse(resp.description).stripeAccountId;
      //     }
      //     modelOut.account = resp;

      //     if (stripeAccountId) {
      //       return fetch(`/payment?type=stripe&accountId=${stripeAccountId}`, {
      //         method: 'GET',
      //         headers: {
      //           'Content-Type': 'application/json'
      //         },
      //       }).then((customer) => {
      //         modelOut.stripeCards = customer.body;
      //         return modelOut;
      //       }).catch(() => {
      //         return modelOut;
      //       });
      //     } else {
      //       return modelOut;
      //     }
      //   });
      // });
    }
  });
});