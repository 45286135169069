define('ui/components/container/form-volumes/component', ['exports', 'ui/utils/constants', 'ui/components/container/form-volumes/template'], function (exports, _constants, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.LOG_AGGREGATOR = exports.EXISTING_VCT = exports.EXISTING_PVC = exports.EXISTING_VOLUME = exports.NEW_VCT = exports.NEW_PVC = exports.NEW_VOLUME = undefined;
  var NEW_VOLUME = exports.NEW_VOLUME = 'newVolume';
  var NEW_PVC = exports.NEW_PVC = 'newPvc';
  var NEW_VCT = exports.NEW_VCT = 'newVolumeClaimTemplate';

  var EXISTING_VOLUME = exports.EXISTING_VOLUME = 'existingVolume';
  var EXISTING_PVC = exports.EXISTING_PVC = 'existingPvc';
  var EXISTING_VCT = exports.EXISTING_VCT = 'existingVct';

  var LOG_AGGREGATOR = exports.LOG_AGGREGATOR = 'cattle.io/log-aggregator';

  exports.default = Ember.Component.extend({
    intl: Ember.inject.service(),
    scope: Ember.inject.service(),
    modalService: Ember.inject.service('modal'),
    layout: _template.default,
    classNames: ['accordion-wrapper'],

    // Inputs
    workload: null,
    launchConfig: null,
    namespace: null,
    errors: null,
    editing: true,
    scaleMode: null,

    volumesArray: null,

    nextNum: 1,
    cluster: Ember.computed.alias('scope.currentCluster'),
    project: Ember.computed.alias('scope.currentProject'),

    init: function init() {
      this._super.apply(this, arguments);
      this.sendAction('registerHook', this.saveVolumes.bind(this), {
        name: 'saveVolumes',
        key: '_volumeHooks'
      });
      this.initVolumes();
    },


    // Create (ephermal) Volume -> volume entry on pod
    // Create PVC for existing (persistent) volume // cru-pvc
    // Create PVC for a new volume via storageclass // cru-pvc
    // Use an existing PVC (from the project volumes page)
    // Bind-mount (ephemeral volume -> hostPath)
    // Tmpfs (ephemeral volume -> emptyDir -> backing=memory)

    actions: {
      remove: function remove(obj) {
        Ember.get(this, 'volumesArray').removeObject(obj);
      },
      addVolume: function addVolume() {
        var store = Ember.get(this, 'store');

        Ember.get(this, 'volumesArray').pushObject({
          mode: NEW_VOLUME,
          volume: store.createRecord({
            type: 'volume',
            name: this.nextName()
          }),
          mounts: [Ember.get(this, 'store').createRecord({ type: 'volumeMount' })]
        });
      },
      addNewPvc: function addNewPvc() {
        var store = Ember.get(this, 'store');

        Ember.get(this, 'volumesArray').pushObject({
          mode: NEW_PVC,
          pvc: store.createRecord({ type: 'persistentVolumeClaim' }),
          name: null,
          volume: store.createRecord({
            type: 'volume',
            persistentVolumeClaim: store.createRecord({
              type: 'persistentVolumeClaimVolumeSource',
              persistentVolumeClaimId: null
            })
          }),
          mounts: [store.createRecord({ type: 'volumeMount' })]
        });
      },
      addPvc: function addPvc() {
        var store = Ember.get(this, 'store');

        Ember.get(this, 'volumesArray').pushObject({
          mode: EXISTING_PVC,
          volume: store.createRecord({
            type: 'volume',
            name: this.nextName(),
            persistentVolumeClaim: store.createRecord({
              type: 'persistentVolumeClaimVolumeSource',
              persistentVolumeClaimId: null
            })
          }),
          mounts: [store.createRecord({ type: 'volumeMount' })]
        });
      },
      addBindMount: function addBindMount() {
        var store = Ember.get(this, 'store');

        Ember.get(this, 'volumesArray').pushObject({
          mode: _constants.default.VOLUME_TYPES.BIND_MOUNT,
          volume: store.createRecord({
            type: 'volume',
            name: this.nextName(),
            hostPath: store.createRecord({
              type: 'hostPathVolumeSource',
              kind: '',
              path: ''
            })
          }),
          mounts: [store.createRecord({ type: 'volumeMount' })]
        });
      },
      addTmpfs: function addTmpfs() {
        var store = Ember.get(this, 'store');

        Ember.get(this, 'volumesArray').pushObject({
          mode: _constants.default.VOLUME_TYPES.TMPFS,
          volume: store.createRecord({
            type: 'volume',
            name: this.nextName(),
            emptyDir: store.createRecord({
              type: 'emptyDirVolumeSource',
              medium: 'Memory'
            })
          }),
          mounts: [store.createRecord({ type: 'volumeMount' })]
        });
      },
      addConfigMap: function addConfigMap() {
        var store = Ember.get(this, 'store');

        Ember.get(this, 'volumesArray').pushObject({
          mode: _constants.default.VOLUME_TYPES.CONFIG_MAP,
          volume: store.createRecord({
            type: 'volume',
            name: this.nextName(),
            configMap: store.createRecord({
              type: 'configMapVolumeSource',
              defaultMode: 256,
              name: null,
              optional: false
            })
          }),
          mounts: [store.createRecord({ type: 'volumeMount' })]
        });
      },
      addSecret: function addSecret() {
        var store = Ember.get(this, 'store');

        Ember.get(this, 'volumesArray').pushObject({
          mode: _constants.default.VOLUME_TYPES.SECRET,
          volume: store.createRecord({
            type: 'volume',
            name: this.nextName(),
            secret: store.createRecord({
              type: 'secretVolumeSource',
              defaultMode: 256,
              secretName: null,
              optional: false
            })
          }),
          mounts: [store.createRecord({ type: 'volumeMount' })]
        });
      },
      addCertificate: function addCertificate() {
        var store = Ember.get(this, 'store');

        Ember.get(this, 'volumesArray').pushObject({
          mode: _constants.default.VOLUME_TYPES.CERTIFICATE,
          volume: store.createRecord({
            type: 'volume',
            name: this.nextName(),
            secret: store.createRecord({
              type: 'secretVolumeSource',
              defaultMode: 256,
              secretName: null,
              optional: false
            })
          }),
          mounts: [store.createRecord({ type: 'volumeMount' })]
        });
      },
      addCustomLogPath: function addCustomLogPath() {
        var store = Ember.get(this, 'store');

        var name = this.nextName();

        Ember.get(this, 'volumesArray').pushObject({
          mode: _constants.default.VOLUME_TYPES.CUSTOM_LOG_PATH,
          volume: store.createRecord({
            type: 'volume',
            name: name,
            flexVolume: store.createRecord({
              type: 'flexVolume',
              driver: LOG_AGGREGATOR,
              fsType: 'ext4',
              options: {
                format: 'json',
                clusterName: Ember.get(this, 'cluster.name'),
                projectName: Ember.get(this, 'project.name'),
                clusterId: Ember.get(this, 'cluster.id'),
                projectId: Ember.get(this, 'project.id').split(':')[1],
                volumeName: name
              }
            })
          }),
          mounts: [store.createRecord({ type: 'volumeMount' })]
        });
      },
      addVolumeClaimTemplate: function addVolumeClaimTemplate() {
        var store = this.store,
            volumesArray = this.volumesArray;


        var vct = store.createRecord({
          type: 'persistentVolumeClaim',
          name: this.nextName()
        });

        volumesArray.pushObject({
          mode: NEW_VCT,
          vct: vct,
          mounts: [store.createRecord({ type: 'volumeMount' })]
        });
      }
    },

    isStatefulSet: Ember.computed('launchConfig', 'scaleMode', function () {
      var scaleMode = this.scaleMode;


      return scaleMode === 'statefulSet';
    }),

    initVolumes: function initVolumes() {
      var _this = this;

      if (!Ember.get(this, 'expandFn')) {
        Ember.set(this, 'expandFn', function (item) {
          item.toggleProperty('expanded');
        });
      }

      var out = [];
      var workload = this.workload;

      var volumes = workload.volumes || [];
      var volumeClaimTemplates = workload.statefulSetConfig && workload.statefulSetConfig.volumeClaimTemplates ? workload.statefulSetConfig.volumeClaimTemplates : [];

      if (volumeClaimTemplates.length > 0) {
        volumeClaimTemplates.forEach(function (vct) {
          Ember.set(vct, 'isVolumeClaimTemplate', true);
        });
      }

      var allVolumes = [].concat(volumes.slice(), volumeClaimTemplates.slice());

      allVolumes.forEach(function (volume) {
        var mode = void 0;
        var hidden = false;

        if (volume.persistentVolumeClaim) {
          mode = EXISTING_PVC;
        } else if (volume.hostPath) {
          mode = _constants.default.VOLUME_TYPES.BIND_MOUNT;
        } else if (volume.flexVolume && volume.flexVolume.driver === LOG_AGGREGATOR) {
          mode = _constants.default.VOLUME_TYPES.CUSTOM_LOG_PATH;
          hidden = Ember.get(volume, 'flexVolume.options.containerName') !== Ember.get(_this, 'launchConfig.name');
        } else if (volume.secret) {
          mode = _this.getSecretType(Ember.get(volume, 'secret.secretName'));
        } else if (volume.configMap) {
          mode = _constants.default.VOLUME_TYPES.CONFIG_MAP;
        } else if (volume.isVolumeClaimTemplate) {
          mode = EXISTING_VCT;
        } else {
          mode = EXISTING_VOLUME;
        }

        out.push({
          mode: mode,
          hidden: hidden,
          volume: volume,
          mounts: []
        });
      });

      (Ember.get(this, 'launchConfig.volumeMounts') || []).forEach(function (mount) {
        var entry = out.findBy('volume.name', mount.name);

        if (entry) {
          entry.mounts.push(mount);
        }
      });

      // filter out custom log path volume when logging is disabled
      if (!Ember.get(this, 'loggingEnabled')) {
        Ember.set(this, 'volumesArray', out.filter(function (row) {
          return row.mode !== _constants.default.VOLUME_TYPES.CUSTOM_LOG_PATH;
        }));
      } else {
        Ember.set(this, 'volumesArray', out);
      }
    },
    getSecretType: function getSecretType(secretName) {
      var store = Ember.get(this, 'store');
      var found = store.all('secret').findBy('name', secretName);

      if (found) {
        if (Ember.get(found, 'type') === _constants.default.VOLUME_TYPES.CERTIFICATE) {
          return _constants.default.VOLUME_TYPES.CERTIFICATE;
        }
      } else {
        found = store.all('namespacedSecret').findBy('type', secretName);
        if (found && Ember.get(found, 'type') === 'namespacedCertificate') {
          return _constants.default.VOLUME_TYPES.CERTIFICATE;
        }
      }

      return _constants.default.VOLUME_TYPES.SECRET;
    },
    nextName: function nextName() {
      var volumes = Ember.get(this, 'workload.volumes') || [];
      var num = Ember.get(this, 'nextNum');
      var name = void 0;

      var ok = false;

      while (!ok) {
        name = 'vol' + num;
        ok = !volumes.findBy('name', name);
        num++;
      }

      Ember.set(this, 'nextNum', num);

      return name;
    },
    saveVolumes: function saveVolumes() {
      var _this2 = this;

      var workload = this.workload,
          launchConfig = this.launchConfig;

      var ary = Ember.get(this, 'volumesArray') || [];
      var promises = [];
      var statefulSetConfig = Ember.get(workload, 'statefulSetConfig') || {};
      var volumeClaimTemplates = Ember.get(statefulSetConfig, 'volumeClaimTemplates') || [];

      var pvc = void 0,
          vct = void 0;

      ary.filterBy('pvc').forEach(function (row) {
        pvc = Ember.get(row, 'pvc');
        Ember.set(pvc, 'namespaceId', Ember.get(_this2, 'namespace.id'));
        promises.push(Ember.get(row, 'pvc').save());
      });

      if (Ember.get(this, 'isStatefulSet')) {
        ary.filterBy('vct').forEach(function (row) {
          vct = Ember.get(row, 'vct');
          volumeClaimTemplates.push(vct);
        });

        Ember.set(this, 'workload.statefulSetConfig.volumeClaimTemplates', volumeClaimTemplates);
      }

      ary.filterBy('mode', _constants.default.VOLUME_TYPES.CUSTOM_LOG_PATH).filterBy('volume.flexVolume.driver', LOG_AGGREGATOR).forEach(function (row) {
        var options = Ember.get(row, 'volume.flexVolume.options');
        var lc = Ember.get(_this2, 'launchConfig');
        var workload = Ember.get(_this2, 'workload');

        if (!Ember.get(row, 'hidden')) {
          Ember.setProperties(options, {
            containerName: Ember.get(lc, 'name'),
            namespace: Ember.get(workload, 'namespace.id'),
            workloadName: Ember.get(workload, 'name')
          });
        }
      });

      return Ember.RSVP.all(promises).then(function () {
        var volumes = [];
        var mounts = [];

        ary.forEach(function (row) {
          if (row.volume && !row.volume.isVolumeClaimTemplate) {
            volumes.pushObject(row.volume);
          }

          row.mounts.forEach(function (mount) {
            if (Ember.get(row, 'vct')) {
              Ember.set(mount, 'name', Ember.get(row, 'vct.name'));
            } else {
              Ember.set(mount, 'name', Ember.get(row, 'volume.name'));
            }
            mounts.pushObject(mount);
          });

          if (row.pvc) {
            var id = Ember.get(row, 'pvc.id');

            Ember.set(row, 'volume.persistentVolumeClaim.persistentVolumeClaimId', id);
          }
        });

        Ember.set(workload, 'volumes', volumes);

        Ember.set(launchConfig, 'volumeMounts', mounts);
      });
    }
  });
});