define('ui/utils/download-files', ['exports', 'shared/utils/download-files'], function (exports, _downloadFiles) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _downloadFiles.default;
    }
  });
});