define('ui/models/clusteralertrule', ['exports', '@rancher/ember-api-store/models/resource', 'ui/mixins/model-alert'], function (exports, _resource, _modelAlert) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var clusterAlertRule = _resource.default.extend(_modelAlert.default, {
    intl: Ember.inject.service(),

    type: 'clusterAlertRule',

    _targetType: 'systemService',

    canClone: true,
    canEdit: true,

    targetType: Ember.computed('systemServiceRule.condition', 'nodeRule.{nodeId,selector}', 'eventRule.resourceKind', 'metricRule.expression', function () {
      if (Ember.get(this, 'systemServiceRule.condition')) {
        return 'systemService';
      }
      if (Ember.get(this, 'nodeRule.nodeId')) {
        return 'node';
      }
      if (Ember.get(this, 'nodeRule.selector')) {
        return 'nodeSelector';
      }
      if (Ember.get(this, 'eventRule.resourceKind')) {
        return 'event';
      }
      if (Ember.get(this, 'metricRule.expression')) {
        return 'metric';
      }
    }),

    displayTargetType: Ember.computed('targetType', function () {
      return Ember.get(this, 'intl').t('alertPage.targetTypes.' + Ember.get(this, 'targetType'));
    }),

    displayCondition: Ember.computed('targetType', 'nodeRule.{condition,cpuThreshold,memThreshold}', 'metricRule.{expression,comparison,thresholdValue}', function () {
      var t = Ember.get(this, 'targetType');
      var intl = Ember.get(this, 'intl');
      var out = intl.t('alertPage.na');
      var c = Ember.get(this, 'nodeRule.condition');
      var cpuThreshold = Ember.get(this, 'nodeRule.cpuThreshold');
      var memThreshold = Ember.get(this, 'nodeRule.memThreshold');
      var metricRule = Ember.get(this, 'metricRule');

      switch (t) {
        case 'systemService':
          out = intl.t('alertPage.index.table.displayCondition.unhealthy');
          break;
        case 'event':
          out = intl.t('alertPage.index.table.displayCondition.happens');
          break;
        case 'node':
        case 'nodeSelector':
          switch (c) {
            case 'notready':
              out = intl.t('alertPage.index.table.displayCondition.notReady');
              break;
            case 'cpu':
              out = intl.t('alertPage.index.table.displayCondition.cpuUsage', { percent: cpuThreshold });
              break;
            case 'mem':
              out = intl.t('alertPage.index.table.displayCondition.memUsage', { percent: memThreshold });
              break;
          }
          break;
        case 'metric':
          out = intl.t('alertPage.comparison.' + metricRule.comparison) + ' ' + metricRule.thresholdValue;
          break;
      }

      return out;
    }),

    threshold: Ember.computed('targetType', 'nodeRule.{memThreshold,cpuThreshold,condition}', function () {
      var t = Ember.get(this, 'targetType');
      var c = Ember.get(this, 'nodeRule.condition');

      if (t === 'node' || t === 'nodeSelector') {
        if (c === 'cpu') {
          return Ember.get(this, 'nodeRule.cpuThreshold');
        }
        if (c === 'mem') {
          return Ember.get(this, 'nodeRule.memThreshold');
        }
      }
    }),

    actions: {
      clone: function clone() {
        Ember.get(this, 'router').transitionTo('authenticated.cluster.alert.new-rule', Ember.get(this, 'groupId'), { queryParams: { id: Ember.get(this, 'id') } });
      },
      edit: function edit() {
        Ember.get(this, 'router').transitionTo('authenticated.cluster.alert.edit-rule', Ember.get(this, 'groupId'), Ember.get(this, 'id'));
      }
    }

  });

  exports.default = clusterAlertRule;
});