define('ui/components/form-service-ports/component', ['exports', 'ui/components/form-service-ports/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var PROTOCOL_OPTIONS = [{
    label: 'TCP',
    value: 'TCP'
  }, {
    label: 'UDP',
    value: 'UDP'
  }];

  exports.default = Ember.Component.extend({
    layout: _template.default,

    protocolOptions: PROTOCOL_OPTIONS,
    editing: true,
    ports: Ember.computed.alias('model.ports'),

    init: function init() {
      this._super.apply(this, arguments);

      if (!Ember.get(this, 'ports')) {
        Ember.set(this, 'model.ports', []);
      }
    },


    actions: {
      addPort: function addPort() {
        var _this = this;

        Ember.get(this, 'ports').pushObject(Ember.get(this, 'store').createRecord({
          type: 'servicePort',
          protocol: 'TCP'
        }));

        Ember.run.next(function () {
          if (_this.isDestroyed || _this.isDestroying) {
            return;
          }

          _this.$('INPUT.public').last()[0].focus();
        });
      },
      removePort: function removePort(obj) {
        Ember.get(this, 'ports').removeObject(obj);
      }
    }
  });
});