define('ui/authenticated/cluster/security/members/new/controller', ['exports', 'ui/mixins/new-or-edit'], function (exports, _newOrEdit) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  // import { all as PromiseAll } from 'rsvp';

  var ROLE_KINDS = [{ id: 'User' }, { id: 'Group' }];

  exports.default = Ember.Controller.extend(_newOrEdit.default, {
    primaryResource: null,
    kinds: ROLE_KINDS,
    actions: {
      doneSaving: function doneSaving() {
        this.transitionToRoute('authenticated.cluster.security.members.index', Ember.get(this, 'model.cluster.id'));
      }
    }
  });
});