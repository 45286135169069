define('ui/authenticated/project/config-maps/route', ['exports', 'ui/utils/constants'], function (exports, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      var store = Ember.get(this, 'store');

      return Ember.RSVP.hash({ configMaps: store.findAll('configMap') });
    },


    setDefaultRoute: Ember.on('activate', function () {
      Ember.set(this, 'session.' + _constants.default.SESSION.PROJECT_ROUTE, 'authenticated.project.config-maps');
    })
  });
});