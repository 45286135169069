define('ui/components/namespace-quota-row/component', ['exports', 'ui/components/namespace-quota-row/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,

    tagName: 'TR',
    classNames: 'main-row'
  });
});