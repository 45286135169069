define('ui/components/accordion-pod/component', ['exports', 'shared/mixins/manage-labels', 'ui/components/accordion-pod/template'], function (exports, _manageLabels, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_manageLabels.default, {
    layout: _template.default,
    model: null,
    expandOnInit: true,
    sortBy: 'displayState',
    showKind: true,
    descending: true,
    initExpand: true,
    headers: [{
      name: 'displayState',
      sort: ['displayState'],
      translationKey: 'generic.state',
      width: 120
    }, {
      name: 'name',
      sort: ['name'],
      translationKey: 'generic.name',
      width: 400
    }, {
      name: 'displayImage',
      sort: ['displayImage'],
      translationKey: 'generic.image'
    }, {
      name: 'node',
      sort: ['displayName'],
      translationKey: 'generic.node',
      width: 180
    }]
  });
});