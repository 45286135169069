define('ui/models/projectlogging', ['exports', '@rancher/ember-api-store/models/resource', 'ui/mixins/logging-model'], function (exports, _resource, _loggingModel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ProjectLogging = _resource.default.extend(_loggingModel.default, { type: 'projectlogging' });

  exports.default = ProjectLogging;
});