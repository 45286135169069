define('ui/authenticated/cluster/projects/new-ns/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    globalStore: Ember.inject.service(),
    clusterStore: Ember.inject.service(),
    scope: Ember.inject.service(),

    model: function model(params) {
      var clusterStore = Ember.get(this, 'clusterStore');

      var namespace = clusterStore.createRecord({
        type: 'namespace',
        name: '',
        clusterId: Ember.get(this, 'scope.currentCluster.id')
      });

      if (params.addTo) {
        Ember.set(namespace, 'projectId', Ember.get(params, 'addTo'));
      }

      return Ember.RSVP.hash({
        namespace: namespace,
        namespaces: Ember.get(this, 'clusterStore').findAll('namespace'),
        allProjects: Ember.get(this, 'globalStore').findAll('project')
      });
    },
    resetController: function resetController(controller, isExiting /* , transition*/) {
      if (isExiting) {
        controller.set('errors', null);
      }
    },

    queryParams: {
      addTo: { refreshModel: true },
      from: { refreshModel: false }
    }

  });
});