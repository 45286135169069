define('ui/components/settings/billing-info/component', ['exports', 'ui/components/settings/billing-info/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    modalService: Ember.inject.service('modal'),
    layout: _template.default,
    classNames: ['billing-info', 'box'],
    cards: null,
    account: null,
    actions: {
      addNew: function addNew() {
        this.get('modalService').toggleModal('modal-add-payment', this.get('account'));
      },
      remove: function remove(card) {
        this.get('modalService').toggleModal('modal-confirm-remove-payment', {
          card: card,
          account: this.get('account')
        });
      }
    }
  });
});