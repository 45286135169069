define('ui/workload/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    scope: Ember.inject.service(),

    launchConfig: null,

    service: Ember.computed.alias('model.workload'),
    monitoringEnabled: Ember.computed.alias('scope.currentCluster.isMonitoringReady'),

    displayEnvironmentVars: Ember.computed('service.launchConfig.environment', function () {
      var envs = [];
      var environment = Ember.get(this, 'service.launchConfig.environment') || {};

      Object.keys(environment).forEach(function (key) {
        envs.pushObject({
          key: key,
          value: environment[key]
        });
      });

      return envs;
    })
  });
});