define('ui/models/secondarylaunchconfig', ['exports', 'ui/models/launchconfig'], function (exports, _launchconfig) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var secondaryLaunchConfigs = _launchconfig.default.extend({
    displayEnvironmentVars: Ember.computed('launchConfig.environment', function () {
      var envs = [];
      var environment = this.get('launchConfig.environment') || {};

      Object.keys(environment).forEach(function (key) {
        envs.pushObject({
          key: key,
          value: environment[key]
        });
      });

      return envs;
    })
  });

  exports.default = secondaryLaunchConfigs;
});