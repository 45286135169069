define('ui/components/new-edit-ingress/component', ['exports', 'shared/mixins/new-or-edit', 'ui/components/new-edit-ingress/template'], function (exports, _newOrEdit, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_newOrEdit.default, {
    intl: Ember.inject.service(),
    scope: Ember.inject.service(),

    layout: _template.default,
    ingress: null,
    editing: null,
    existing: null,
    namespacedCertificates: null,
    certificates: null,

    isGKE: Ember.computed.alias('scope.currentCluster.isGKE'),

    primaryResource: Ember.computed.alias('ingress'),

    init: function init() {
      this._super.apply(this, arguments);

      if (Ember.get(this, 'existing')) {
        Ember.set(this, 'namespace', Ember.get(this, 'existing.namespace'));
      }
    },

    actions: {
      done: function done() {
        this.sendAction('done');
      },
      cancel: function cancel() {
        this.sendAction('cancel');
      },
      setLabels: function setLabels(labels) {
        var out = {};

        labels.forEach(function (row) {
          out[row.key] = row.value;
        });

        Ember.set(this, 'ingress.labels', out);
      }
    },

    headerLabel: Ember.computed('intl.locale', 'existing', function () {
      var k = void 0;

      if (Ember.get(this, 'existing')) {
        k = 'newIngress.header.edit';
      } else {
        k = 'newIngress.header.add';
      }

      return Ember.get(this, 'intl').t(k);
    }),

    willSave: function willSave() {
      var pr = Ember.get(this, 'primaryResource');

      // Namespace is required, but doesn't exist yet... so lie to the validator
      var nsId = Ember.get(pr, 'namespaceId');

      Ember.set(pr, 'namespaceId', '__TEMP__');
      var ok = this.validate();

      Ember.set(pr, 'namespaceId', nsId);

      return ok;
    },
    doSave: function doSave() {
      var _arguments = arguments;

      var pr = Ember.get(this, 'primaryResource');

      var namespacePromise = Ember.RSVP.resolve();

      if (!Ember.get(this, 'existing')) {
        // Set the namespace ID
        if (Ember.get(this, 'namespace.id')) {
          Ember.set(pr, 'namespaceId', Ember.get(this, 'namespace.id'));
        } else if (Ember.get(this, 'namespace')) {
          namespacePromise = Ember.get(this, 'namespace').save().then(function (newNamespace) {
            Ember.set(pr, 'namespaceId', Ember.get(newNamespace, 'id'));

            return newNamespace.waitForState('active');
          });
        }
      }

      var self = this;
      var sup = self._super;

      return namespacePromise.then(function () {
        return sup.apply(self, _arguments);
      });
    },
    validate: function validate() {
      var intl = Ember.get(this, 'intl');

      var pr = Ember.get(this, 'primaryResource');
      var errors = pr.validationErrors() || [];

      errors.pushObjects(Ember.get(this, 'namespaceErrors') || []);
      errors.pushObjects(Ember.get(this, 'certErrors') || []);

      if (!Ember.get(this, 'ingress.rules.length') && !Ember.get(this, 'ingress.defaultBackend')) {
        errors.push(intl.t('newIngress.error.noRules'));
      }
      if (Ember.get(this, 'ingress.rules.length')) {
        var invalid = Ember.get(this, 'ingress.rules').some(function (rule) {
          var paths = [];

          Object.keys(rule.paths).forEach(function (key) {
            paths.push(rule.paths[key]);
          });

          return paths.some(function (path) {
            return !path.targetPort;
          });
        });

        if (invalid) {
          errors.push(intl.t('validation.required', { key: intl.t('generic.port') }));
        }
      }

      if (errors.length) {
        Ember.set(this, 'errors', errors.uniq());

        return false;
      }

      return true;
    },
    doneSaving: function doneSaving() {
      this._super.apply(this, arguments);
      this.send('done');
    }
  });
});