define('ui/volumes/new/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    actions: {
      done: function done() {
        return this.transitionToRoute('volumes.index');
      },
      cancel: function cancel() {
        this.send('goToPrevious', 'volumes.index');
      }
    }
  });
});