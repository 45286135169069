define('ui/models/pipelineexecution', ['exports', '@rancher/ember-api-store/models/resource', 'shared/utils/pipeline-constants', '@rancher/ember-api-store/utils/denormalize'], function (exports, _resource, _pipelineConstants, _denormalize) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var PipelineExecution = _resource.default.extend({
    router: Ember.inject.service(),
    type: 'pipelineexecution',
    pipeline: (0, _denormalize.reference)('pipelineId', 'pipeline'),

    relevantState: Ember.computed.alias('executionState'),
    availableActions: Ember.computed('actionLinks.{rerun,stop}', function () {
      var a = Ember.get(this, 'actionLinks') || {};

      return [{
        label: 'action.rerun',
        icon: 'icon icon-refresh',
        action: 'rerun',
        enabled: !!a.rerun,
        bulkable: true
      }, {
        label: 'action.stop',
        icon: 'icon icon-stop',
        action: 'stop',
        enabled: !!a.stop,
        bulkable: true
      }, { divider: true }];
    }),

    amount: Ember.computed('pipelineConfig.stages.[]', function () {
      var activity_stages = Ember.get(this, 'pipelineConfig.stages');
      var countStage = 0;
      var countStep = 0;

      for (var i = 0; i < activity_stages.length; i++) {
        var stage = activity_stages[i];

        countStage++;
        for (var j = 0; j < stage.steps.length; j++) {
          stage.steps[j];
          countStep++;
        }
      }

      return {
        countStage: countStage,
        countStep: countStep
      };
    }),

    shortCommit: Ember.computed('commit', function () {
      var commit = Ember.get(this, 'commit');

      if (commit) {
        return commit.substr(0, 8);
      }

      return '';
    }),

    startedTimeStamp: Ember.computed('started', function () {
      var time = Ember.get(this, 'started');

      return new Date(time);
    }),

    showTransitioning: Ember.computed('showTransitioningMessage', 'executionState', function () {
      return Ember.get(this, 'showTransitioningMessage') && Ember.get(this, 'executionState') !== _pipelineConstants.default.STATES.ABORTED && Ember.get(this, 'executionState') !== _pipelineConstants.default.STATES.FAILED;
    }),

    displayRepositoryUrl: Ember.computed('repositoryUrl', function () {
      var url = Ember.get(this, 'repositoryUrl');

      if (url.endsWith('.git')) {
        url = url.substr(0, url.length - 4);
      }

      return url;
    }),

    commitUrl: Ember.computed('commit', function () {
      var url = Ember.get(this, 'displayRepositoryUrl');
      var sourceCodeType = Ember.get(this, 'pipeline.sourceCodeCredential.sourceCodeType');
      var name = Ember.get(this, 'pipeline.displayName');

      switch (sourceCodeType) {
        case 'bitbucketserver':
          if (name.startsWith('~')) {
            return this.bitbucketRootUrl('users') + '/commits/' + Ember.get(this, 'commit');
          } else {
            return this.bitbucketRootUrl('projects') + '/commits/' + Ember.get(this, 'commit');
          }
        case 'bitbucketcloud':
          return url + '/commits/' + Ember.get(this, 'commit');
        default:
          return url + '/commit/' + Ember.get(this, 'commit');
      }
    }),

    branchUrl: Ember.computed('branch', function () {
      var url = Ember.get(this, 'displayRepositoryUrl');
      var sourceCodeType = Ember.get(this, 'pipeline.sourceCodeCredential.sourceCodeType');
      var name = Ember.get(this, 'pipeline.displayName');

      switch (sourceCodeType) {
        case 'bitbucketserver':
          if (name.startsWith('~')) {
            return this.bitbucketRootUrl('users') + '/browse?at=refs%2Fheads%2F' + Ember.get(this, 'branch');
          } else {
            return this.bitbucketRootUrl('projects') + '/browse?at=refs%2Fheads%2F' + Ember.get(this, 'branch');
          }
        case 'bitbucketcloud':
          return url + '/src/' + Ember.get(this, 'branch');
        default:
          return url + '/tree/' + Ember.get(this, 'branch');
      }
    }),

    duration: Ember.computed('started', 'ended', function () {
      var started = Ember.get(this, 'started');
      var ended = Ember.get(this, 'ended');

      if (ended) {
        var duration = new Date(ended).getTime() - new Date(started).getTime();

        return duration < 0 ? null : duration;
      }
    }),

    bitbucketRootUrl: function bitbucketRootUrl(repoType) {
      var url = Ember.get(this, 'displayRepositoryUrl');

      url = url.substr(0, Ember.get(url, 'length') - Ember.get(this, 'pipeline.displayName.length') - 5);

      return url + '/' + repoType + '/' + Ember.get(this, 'pipeline.projectName') + '/repos/' + Ember.get(this, 'pipeline.repoName');
    },


    actions: {
      rerun: function rerun() {
        var _this = this;

        return this.doAction('rerun').then(function () {
          var pipelineId = Ember.get(_this, 'pipeline.id');
          var nextRun = Ember.get(_this, 'pipeline.nextRun');

          Ember.get(_this, 'router').transitionTo('authenticated.project.pipeline.pipelines.run', pipelineId, nextRun);
        });
      },
      stop: function stop() {
        return this.doAction('stop');
      }
    }

  });

  PipelineExecution.reopenClass({
    mangleIn: function mangleIn(data) {
      if (data && data.pipeline) {
        data.pipeline._type = data.pipeline.type;
        delete data.pipeline.type;
      }

      return data;
    }
  });

  exports.default = PipelineExecution;
});