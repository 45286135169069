define('ui/models/credential', ['exports', '@rancher/ember-api-store/models/resource'], function (exports, _resource) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _resource.default.extend({
    router: Ember.inject.service(),

    canClone: true,

    actions: {
      clone: function clone() {
        Ember.get(this, 'router').transitionTo('authenticated.project.registries.new', null, { queryParams: { id: Ember.get(this, 'id') } });
      }
    }
  });
});