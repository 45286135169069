define('ui/models/replicaset', ['exports', 'ui/models/workload'], function (exports, _workload) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ReplicaSet = _workload.default.extend({});

  exports.default = ReplicaSet;
});