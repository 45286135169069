define('ui/components/container/form-job-config/component', ['exports', 'ui/components/container/form-job-config/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    workload: null,
    scaleMode: null,
    editing: null,

    classNames: ['accordion-wrapper'],

    didReceiveAttrs: function didReceiveAttrs() {
      if (!this.get('expandFn')) {
        this.set('expandFn', function (item) {
          item.toggleProperty('expanded');
        });
      }
    },

    jobConfig: Ember.computed('scaleMode', function () {
      var scaleMode = Ember.get(this, 'scaleMode');
      var config = void 0;

      if (scaleMode === 'job') {
        config = Ember.get(this, 'workload.jobConfig');
      } else {
        config = Ember.get(this, 'workload.cronJobConfig.jobConfig');
      }

      return config;
    })

  });
});