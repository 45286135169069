define('ui/utils/additional-routes', ['exports', 'shared/utils/additional-routes'], function (exports, _additionalRoutes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'addRoutes', {
    enumerable: true,
    get: function () {
      return _additionalRoutes.addRoutes;
    }
  });
  Object.defineProperty(exports, 'applyRoutes', {
    enumerable: true,
    get: function () {
      return _additionalRoutes.applyRoutes;
    }
  });
  Object.defineProperty(exports, 'clearRoutes', {
    enumerable: true,
    get: function () {
      return _additionalRoutes.clearRoutes;
    }
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _additionalRoutes.default;
    }
  });
});