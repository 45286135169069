define('ui/components/modal-edit-secret/component', ['exports', 'shared/mixins/modal-base', 'ui/components/modal-edit-secret/template'], function (exports, _modalBase, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_modalBase.default, {
    layout: _template.default,
    classNames: ['span-8', 'offset-2'],

    editing: true,
    model: null,

    originalModel: Ember.computed.alias('modalService.modalOpts'),
    init: function init() {
      this._super.apply(this, arguments);
      this.set('model', this.get('originalModel').clone());
    },
    doneSaving: function doneSaving() {
      this.send('cancel');
    }
  });
});