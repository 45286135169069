define('ui/authenticated/cluster/notifier/index/route', ['exports', 'ui/utils/constants'], function (exports, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    globalStore: Ember.inject.service(),

    model: function model(params, transition) {
      var cs = Ember.get(this, 'globalStore');
      var clusterId = transition.params['authenticated.cluster'].cluster_id;

      return Ember.RSVP.hash({ notifiers: cs.find('notifier', null, { filter: { clusterId: clusterId } }).then(function () {
          return cs.all('notifier');
        }) });
    },


    setDefaultRoute: Ember.on('activate', function () {
      Ember.set(this, 'session.' + _constants.default.SESSION.CLUSTER_ROUTE, 'authenticated.cluster.notifier');
    })
  });
});