define('ui/helpers/array-includes', ['exports', 'shared/helpers/array-includes'], function (exports, _arrayIncludes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _arrayIncludes.default;
    }
  });
});