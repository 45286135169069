define('ui/authenticated/cluster/projects/index/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    scope: Ember.inject.service(),
    queryParams: { group: 'group' },
    group: 'project',

    actions: {
      changeView: function changeView() {}
    },

    rows: Ember.computed('model.namespaces.@each.displayName', 'model.projects.@each.clusterId', 'scope.currentCluster.id', function () {
      return Ember.get(this, 'model.namespaces').filterBy('displayName');
    }),

    projects: Ember.computed('model.namespaces.@each.displayName', 'model.projects.@each.clusterId', 'scope.currentCluster.id', function () {
      return Ember.get(this, 'model.projects').filterBy('clusterId', Ember.get(this, 'scope.currentCluster.id'));
    }),

    projectsWithoutNamespaces: Ember.computed('projects.@each.{id,state,clusterId}', 'rows.@each.projectId', function () {
      var _this = this;

      return Ember.get(this, 'projects').filter(function (p) {
        var namespaces = Ember.get(_this, 'rows').filterBy('projectId', Ember.get(p, 'id')) || [];

        return Ember.get(namespaces, 'length') <= 0;
      }).sortBy('displayName');
    })

  });
});