define('ui/helpers/link-ssl-domain', ['exports', 'shared/helpers/link-ssl-domain'], function (exports, _linkSslDomain) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _linkSslDomain.default;
    }
  });
});