define('ui/models/dockercredential', ['exports', '@rancher/ember-api-store/models/resource'], function (exports, _resource) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.PRESETS_BY_NAME = exports.PRESETS = undefined;
  var PRESETS = exports.PRESETS = {
    'index.docker.io': 'dockerhub',
    'quay.io': 'quay'
  };

  var PRESETS_BY_NAME = exports.PRESETS_BY_NAME = {};
  Object.keys(PRESETS).forEach(function (key) {
    PRESETS_BY_NAME[PRESETS[key]] = key;
  });

  var DockerCredential = _resource.default.extend({
    intl: Ember.inject.service(),
    router: Ember.inject.service(),

    state: 'active',
    canClone: true,

    firstRegistry: Ember.computed.alias('asArray.firstObject'),
    registryCount: Ember.computed.alias('asArray.length'),

    asArray: Ember.computed('registries', function () {
      var all = Ember.get(this, 'registries') || {};

      var reg = void 0,
          address = void 0,
          preset = void 0;

      return Object.keys(all).map(function (key) {
        address = key.toLowerCase();
        reg = all[key];
        preset = PRESETS[address] || 'custom';

        return new Ember.Object({
          address: address,
          auth: reg.auth,
          username: reg.username,
          password: reg.password,
          preset: preset
        });
      });
    }),

    searchAddresses: Ember.computed('asArray.[].address', function () {
      return Ember.get(this, 'asArray').map(function (x) {
        return Ember.get(x, 'address');
      }).sort().uniq();
    }),

    searchUsernames: Ember.computed('asArray.[].username', function () {
      return Ember.get(this, 'asArray').map(function (x) {
        return Ember.get(x, 'username');
      }).sort().uniq();
    }),

    displayAddress: Ember.computed('intl.locale', 'registryCount', 'firstRegistry.address', function () {
      var address = Ember.get(this, 'firstRegistry.address');

      if (Ember.get(this, 'registryCount') > 1) {
        return 'cruRegistry.multiple';
      } else if (PRESETS[address]) {
        return 'cruRegistry.address.' + PRESETS[address];
      } else {
        return address;
      }
    }),

    displayUsername: Ember.computed('registryCount', 'firstRegistry.username', function () {
      var intl = Ember.get(this, 'intl');
      var username = Ember.get(this, 'firstRegistry.username');

      if (Ember.get(this, 'registryCount') > 1) {
        return intl.t('cruRegistry.multiple');
      } else {
        return username;
      }
    }),
    actions: {
      clone: function clone() {
        Ember.get(this, 'router').transitionTo('authenticated.project.registries.new', {
          queryParams: {
            id: Ember.get(this, 'id'),
            type: Ember.get(this, 'type')
          }
        });
      }
    }

  });

  exports.default = DockerCredential;
});