define('ui/initializers/polyfill-intl', ['exports', 'ui/utils/load-script'], function (exports, _loadScript) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(application) {
    if (typeof Intl === 'undefined') {
      application.needIntlPolyfill = true;
      application.deferReadiness();
      (0, _loadScript.loadScript)(application.baseAssets + 'assets/intl/intl.min.js').then(function () {
        return (0, _loadScript.loadScript)(application.baseAssets + 'assets/intl/locales/en-us.js');
      }).finally(function () {
        application.advanceReadiness();
      });
    }
  }

  exports.default = {
    name: 'polyfill-intl',
    initialize: initialize,
    before: 'app'
  };
});