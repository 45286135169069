define('ui/volumes/new/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() /* params, transition*/{
      var store = Ember.get(this, 'store');
      var clusterStore = Ember.get(this, 'clusterStore');

      return Ember.RSVP.hash({
        persistentVolumes: clusterStore.findAll('persistentVolume'),
        storageClasses: clusterStore.findAll('storageClass'),
        pvc: store.createRecord({ type: 'persistentVolumeClaim' })
      });
    }
  });
});