define('ui/components/authorize-user/component', ['exports', 'ui/components/authorize-user/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    tagName: 'div',
    classNames: ['login']
  });
});