define('ui/authenticated/project/project-catalogs/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    catalog: Ember.inject.service(),

    model: function model() {
      return Ember.get(this, 'catalog').fetchUnScopedCatalogs();
    }
  });
});