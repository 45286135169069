define('ui/components/volume-source/source-custom-log-path/component', ['exports', 'ui/components/volume-source/source-custom-log-path/template', 'shared/mixins/volume-source'], function (exports, _template, _volumeSource) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var formats = ['json', 'apache2', 'nginx', 'rfc3164', 'rfc5424', 'none'].map(function (value) {
    return {
      value: value,
      label: value
    };
  });

  exports.default = Ember.Component.extend(_volumeSource.default, {
    layout: _template.default,
    formats: formats,
    useCustomRegex: false,
    cachedFormat: null,

    field: 'flexVolume',
    initialCustomFormat: null,

    init: function init() {
      this._super.apply(this, arguments);
      var format = Ember.get(this, 'config.options.format');

      if (formats.every(function (item) {
        return item.value !== format;
      })) {
        Ember.set(this, 'useCustomRegex', true);
        Ember.set(this, 'initialCustomFormat', format);
      }
    },


    actions: {
      remove: function remove() {
        this.sendAction('remove', Ember.get(this, 'model'));
      },
      useCustomRegex: function useCustomRegex() {
        Ember.set(this, 'useCustomRegex', !Ember.get(this, 'useCustomRegex'));
      }
    },
    mount: function () {
      return Ember.get(this, 'mounts').get('firstObject');
    }.property('mounts.[]'),

    useCustomRegexChange: function () {
      var useCustomRegex = Ember.get(this, 'useCustomRegex');

      if (useCustomRegex) {
        Ember.set(this, 'cachedFormat', Ember.get(this, 'config.options.format'));
        Ember.set(this, 'config.options.format', Ember.get(this, 'initialCustomFormat'));
      } else {
        Ember.set(this, 'config.options.format', Ember.get(this, 'cachedFormat'));
      }
    }.observes('useCustomRegex')

  });
});