define('ui/components/resource-event-list/component', ['exports', 'ui/components/resource-event-list/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var NON_NAMESPACED_RESOURCES = ['PersistentVolume'];

  exports.default = Ember.Component.extend({
    globalStore: Ember.inject.service(),
    scope: Ember.inject.service(),

    layout: _template.default,

    events: null,

    sortBy: 'lastTimestamp',
    descending: false,
    name: null,
    namespaceId: null,
    resourceType: null,
    expanded: false,
    timeOutAnchor: null,
    loading: false,

    headers: [{
      name: 'type',
      sort: ['type'],
      translationKey: 'resourceEventList.table.type',
      width: 100
    }, {
      name: 'reason',
      sort: ['reason'],
      translationKey: 'resourceEventList.table.reason',
      width: 200
    }, {
      name: 'message',
      sort: ['message'],
      translationKey: 'resourceEventList.table.message'
    }, {
      name: 'lastTimestamp',
      sort: ['lastTimestamp'],
      translationKey: 'conditionSections.table.lastUpdate',
      width: 200
    }],

    init: function init() {
      this._super.apply(this, arguments);
      this.expanedDidChange();
    },
    willDestroyElement: function willDestroyElement() {
      this.clearTimeOut();
      this._super();
    },

    expanedDidChange: Ember.observer('expanded', function () {
      if (Ember.get(this, 'expanded')) {
        Ember.set(this, 'loading', true);
        this.fetchEvents();
      } else {
        this.clearTimeOut();
      }
    }),

    fetchEvents: function fetchEvents() {
      var _this = this;

      var query = 'fieldSelector=involvedObject.name=' + Ember.get(this, 'name') + ',involvedObject.kind=' + Ember.get(this, 'kind').capitalize();

      var url = '/k8s/clusters/' + Ember.get(this, 'scope.currentCluster.id') + '/api/v1/';

      if (NON_NAMESPACED_RESOURCES.indexOf(Ember.get(this, 'kind')) === -1) {
        url += 'namespaces/' + Ember.get(this, 'namespaceId') + '/';
      }

      url += 'events?' + query;

      Ember.get(this, 'globalStore').rawRequest({
        url: url,
        method: 'GET'
      }).then(function (xhr) {
        Ember.set(_this, 'events', xhr.body.items);
        Ember.set(_this, 'loading', false);
        var timeOutAnchor = setTimeout(function () {
          _this.fetchEvents();
        }, 10000);

        Ember.set(_this, 'timeOutAnchor', timeOutAnchor);
      });
    },
    clearTimeOut: function clearTimeOut() {
      var timeOutAnchor = Ember.get(this, 'timeOutAnchor');

      if (timeOutAnchor) {
        clearTimeout(timeOutAnchor);
        Ember.set(this, 'timeOutAnchor', timeOutAnchor);
      }
    }
  });
});