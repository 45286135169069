define('ui/volumes/index/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    scope: Ember.inject.service(),

    projectController: Ember.inject.controller('authenticated.project'),
    queryParams: ['sortBy'],
    sortBy: 'name',

    headers: [{
      name: 'state',
      sort: ['sortState', 'displayName'],
      searchField: 'displayState',
      translationKey: 'generic.state',
      width: 120
    }, {
      name: 'name',
      sort: ['displayName', 'id'],
      searchField: 'displayName',
      translationKey: 'volumesPage.claimName.label'
    }, {
      name: 'size',
      sort: ['sizeBytes'],
      search: ['sizeBytes', 'displaySize'],
      translationKey: 'generic.size',
      width: 120
    }, {
      name: 'volume',
      sort: ['volume.displayName', 'displayName', 'id'],
      translationKey: 'volumesPage.volume.label',
      searchField: null
    }, {
      name: 'storageClass',
      sort: ['storageClass.displayName', 'displayName', 'id'],
      translationKey: 'volumesPage.storageClass.label',
      searchField: null
    }],

    groupTableBy: Ember.computed.alias('projectController.groupTableBy'),
    expandedInstances: Ember.computed.alias('projectController.expandedInstances'),
    preSorts: Ember.computed.alias('projectController.preSorts'),

    rows: Ember.computed.alias('model.persistentVolumeClaims')
  });
});