define('ui/components/namespace-table/component', ['exports', 'ui/components/namespace-table/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var headers = [{
    name: 'state',
    sort: ['sortState', 'displayName'],
    searchField: 'displayState',
    translationKey: 'generic.state',
    width: 120
  }, {
    name: 'name',
    sort: ['displayName'],
    searchField: ['displayName', 'name'],
    translationKey: 'projectsPage.ns.label'
  }, {
    classNames: 'text-right pr-20',
    name: 'created',
    sort: ['createdTs'],
    searchField: false,
    translationKey: 'projectsPage.created.label',
    width: 250
  }];

  exports.default = Ember.Component.extend({
    scope: Ember.inject.service(),

    layout: _template.default,
    headers: headers,
    tagName: '',
    sortBy: 'name',
    searchText: '',
    subRows: true,
    suffix: true,
    paging: true,
    extraSearchFields: ['project.displayName']
  });
});