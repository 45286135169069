define('ui/ingresses/run/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(params) {
      var store = Ember.get(this, 'store');

      var dependencies = {
        namespacedcertificates: store.findAll('namespacedcertificate'),
        certificates: store.findAll('certificate')
      };

      if (params.ingressId) {
        dependencies['existingIngress'] = store.find('ingress', params.ingressId);
      }

      return Ember.RSVP.hash(dependencies).then(function (hash) {
        var ingress = void 0;

        if (hash.existingIngress) {
          if ('' + params.upgrade === 'true') {
            ingress = hash.existingIngress.clone();
            hash.existing = hash.existingIngress;
          } else {
            ingress = hash.existingIngress.cloneForNew();
          }
          delete hash.existingIngress;
        } else {
          ingress = store.createRecord({
            type: 'ingress',
            name: '',
            rules: [],
            tls: []
          });
        }
        hash.ingress = ingress;

        return hash;
      });
    },
    resetController: function resetController(controller, isExisting) {
      if (isExisting) {
        Ember.set(controller, 'ingressId', null);
        Ember.set(controller, 'upgrade', null);
      }
    },


    actions: {
      cancel: function cancel() {
        this.goToPrevious();
      }
    }
  });
});