define('ui/services/app', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    unknownProperty: function unknownProperty(key) {
      return Ember.get(this, 'app.' + key);
    }
  });
});