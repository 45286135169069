define('ui/authenticated/cluster/notifier/index/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    modalService: Ember.inject.service('modal'),
    globalStore: Ember.inject.service(),

    queryParams: ['type'],
    currentType: 'slack',

    notifiers: Ember.computed.alias('model.notifiers'),
    actions: {
      showNewEditModal: function showNewEditModal() {
        Ember.get(this, 'modalService').toggleModal('notifier/modal-new-edit', {
          closeWithOutsideClick: false,
          controller: this,
          currentType: Ember.computed.alias('controller.currentType'),
          mode: 'add'
        });
      }
    }
  });
});