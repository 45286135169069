define('ui/ingress/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(params) {
      var store = Ember.get(this, 'store');

      return Ember.RSVP.hash({
        ingress: store.find('ingress', params.ingress_id),
        certificates: store.findAll('certificate'),
        namespacedCertificates: store.findAll('namespacedcertificate')
      });
    }
  });
});