define('ui/components/modal-wechat/component', ['exports', 'shared/mixins/modal-base', 'ui/components/modal-wechat/template'], function (exports, _modalBase, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_modalBase.default, {
    settings: Ember.inject.service(),
    layout: _template.default,
    classNames: ['modal-container', 'about', 'span-6', 'offset-3', 'alert']
  });
});