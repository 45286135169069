define('ui/apps-tab/index/controller', ['exports', 'ui/utils/constants'], function (exports, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    prefs: Ember.inject.service(),
    intl: Ember.inject.service(),
    catalog: Ember.inject.service(),
    projectController: Ember.inject.controller('authenticated.project'),
    sortBy: 'name',

    tags: Ember.computed.alias('projectController.tags'),
    templatesObsvr: Ember.observer('model.apps.[]', function () {
      var _this = this;

      Ember.run.once(function () {
        return _this.get('catalog').fetchAppTemplates(Ember.get(_this, 'model.apps'));
      });
    }),

    filteredApps: Ember.computed('model.apps.@each.{type,isFromCatalog,tags,state}', 'tags', function () {
      var needTags = Ember.get(this, 'tags');

      var apps = Ember.get(this, 'model.apps').filter(function (ns) {
        return !_constants.default.REMOVEDISH_STATES.includes(Ember.get(ns, 'state'));
      });

      if (needTags && needTags.length) {
        apps = apps.filter(function (obj) {
          return obj.hasTags(needTags);
        });
      }

      apps = apps.sortBy('displayName');

      var group = [];
      var dataIndex = 0;

      apps.forEach(function (app, index) {
        if (index % 2 === 0) {
          group.push([app]);
          dataIndex++;
        } else {
          group[dataIndex - 1].push(app);
        }
      });

      return group;
    })
  });
});