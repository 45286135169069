define('ui/utils/percent-gauge', ['exports', 'shared/utils/percent-gauge'], function (exports, _percentGauge) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _percentGauge.default;
    }
  });
});