define('ui/components/modal-rotate-certificates/component', ['exports', 'shared/mixins/modal-base', 'ui/components/modal-rotate-certificates/template', 'moment'], function (exports, _modalBase, _template, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_modalBase.default, {
    growl: Ember.inject.service(),
    intl: Ember.inject.service(),

    layout: _template.default,
    classNames: ['large-modal'],
    rotateCaCerts: false,
    services: null,
    selectedServices: null,
    mode: 'single',
    model: Ember.computed.alias('modalService.modalOpts.model'),

    init: function init() {
      this._super.apply(this, arguments);

      Ember.setProperties(this, {
        services: [],
        errors: []
      });
    },
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      if (this.model.certsExpiring) {
        var expiringCerts = this.model.expiringCerts;

        var etcdNodes = (expiringCerts || []).filter(function (cert) {
          return cert.expiringCertName.includes('etcd');
        });
        var certLabel = null;

        Ember.set(this, 'services', this.modalOpts.serviceDefaults.map(function (cert) {
          var expiringCert = null;
          var expiresIn = null;

          if (cert === 'kubelet') {
            expiringCert = expiringCerts.findBy('expiringCertName', 'kube-node');
          } else if (cert === 'etcd' && etcdNodes.length > 0) {
            // there can be multiple etcd nodes with different cert expires, we can grab and alert the soonest expiring cert date since 'rofateCertificates' action will rotates all etcd node certs at the same time.
            expiringCert = etcdNodes.sortBy('milliUntil').get('firstObject');
          } else {
            expiringCert = expiringCerts.findBy('expiringCertName', cert);
          }

          if (expiringCert && !Ember.isEmpty(expiringCert.exactDateTime)) {
            expiresIn = expiringCert.exactDateTime;

            if (expiringCert.milliUntil > 0) {
              certLabel = _this.intl.t('modalRotateCertificates.expiring.until', {
                cert: cert,
                expiresIn: (0, _moment.default)(expiresIn).fromNow()
              });
            } else {
              certLabel = _this.intl.t('modalRotateCertificates.expiring.from', {
                cert: cert,
                expiresIn: (0, _moment.default)(expiresIn).fromNow()
              });
            }
          } else {
            certLabel = '' + cert;
          }

          return {
            label: certLabel,
            value: cert
          };
        }));
      } else {
        Ember.set(this, 'services', this.modalOpts.serviceDefaults.map(function (serv) {
          return {
            label: serv,
            value: serv
          };
        }));
      }
    },


    actions: {
      rotateCaCerts: function rotateCaCerts(cb) {
        var _this2 = this;

        var resource = this.modalOpts.model;
        var params = this.getRotateCertsParams();

        resource.doAction('rotateCertificates', params).then(function () {
          _this2.send('cancel');
        }).catch(function (err) {
          _this2.growl.fromError(err);
          if (cb) {
            cb(false);
          }
        });
      },
      mutServices: function mutServices(select) {
        Ember.set(this, 'selectedServices', select);
      }
    },

    getRotateCertsParams: function getRotateCertsParams() {
      switch (this.mode) {
        case 'caAndService':
          return {
            services: '',
            caCertificates: true
          };
        case 'single':
          return {
            services: Ember.get(this, 'selectedServices'),
            caCertificates: false
          };
        case 'service':
          return {
            services: null,
            caCertificates: false
          };
        default:
          return;
      }
    }
  });
});