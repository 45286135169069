define('ui/components/page-header/component', ['exports', 'ui/components/page-header/template', 'shared/utils/constants', 'shared/utils/navigation-tree'], function (exports, _template, _constants, _navigationTree) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function fnOrValue(val, ctx) {
    if (typeof val === 'function') {
      return val.call(ctx);
    } else {
      return val;
    }
  }

  exports.default = Ember.Component.extend({
    // Injections
    intl: Ember.inject.service(),
    scope: Ember.inject.service(),
    settings: Ember.inject.service(),
    access: Ember.inject.service(),
    prefs: Ember.inject.service(),

    layout: _template.default,
    // Inputs
    pageScope: null,

    // Component options
    tagName: 'header',
    classNames: ['page-header'],
    dropdownSelector: '.navbar .dropdown',

    stacks: null,

    // This computed property generates the active list of choices to display
    navTree: null,
    clusterId: Ember.computed.alias('scope.currentCluster.id'),
    cluster: Ember.computed.alias('scope.currentCluster'),
    projectId: Ember.computed.alias('scope.currentProject.id'),
    project: Ember.computed.alias('scope.currentProject'),
    accessEnabled: Ember.computed.alias('access.enabled'),

    init: function init() {
      this._super.apply(this, arguments);
      Ember.get(this, 'intl.locale');

      Ember.setProperties(this, {
        stacks: Ember.get(this, 'store').all('stack'),
        hosts: Ember.get(this, 'store').all('host'),
        stackSchema: Ember.get(this, 'store').getById('schema', 'stack')
      });

      Ember.run.once(this, 'updateNavTree');

      Ember.run.scheduleOnce('render', function () {
        // responsive nav 63-87
        var responsiveNav = document.getElementById('js-responsive-nav');

        var toggleBtn = document.createElement('a');

        toggleBtn.setAttribute('class', 'nav-toggle');
        responsiveNav.insertBefore(toggleBtn, responsiveNav.firstChild);

        function hasClass(e, t) {
          return new RegExp(' ' + t + ' ').test(' ' + e.className + ' ');
        }

        function toggleClass(e, t) {
          var n = ' ' + e.className.replace(/[\t\r\n]/g, ' ') + ' ';

          if (hasClass(e, t)) {
            while (n.indexOf(' ' + t + ' ') >= 0) {
              n = n.replace(' ' + t + ' ', ' ');
            }e.className = n.replace(/^\s+|\s+$/g, '');
          } else {
            e.className += ' ' + t;
          }
        }

        toggleBtn.onclick = function () {
          toggleClass(this.parentNode, 'nav-open');
        };

        var root = document.documentElement;

        root.className = root.className + ' js';
      });
    },
    willRender: function willRender() {
      if ($('BODY').hasClass('touch') && $('header > nav').hasClass('nav-open')) {
        // eslint-disable-line
        Ember.run.later(function () {
          $('header > nav').removeClass('nav-open'); // eslint-disable-line
        });
      }
    },


    shouldUpdateNavTree: Ember.observer('pageScope', 'clusterId', 'cluster.isReady', 'projectId', 'stacks.@each.group', 'prefs.' + _constants.default.PREFS.ACCESS_WARNING, 'access.enabled', 'intl.locale', function () {
      Ember.run.scheduleOnce('afterRender', this, 'updateNavTree');
    }),

    // beyond things listed in "Inputs"
    hasProject: Ember.computed('project', function () {
      return !!Ember.get(this, 'project');
    }),

    // Hackery: You're an owner if you can write to the 'system' field of a stack
    isOwner: Ember.computed('stackSchema.resourceFields.system.update', function () {
      return !!Ember.get(this, 'stackSchema.resourceFields.system.update');
    }),

    updateNavTree: function updateNavTree() {
      var _this = this;

      var currentScope = Ember.get(this, 'pageScope');

      var out = (0, _navigationTree.get)().filter(function (item) {
        if (typeof Ember.get(item, 'condition') === 'function') {
          if (!item.condition.call(_this)) {
            return false;
          }
        }

        if (Ember.get(item, 'scope') && Ember.get(item, 'scope') !== currentScope) {
          return false;
        }

        var itemRoute = fnOrValue(Ember.get(item, 'route'), _this);
        var itemContext = (Ember.get(item, 'ctx') || []).map(function (prop) {
          return fnOrValue(prop, _this);
        });

        Ember.setProperties(item, {
          localizedLabel: fnOrValue(Ember.get(item, 'localizedLabel'), _this),
          label: fnOrValue(Ember.get(item, 'label'), _this),
          route: itemRoute,
          ctx: itemContext,
          submenu: fnOrValue(Ember.get(item, 'submenu'), _this)
        });

        Ember.set(item, 'submenu', (Ember.get(item, 'submenu') || []).filter(function (subitem) {
          if (typeof Ember.get(subitem, 'condition') === 'function' && !subitem.condition.call(_this)) {
            return false;
          }

          var subItemRoute = fnOrValue(Ember.get(subitem, 'route'), _this);
          var subItemContext = (Ember.get(subitem, 'ctx') || []).map(function (prop) {
            return fnOrValue(prop, _this);
          });

          Ember.setProperties(subitem, {
            localizedLabel: fnOrValue(Ember.get(subitem, 'localizedLabel'), _this),
            label: fnOrValue(Ember.get(subitem, 'label'), _this),
            route: subItemRoute,
            ctx: subItemContext
          });

          return true;
        }));

        return true;
      });

      var old = JSON.stringify(Ember.get(this, 'navTree'));
      var neu = JSON.stringify(out);

      if (old !== neu) {
        Ember.set(this, 'navTree', out);
      }
    }
  }

  // Utilities you can use in the condition() function to decide if an item is shown or hidden,
  );
});