define('ui/security/authentication/ping/controller', ['exports', 'global-admin/security/authentication/ping/controller'], function (exports, _controller) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _controller.default;
    }
  });
});