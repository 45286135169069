define('ui/custom-drivers/cluster-drivers/template', ['exports', 'nodes/custom-drivers/cluster-drivers/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _template.default;
    }
  });
});