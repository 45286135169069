define('ui/models/nodedriver', ['exports', '@rancher/ember-api-store/models/resource', 'ui/utils/constants', 'ui/utils/parse-externalid'], function (exports, _resource, _constants, _parseExternalid) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.BUILT_IN_ICON_ONLY = exports.BUILT_IN_UI = undefined;
  var BUILT_IN_UI = exports.BUILT_IN_UI = ['amazonec2', 'digitalocean', 'azure', 'exoscale', 'packet', 'rackspace', 'vmwarevsphere', 'aliyunecs'];
  var BUILT_IN_ICON_ONLY = exports.BUILT_IN_ICON_ONLY = ['openstack', 'otc'];

  function displayUrl(url) {
    url = url || '';
    var parts = url.split('/');
    var out = null;

    if (parts.length < 2) {
      return url;
    }

    if (url.indexOf('github.com') >= 0) {
      out = '.../' + parts[parts.length - 2] + '/' + parts[parts.length - 1];
    } else {
      out = url;
    }

    return out;
  }

  exports.default = _resource.default.extend({
    modalService: Ember.inject.service('modal'),
    catalog: Ember.inject.service(),
    intl: Ember.inject.service(),

    type: 'nodeDriver',
    catalogTemplateIcon: Ember.computed('externalId', function () {
      var parsedExtId = (0, _parseExternalid.parseExternalId)(Ember.get(this, 'externalId')) || null;

      if (!parsedExtId) {
        return null;
      }

      if (Ember.get(this, 'catalog').getTemplateFromCache(parsedExtId.templateId)) {
        return Ember.get(this, 'catalog').getTemplateFromCache(parsedExtId.templateId).get('links.icon');
      } else {
        return Ember.get(this, 'app.baseAssets') + 'assets/images/providers/generic-driver.svg';
      }
    }),

    displayName: Ember.computed('name', 'intl.locale', function () {
      var intl = Ember.get(this, 'intl');
      var name = Ember.get(this, 'name');
      var key = 'nodeDriver.displayName.' + name;

      if (name && intl.exists(key)) {
        return intl.t(key);
      } else if (name) {
        return name.capitalize();
      } else {
        return '(' + Ember.get(this, 'id') + ')';
      }
    }),

    displayIcon: Ember.computed('name', function () {
      var name = Ember.get(this, 'name');

      if (Ember.get(this, 'hasBuiltinUi')) {
        return name;
      } else {
        return 'generic';
      }
    }),

    displayUrl: Ember.computed('url', function () {
      return displayUrl(Ember.get(this, 'url'));
    }),

    displayChecksum: Ember.computed('checksum', function () {
      return Ember.get(this, 'checksum').substring(0, 8);
    }),

    displayUiUrl: Ember.computed('uiUrl', function () {
      return displayUrl(Ember.get(this, 'uiUrl'));
    }),

    hasBuiltinUi: Ember.computed('name', function () {
      return BUILT_IN_UI.indexOf(Ember.get(this, 'name')) >= 0;
    }),

    hasBuiltinIconOnly: Ember.computed('name', function () {
      return BUILT_IN_ICON_ONLY.indexOf(Ember.get(this, 'name')) >= 0;
    }),

    isCustom: Ember.computed('builtin', 'externalId', function () {
      return !Ember.get(this, 'builtin') && !Ember.get(this, 'externalId');
    }),

    hasUi: Ember.computed('hasBuiltinUi', function () {
      return Ember.get(this, 'hasBuiltinUi') || !!Ember.get(this, 'uiUrl');
    }),

    newExternalId: Ember.computed('isSystem', 'selectedTemplateModel.id', function () {
      var externalId = _constants.default.EXTERNAL_ID.KIND_CATALOG + _constants.default.EXTERNAL_ID.KIND_SEPARATOR + Ember.get(this, 'selectedTemplateModel.id');

      return externalId;
    }),

    canEdit: Ember.computed('links.update', 'builtin', function () {
      return !!Ember.get(this, 'links.update') && !Ember.get(this, 'builtin');
    }),

    availableActions: Ember.computed('actionLinks.{activate,deactivate}', function () {
      var a = Ember.get(this, 'actionLinks') || {};

      return [{
        label: 'action.activate',
        icon: 'icon icon-play',
        action: 'activate',
        enabled: !!a.activate,
        bulkable: true
      }, {
        label: 'action.deactivate',
        icon: 'icon icon-pause',
        action: 'deactivate',
        enabled: !!a.deactivate,
        bulkable: true
      }];
    }),

    externalIdInfo: Ember.computed('externalId', function () {
      return (0, _parseExternalid.parseExternalId)(Ember.get(this, 'externalId'));
    }),
    actions: {
      activate: function activate() {
        return this.doAction('activate');
      },
      deactivate: function deactivate() {
        return this.doAction('deactivate');
      },
      edit: function edit() {
        Ember.get(this, 'modalService').toggleModal('modal-edit-driver', this);
      }
    }

  });
});