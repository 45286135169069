define('ui/helpers/maybe-t', ['exports', 'shared/helpers/maybe-t'], function (exports, _maybeT) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _maybeT.default;
    }
  });
});