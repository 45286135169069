define('ui/authenticated/cluster/cluster-catalogs/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    catalog: Ember.inject.service(),

    model: function model() {
      return Ember.RSVP.hash({
        clusterCatalogs: Ember.get(this, 'catalog').fetchCatalogs('clusterCatalog'),
        globalCatalogs: Ember.get(this, 'catalog').fetchCatalogs()
      });
    }
  });
});