define('ui/authenticated/cluster/nodes/index/route', ['exports', 'ui/utils/constants'], function (exports, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    globalStore: Ember.inject.service(),
    scope: Ember.inject.service(),

    model: function model() {
      var cluster = this.modelFor('authenticated.cluster');

      return this.get('globalStore').findAll('node').then(function (nodes) {
        return {
          cluster: cluster,
          nodes: nodes
        };
      });
    },


    setDefaultRoute: Ember.on('activate', function () {
      Ember.set(this, 'session.' + _constants.default.SESSION.CLUSTER_ROUTE, 'authenticated.cluster.nodes');
    })
  });
});