define('ui/containers/index/controller', ['exports', 'ui/components/pod-dots/component'], function (exports, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.headers = undefined;
  var headers = exports.headers = [{
    name: 'expand',
    sort: false,
    searchField: null,
    width: 30
  }, {
    name: 'state',
    sort: ['sortState', 'displayName'],
    searchField: 'displayState',
    translationKey: 'generic.state',
    width: 120
  }, {
    name: 'name',
    sort: ['sortName', 'id'],
    searchField: 'displayName',
    translationKey: 'generic.name'
  }, {
    name: 'image',
    sort: ['image', 'displayName'],
    searchField: 'image',
    translationKey: 'generic.image'
  }, {
    name: 'scale',
    sort: ['scale:desc', 'isGlobalScale:desc', 'displayName'],
    searchField: null,
    translationKey: 'stacksPage.table.scale',
    classNames: 'text-center',
    width: 100
  }];

  exports.default = Ember.Controller.extend({
    scope: Ember.inject.service(),
    prefs: Ember.inject.service(),

    projectController: Ember.inject.controller('authenticated.project'),
    queryParams: ['sortBy'],
    sortBy: 'name',

    headers: headers,
    extraSearchFields: ['id:prefix', 'displayIp:ip'],
    extraSearchSubFields: _component.searchFields,

    group: Ember.computed.alias('projectController.group'),
    groupTableBy: Ember.computed.alias('projectController.groupTableBy'),
    expandedInstances: Ember.computed.alias('projectController.expandedInstances'),
    preSorts: Ember.computed.alias('projectController.preSorts'),

    actions: {
      toggleExpand: function toggleExpand() {
        var _get;

        (_get = this.get('projectController')).send.apply(_get, ['toggleExpand'].concat(Array.prototype.slice.call(arguments)));
      }
    },

    rows: function () {
      var groupBy = this.get('group');
      var out = [];

      switch (groupBy) {
        case 'none':
        case 'node':
          out = this.get('model.pods');
          break;
        default:
          out = this.get('model.pods').filter(function (obj) {
            return !obj.get('workloadId');
          });
          out.pushObjects(this.get('model.workloads').slice());
          break;
      }

      return out;
    }.property('group', 'model.workloads.@each.{namespaceId,isBalancer}', 'model.pods.@each.{workloadId,namespaceId}'),

    groupByRef: function () {
      var group = this.get('group');

      if (group === 'node') {
        return 'node';
      } else if (group === 'namespace') {
        return 'namespace';
      }
    }.property('group')
  });
});