define('ui/authenticated/cluster/edit/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['provider'],
    provider: null,

    cluster: Ember.computed.alias('model.cluster'),

    actions: {
      close: function close() {
        this.transitionToRoute('authenticated.cluster');
      }
    }
  });
});