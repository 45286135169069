define('ui/authenticated/project/certificates/new/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() /* params, transition*/{
      return this.get('store').createRecord({ type: 'certificate' });
    },
    resetController: function resetController(controller, isExiting /* , transition*/) {
      if (isExiting) {
        controller.set('errors', null);
      }
    }
  });
});