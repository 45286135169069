define('ui/ingresses/run/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['ingressId', 'upgrade'],
    ingressId: null,
    upgrade: null,

    actions: {
      done: function done() {
        this.send('goToPrevious', 'ingresses.index');
      },
      cancel: function cancel() {
        this.send('goToPrevious', 'ingresses.index');
      }
    }
  });
});