define('ui/components/form-node-selector/component', ['exports', 'ui/components/form-node-selector/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,

    rule: null,
    editing: true,

    actions: {
      removeRule: function removeRule(rule) {
        this.sendAction('removeRule', rule);
      }
    }
  });
});