define('ui/authenticated/project/dns/detail/edit/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      var store = Ember.get(this, 'store');
      var original = this.modelFor('authenticated.project.dns.detail').record;

      return Ember.RSVP.hash({
        dnsRecords: store.findAll('service'),
        workloads: store.findAll('workload'),
        record: original.clone()
      });
    },
    setupController: function setupController(controller /* , model*/) {
      this._super.apply(this, arguments);
      var original = this.modelFor('authenticated.project.dns.detail');

      Ember.set(controller, 'originalModel', original);
    }
  });
});