define('ui/models/deployment', ['exports', 'ui/models/workload'], function (exports, _workload) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Deployment = _workload.default.extend({
    combinedState: Ember.computed('state', 'isPaused', function () {
      var service = Ember.get(this, 'state');

      if (service === 'active' && Ember.get(this, 'isPaused')) {
        return 'paused';
      }

      return service;
    }),

    isPaused: Ember.computed('paused', function () {
      return !!Ember.get(this, 'paused');
    })
  });

  exports.default = Deployment;
});