define('ui/models/scalinggroup', ['exports', 'ui/models/service'], function (exports, _service) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ScalingGroup = _service.default.extend({
    type: 'scalingGroup',
    displayEnvironmentVars: Ember.computed('launchConfig.environment', function () {
      var envs = [];
      var environment = this.get('launchConfig.environment') || {};

      Object.keys(environment).forEach(function (key) {
        envs.pushObject({
          key: key,
          value: environment[key]
        });
      });

      return envs;
    })
  });

  exports.default = ScalingGroup;
});