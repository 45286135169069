define('ui/authenticated/project/security/members/index/route', ['exports', 'ui/utils/constants'], function (exports, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    globalStore: Ember.inject.service(),

    model: function model() {
      var pid = this.paramsFor('authenticated.project');
      var pm = this.modelFor('authenticated.project');

      this.controllerFor('authenticated.project.security.members.index').set('projectId', pid.project_id);

      // TODO 2.0 bug here with projectId on a PRTB where API mungs it up be removing the cluster id on the projectid
      return Ember.get(pm, 'project');
    },


    setDefaultRoute: Ember.on('activate', function () {
      Ember.set(this, 'session.' + _constants.default.SESSION.PROJECT_ROUTE, 'authenticated.project.security.members.index');
    })
  });
});