define('ui/components/settings/user-info/component', ['exports', 'ui/components/settings/user-info/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    access: Ember.inject.service(),
    modalService: Ember.inject.service('modal'),
    layout: _template.default,
    account: null,

    actions: {
      editPassword: function editPassword() {
        this.get('account').send('edit');
      }
    }

  });
});