define('ui/components/modal-container-logs/component', ['exports', 'shared/mixins/modal-base', 'ui/components/modal-container-logs/template'], function (exports, _modalBase, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_modalBase.default, {
    layout: _template.default,
    classNames: ['large-modal', 'fullscreen-modal', 'alert'],
    originalModel: Ember.computed.alias('modalService.modalOpts.model'),
    containerName: Ember.computed.alias('modalService.modalOpts.containerName')
  });
});