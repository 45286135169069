define('ui/authenticated/project/certificates/detail/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(params) {
      var all = this.modelFor('authenticated.project.certificates');

      var cert = all.projectCerts.findBy('id', params.certificate_id);

      if (cert) {
        return cert;
      }

      cert = all.namespacedCerts.findBy('id', params.certificate_id);
      if (cert) {
        return cert;
      }

      return Ember.get(this, 'store').find('certificate', params.certificate_id);
    }
  });
});