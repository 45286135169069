define('ui/utils/convert-yaml', ['exports', 'shared/utils/convert-yaml'], function (exports, _convertYaml) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'convertDotAnswersToYaml', {
    enumerable: true,
    get: function () {
      return _convertYaml.convertDotAnswersToYaml;
    }
  });
});