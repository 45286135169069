define('ui/components/container/form-scale/component', ['exports', 'ui/components/container/form-scale/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var HISTORY_LIMIT = 10;

  function getDefaultConfig(config) {
    switch (config) {
      case 'deployment':
        return {
          type: 'deploymentConfig',
          revisionHistoryLimit: HISTORY_LIMIT
        };
      case 'daemonSet':
        return {
          type: 'daemonSetConfig',
          revisionHistoryLimit: HISTORY_LIMIT
        };
      case 'replicaSet':
        return { type: 'replicaSetConfig' };
      case 'replicationController':
        return { type: 'replicationControllerConfig' };
      case 'statefulSet':
        return {
          type: 'statefulSetConfig',
          podManagementPolicy: 'OrderedReady',
          revisionHistoryLimit: HISTORY_LIMIT,
          volumeClaimTemplates: []
        };
      case 'cronJob':
        return {
          type: 'cronJobConfig',
          concurrencyPolicy: 'Allow',
          failedJobsHistoryLimit: HISTORY_LIMIT,
          schedule: '0 * * * *',
          successfulJobsHistoryLimit: HISTORY_LIMIT,
          jobConfig: {},
          suspend: false
        };
      case 'job':
        return { type: 'jobConfig' };
    }
  }

  exports.default = Ember.Component.extend({
    layout: _template.default,
    initialScale: null,
    isUpgrade: null,
    isGlobal: null,
    min: 0,
    max: 1000,
    scaleMode: null,
    editing: true,

    userInput: null,

    canChangeScaleMode: Ember.computed.not('isUpgrade'),

    init: function init() {
      this._super.apply(this, arguments);

      var initial = Ember.get(this, 'initialScale');

      if (initial === null) {
        initial = 1;
      }

      Ember.set(this, 'userInput', '' + initial);
      this.scaleModeChanged();
      if (Ember.get(this, 'scaleMode') !== 'deployment' && !Ember.get(this, 'isUpgrade')) {
        Ember.set(this, 'advancedShown', true);
      }
    },


    actions: {
      increase: function increase() {
        Ember.set(this, 'userInput', Math.min(Ember.get(this, 'max'), Ember.get(this, 'asInteger') + 1));
      },
      decrease: function decrease() {
        Ember.set(this, 'userInput', Math.max(Ember.get(this, 'min'), Ember.get(this, 'asInteger') - 1));
      },
      showAdvanced: function showAdvanced() {
        this.set('advancedShown', true);
      }
    },

    scaleChanged: Ember.observer('asInteger', function () {
      var cur = Ember.get(this, 'asInteger');

      this.sendAction('setScale', cur);
    }),

    scaleModeChanged: Ember.observer('scaleMode', function () {
      var scaleMode = Ember.get(this, 'scaleMode');

      if (!scaleMode || scaleMode === 'sidekick') {
        return;
      }

      var config = scaleMode + 'Config';
      var workload = Ember.get(this, 'workload');

      if (!Ember.get(workload, config)) {
        Ember.set(workload, config, Ember.get(this, 'store').createRecord(getDefaultConfig(scaleMode)));
      }
    }),

    canAdvanced: Ember.computed('advancedShown', 'isUpgrade', 'scaleMode', function () {
      if (Ember.get(this, 'advancedShown')) {
        return false;
      }

      if (Ember.get(this, 'isUpgrade')) {
        return false;
      }

      return true;
    }),

    asInteger: Ember.computed('userInput', function () {
      return parseInt(Ember.get(this, 'userInput'), 10) || 0;
    }),

    canChangeScale: Ember.computed('scaleMode', function () {
      return ['deployment', 'replicaSet', 'daemonSet', 'replicationController', 'statefulSet'].includes(Ember.get(this, 'scaleMode'));
    })
  });
});