define('ui/catalog-tab/index/route', ['exports', 'ui/utils/constants'], function (exports, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    resetController: function resetController(controller, isExiting /* , transition*/) {
      if (isExiting) {
        controller.set('search', '');
      }
    },


    setDefaultRoute: Ember.on('activate', function () {
      Ember.set(this, 'session.' + _constants.default.SESSION.PROJECT_ROUTE, 'catalog-tab.index');
    })
  });
});