define('ui/utils/parse-port', ['exports', 'shared/utils/parse-port'], function (exports, _parsePort) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _parsePort.default;
    }
  });
  Object.defineProperty(exports, 'parsePortSpec', {
    enumerable: true,
    get: function () {
      return _parsePort.parsePortSpec;
    }
  });
  Object.defineProperty(exports, 'portToInt', {
    enumerable: true,
    get: function () {
      return _parsePort.portToInt;
    }
  });
});