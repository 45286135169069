define('ui/utils/platform', ['exports', 'shared/utils/platform'], function (exports, _platform) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'platform', {
    enumerable: true,
    get: function () {
      return _platform.platform;
    }
  });
  Object.defineProperty(exports, 'isLinuxy', {
    enumerable: true,
    get: function () {
      return _platform.isLinuxy;
    }
  });
  Object.defineProperty(exports, 'isMac', {
    enumerable: true,
    get: function () {
      return _platform.isMac;
    }
  });
  Object.defineProperty(exports, 'isWin', {
    enumerable: true,
    get: function () {
      return _platform.isWin;
    }
  });
  Object.defineProperty(exports, 'alternateKey', {
    enumerable: true,
    get: function () {
      return _platform.alternateKey;
    }
  });
  Object.defineProperty(exports, 'alternateLabel', {
    enumerable: true,
    get: function () {
      return _platform.alternateLabel;
    }
  });
  Object.defineProperty(exports, 'moreKey', {
    enumerable: true,
    get: function () {
      return _platform.moreKey;
    }
  });
  Object.defineProperty(exports, 'moreLabel', {
    enumerable: true,
    get: function () {
      return _platform.moreLabel;
    }
  });
  Object.defineProperty(exports, 'rangeKey', {
    enumerable: true,
    get: function () {
      return _platform.rangeKey;
    }
  });
  Object.defineProperty(exports, 'rangeLabel', {
    enumerable: true,
    get: function () {
      return _platform.rangeLabel;
    }
  });
  Object.defineProperty(exports, 'isAlternate', {
    enumerable: true,
    get: function () {
      return _platform.isAlternate;
    }
  });
  Object.defineProperty(exports, 'isMore', {
    enumerable: true,
    get: function () {
      return _platform.isMore;
    }
  });
  Object.defineProperty(exports, 'isRange', {
    enumerable: true,
    get: function () {
      return _platform.isRange;
    }
  });
  Object.defineProperty(exports, 'version', {
    enumerable: true,
    get: function () {
      return _platform.version;
    }
  });
  Object.defineProperty(exports, 'userAgent', {
    enumerable: true,
    get: function () {
      return _platform.userAgent;
    }
  });
  Object.defineProperty(exports, 'isGecko', {
    enumerable: true,
    get: function () {
      return _platform.isGecko;
    }
  });
  Object.defineProperty(exports, 'isBlink', {
    enumerable: true,
    get: function () {
      return _platform.isBlink;
    }
  });
  Object.defineProperty(exports, 'isWebKit', {
    enumerable: true,
    get: function () {
      return _platform.isWebKit;
    }
  });
  Object.defineProperty(exports, 'isSafari', {
    enumerable: true,
    get: function () {
      return _platform.isSafari;
    }
  });
  Object.defineProperty(exports, 'isMobile', {
    enumerable: true,
    get: function () {
      return _platform.isMobile;
    }
  });
  Object.defineProperty(exports, 'xhrConcur', {
    enumerable: true,
    get: function () {
      return _platform.xhrConcur;
    }
  });
});