define('ui/models/service', ['exports', '@rancher/ember-api-store/models/resource', '@rancher/ember-api-store/utils/denormalize', 'ui/mixins/endpoint-ports'], function (exports, _resource, _denormalize, _endpointPorts) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.UNKNOWN = exports.CLUSTERIP = exports.SELECTOR = exports.WORKLOAD = exports.ALIAS = exports.CNAME = exports.ARECORD = undefined;

  var _FIELD_MAP;

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  var ARECORD = exports.ARECORD = 'arecord';
  var CNAME = exports.CNAME = 'cname';
  var ALIAS = exports.ALIAS = 'alias';
  var WORKLOAD = exports.WORKLOAD = 'workload';
  var SELECTOR = exports.SELECTOR = 'selector';
  var CLUSTERIP = exports.CLUSTERIP = 'clusterIp';
  var UNKNOWN = exports.UNKNOWN = 'unknown';

  var FIELD_MAP = (_FIELD_MAP = {}, _defineProperty(_FIELD_MAP, ARECORD, 'ipAddresses'), _defineProperty(_FIELD_MAP, CNAME, 'hostname'), _defineProperty(_FIELD_MAP, ALIAS, 'targetDnsRecordIds'), _defineProperty(_FIELD_MAP, WORKLOAD, 'targetWorkloadIds'), _defineProperty(_FIELD_MAP, SELECTOR, 'selector'), _FIELD_MAP);

  var Service = _resource.default.extend(_endpointPorts.default, {
    clusterStore: Ember.inject.service(),
    router: Ember.inject.service(),
    intl: Ember.inject.service(),
    scope: Ember.inject.service(),
    namespace: (0, _denormalize.reference)('namespaceId', 'namespace', 'clusterStore'),
    targetDnsRecords: (0, _denormalize.arrayOfReferences)('targetDnsRecordIds', 'service'),
    targetWorkloads: (0, _denormalize.arrayOfReferences)('targetWorkloadIds', 'workload'),

    isIngress: Ember.computed.equal('ownerReferences.firstObject.kind', 'Ingress'),

    selectedPods: Ember.computed('selector', function () {
      var rules = Ember.get(this, 'selector');
      var keys = Object.keys(rules);

      if (!keys.length) {
        return [];
      }

      var pods = Ember.get(this, 'store').all('pod');
      var key = void 0;

      for (var i = 0; pods.length > 0 && i < keys.length; i++) {
        key = keys[i];
        pods = pods.filter(function (p) {
          return p.hasLabel(key, rules[key]);
        });
      }

      return pods;
    }),

    nameWithType: Ember.computed('displayName', 'recordType', 'intl.locale', function () {
      var name = Ember.get(this, 'displayName');
      var recordType = Ember.get(this, 'recordType');
      var type = Ember.get(this, 'intl').t('dnsPage.type.' + recordType);

      return name + ' (' + type + ')';
    }),

    availablePorts: Ember.computed('recordType', 'ports.@each.{targetPort,port}', function () {
      var list = [];
      var ports = Ember.get(this, 'ports');

      ports.forEach(function (p) {
        list.push(p.targetPort.toString());
        list.push(p.port.toString());
        list.push(Ember.get(p, 'name'));
      });

      return list.uniq().map(function (p) {
        return { port: p };
      }).sortBy('port');
    }),

    recordType: Ember.computed('ipAddresses.length', 'hostname', 'selector', 'targetDnsRecordIds.length', 'targetWorkloadIds.length', 'clusterIp', function () {
      if (Ember.get(this, 'ipAddresses.length')) {
        return ARECORD;
      }

      if (Ember.get(this, 'hostname')) {
        return CNAME;
      }

      if (Ember.get(this, 'targetDnsRecordIds.length')) {
        return ALIAS;
      }

      if (Ember.get(this, 'targetWorkloadIds.length')) {
        return WORKLOAD;
      }

      var selector = Ember.get(this, 'selector');

      if (selector && Object.keys(selector).length) {
        return SELECTOR;
      }

      if (Ember.get(this, 'clusterIp')) {
        return CLUSTERIP;
      }

      return UNKNOWN;
    }),

    displayType: Ember.computed('recordType', 'intl.locale', function () {
      return Ember.get(this, 'intl').t('dnsPage.type.' + Ember.get(this, 'recordType'));
    }),

    displayTarget: Ember.computed('recordType', 'ipAddresses.[]', 'hostname', 'selector', 'targetDnsRecords.[]', 'targetWorkloads.[]', function () {
      var selectors = Ember.get(this, 'selector') || {};
      var records = Ember.get(this, 'targetDnsRecords') || [];
      var workloads = Ember.get(this, 'targetWorkloads') || {};

      switch (Ember.get(this, 'recordType')) {
        case ARECORD:
          return Ember.get(this, 'ipAddresses').join('\n');
        case CNAME:
          return Ember.get(this, 'hostname');
        case SELECTOR:
          return Object.keys(selectors).map(function (k) {
            return k + '=' + selectors[k];
          }).join('\n');
        case ALIAS:
          return records.map(function (x) {
            return Ember.get(x, 'displayName');
          }).join('\n');
        case WORKLOAD:
          return workloads.map(function (x) {
            return Ember.get(x, 'displayName');
          }).join('\n');
        case CLUSTERIP:
          return Ember.get(this, 'clusterIp');
        default:
          return 'Unknown';
      }
    }),

    selectorArray: Ember.computed('selector', function () {
      var selectors = Ember.get(this, 'selector') || {};
      var out = [];

      Object.keys(selectors).map(function (k) {
        out.push({
          key: k,
          value: selectors[k]
        });
      });

      return out;
    }),

    canEdit: Ember.computed('links.update', 'isIngress', function () {
      return !!Ember.get(this, 'links.update') && !Ember.get(this, 'isIngress');
    }),

    canRemove: Ember.computed('links.remove', 'isIngress', function () {
      return !!Ember.get(this, 'links.remove') && !Ember.get(this, 'isIngress');
    }),

    displayKind: Ember.computed('intl.locale', 'kind', function () {
      var intl = Ember.get(this, 'intl');

      if (Ember.get(this, 'kind') === 'LoadBalancer') {
        return intl.t('model.service.displayKind.loadBalancer');
      } else {
        return intl.t('model.service.displayKind.generic');
      }
    }),

    proxyEndpoints: Ember.computed('labels', function () {
      var _this = this;

      var parts = [];
      var labels = Ember.get(this, 'labels');
      var location = window.location;

      if (labels && labels['kubernetes.io/cluster-service'] === 'true') {
        (Ember.get(this, 'ports') || []).forEach(function (port) {
          var linkEndpoint = location.origin + '/k8s/clusters/' + Ember.get(_this, 'scope.currentCluster.id') + '/api/v1/namespaces/' + Ember.get(_this, 'namespaceId') + '/services/';

          if (Ember.get(port, 'name') === 'http' || Ember.get(port, 'name') === 'https') {
            linkEndpoint += Ember.get(port, 'name') + ':';
          }
          linkEndpoint += Ember.get(_this, 'name') + ':' + Ember.get(port, 'port') + '/proxy/';

          parts.push({
            linkEndpoint: linkEndpoint,
            displayEndpoint: '/index.html',
            protocol: location.protocol.substr(0, location.protocol.length - 1),
            isTcpish: true,
            isReady: true
          });
        });
      }

      return parts;
    }),

    actions: {
      edit: function edit() {
        Ember.get(this, 'router').transitionTo('authenticated.project.dns.detail.edit', this.get('id'));
      },
      clone: function clone() {
        Ember.get(this, 'router').transitionTo('authenticated.project.dns.new', this.get('projectId'), { queryParams: { id: this.get('id') } });
      }
    },

    clearTypesExcept: function clearTypesExcept(type) {
      var _this2 = this;

      Object.keys(FIELD_MAP).forEach(function (key) {
        if (key !== type) {
          Ember.set(_this2, FIELD_MAP[key], null);
        }
      });
    }
  });

  Service.reopenClass({
    mangleIn: function mangleIn(data) {
      if (data) {
        var publicEndpoints = Ember.get(data, 'publicEndpoints') || [];

        publicEndpoints.forEach(function (endpoint) {
          endpoint.type = 'publicEndpoint';
        });
      }

      return data;
    }
  });

  exports.default = Service;
});