define('ui/authenticated/cluster/security/members/index/route', ['exports', 'ui/utils/constants'], function (exports, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    globalStore: Ember.inject.service(),

    model: function model() {
      return this.modelFor('authenticated.cluster');
    },


    setDefaultRoute: Ember.on('activate', function () {
      Ember.set(this, 'session.' + _constants.default.SESSION.CLUSTER_ROUTE, 'authenticated.cluster.security.members.index');
    })
  });
});