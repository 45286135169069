define('ui/instance-initializers/cluster-store', ['exports', 'ui/mixins/store-tweaks'], function (exports, _storeTweaks) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(instance) {
    var application = instance.lookup('application:main');
    var clusterStore = instance.lookup('service:clusterStore');
    var cookies = instance.lookup('service:cookies');

    clusterStore.reopen(_storeTweaks.default);
    clusterStore.baseUrl = application.clusterEndpoint;

    var timeout = cookies.get('timeout');

    if (timeout) {
      clusterStore.defaultTimeout = timeout;
    }
  }

  exports.default = {
    name: 'cluster-store',
    initialize: initialize
  };
});