define('ui/components/container/form-upgrade-deployment/component', ['exports', 'ui/components/container/form-upgrade-deployment/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function maybeInt(str) {
    var num = parseInt(str, 10);

    if ('' + num === str) {
      return num;
    }

    return str;
  }

  exports.default = Ember.Component.extend({
    layout: _template.default,
    workload: null,
    scaleMode: null,
    editing: null,
    isUpgrade: null,

    classNames: ['accordion-wrapper'],
    _strategy: null,
    workloadConfig: null,
    batchSize: null,

    didReceiveAttrs: function didReceiveAttrs() {
      var config = Ember.get(this, 'workloadConfig');
      var maxSurge = Ember.get(config, 'maxSurge');
      var maxUnavailable = Ember.get(config, 'maxUnavailable');
      var actualStrategy = Ember.get(config, 'strategy');

      var changes = {};

      if (!actualStrategy) {
        actualStrategy = 'RollingUpdate';
        maxSurge = 1;
        maxUnavailable = null;
      }

      if (actualStrategy === 'RollingUpdate') {
        if (maxSurge && maxUnavailable) {
          changes['_strategy'] = 'custom';
        } else if (maxSurge) {
          changes['_strategy'] = 'startFirst';
          changes['batchSize'] = maxSurge;
        } else if (maxUnavailable) {
          changes['_strategy'] = 'stopFirst';
          changes['batchSize'] = maxUnavailable;
        } else {
          changes['_strategy'] = 'stopFirst';
        }
      }

      if (actualStrategy === 'Recreate') {
        changes['_strategy'] = 'recreate';
      }

      Ember.setProperties(this, changes);
      this.strategyChanged();
    },


    strategyChanged: Ember.observer('_strategy', 'batchSize', function () {
      var _strategy = Ember.get(this, '_strategy');
      var config = Ember.get(this, 'workloadConfig');

      var batchSize = maybeInt(Ember.get(this, 'batchSize'));
      var maxSurge = maybeInt(Ember.get(config, 'maxSurge'));
      var maxUnavailable = maybeInt(Ember.get(config, 'maxUnavailable'));

      if (!maxSurge && !maxUnavailable) {
        maxSurge = 1;
        maxUnavailable = 0;
      }

      if (_strategy === 'startFirst') {
        Ember.setProperties(config, {
          strategy: 'RollingUpdate',
          maxSurge: batchSize,
          maxUnavailable: 0
        });
      } else if (_strategy === 'stopFirst') {
        Ember.setProperties(config, {
          strategy: 'RollingUpdate',
          maxSurge: 0,
          maxUnavailable: batchSize
        });
      } else if (_strategy === 'custom') {
        Ember.setProperties(config, {
          strategy: 'RollingUpdate',
          maxSurge: maxSurge,
          maxUnavailable: maxUnavailable
        });
      } else if (_strategy === 'recreate') {
        Ember.setProperties(config, {
          strategy: 'Recreate',
          maxSurge: null,
          maxUnavailable: null
        });
      }
    })
  });
});