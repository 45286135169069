define('ui/authenticated/project/dns/detail/edit/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    actions: {
      back: function back() {
        this.send('goToPrevious', 'authenticated.project.dns');
      }
    }
  });
});