define('ui/utils/debounce', ['exports', 'shared/utils/debounce'], function (exports, _debounce) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'debouncedObserver', {
    enumerable: true,
    get: function () {
      return _debounce.debouncedObserver;
    }
  });
  Object.defineProperty(exports, 'throttledObserver', {
    enumerable: true,
    get: function () {
      return _debounce.throttledObserver;
    }
  });
});