define('ui/models/projectcatalog', ['exports', 'ui/models/catalog', '@rancher/ember-api-store/utils/denormalize'], function (exports, _catalog, _denormalize) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _catalog.default.extend({
    level: 'project',
    project: (0, _denormalize.reference)('projectId')
  });
});