define('ui/components/volume-source/source-azure-disk/component', ['exports', 'ui/components/volume-source/source-azure-disk/template', 'shared/mixins/volume-source'], function (exports, _template, _volumeSource) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_volumeSource.default, {
    layout: _template.default,
    field: 'azureDisk',

    initValue: {
      readOnly: false,
      cachingMode: 'None',
      kind: 'Shared'
    }
  });
});