define('ui/authenticated/project/secrets/detail/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(params) {
      var all = this.modelFor('authenticated.project.secrets');

      var secret = all.projectSecrets.findBy('id', params.secret_id);

      if (secret) {
        return secret;
      }

      secret = all.namespacedSecrets.findBy('id', params.secret_id);
      if (secret) {
        return secret;
      }

      return Ember.get(this, 'store').find('secret', params.secret_id);
    }
  });
});