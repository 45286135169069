define('ui/authenticated/project/index/route', ['exports', 'ui/utils/constants'], function (exports, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var DEFAULT_ROUTE = 'containers';
  var VALID_ROUTES = [DEFAULT_ROUTE, 'scaling-groups', 'ingresses', 'authenticated.project.dns', 'volumes', 'authenticated.project.pipeline.pipelines'];

  exports.default = Ember.Route.extend({
    redirect: function redirect() {
      var route = this.get('session.' + _constants.default.SESSION.CONTAINER_ROUTE);

      if (!VALID_ROUTES.includes(route)) {
        route = DEFAULT_ROUTE;
      }

      this.replaceWith(route);
    }
  });
});