define('ui/components/modal-feedback/component', ['exports', 'ui/utils/constants', 'ui/utils/load-script', 'shared/mixins/modal-base', 'ui/components/modal-feedback/template'], function (exports, _constants, _loadScript, _modalBase, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_modalBase.default, {
    settings: Ember.inject.service(),
    prefs: Ember.inject.service(),
    access: Ember.inject.service(),

    layout: _template.default,
    classNames: ['span-8', 'offset-2', 'modal-telemetry'],
    loading: true,

    init: function init() {
      this._super.apply(this, arguments);
      var self = this;

      var opt = JSON.parse(this.get('settings.' + _constants.default.SETTING.FEEDBACK_FORM) || '{}');

      Ember.run.scheduleOnce('afterRender', this, function () {
        (0, _loadScript.loadScript)('//js.hsforms.net/forms/v2.js').then(function () {
          window['hbspt'].forms.create({
            css: '',
            portalId: opt.portalId, // '468859',
            formId: opt.formId, // 'bfca2d1d-ed50-4ed7-a582-3f0440f236ca',
            target: '#feedback-form',
            errorClass: 'form-control',
            onFormReady: function onFormReady() {
              self.styleForm();
              $('INPUT[name=rancher_account_id]')[0].value = self.get('access.principal.id'); // eslint-disable-line
              $('INPUT[name=github_username]')[0].value = self.get('access.identity.login'); // eslint-disable-line
              self.set('loading', false);
            },
            onFormSubmit: function onFormSubmit() {
              self.styleForm();
              Ember.run.later(function () {
                self.send('sent');
              }, 1000);
            }
          });
        });
      });
    },


    actions: {
      submit: function submit() {
        var form = $('#feedback-form'); // eslint-disable-line

        form.find('INPUT[type=submit]').click();
      },
      sent: function sent() {
        this.set('prefs.' + _constants.default.PREFS.FEEDBACK, 'sent');
        this.send('cancel');
      }
    },
    styleForm: function styleForm() {
      var self = this;

      var form = $('#feedback-form'); // eslint-disable-line

      form.find('.field').not('.hs_sandbox_acknowledgement').addClass('col-md-6');
      form.find('.field.hs_sandbox_acknowledgement').addClass('span-12');

      form.find('INPUT[type=text],INPUT[type=email],SELECT').addClass('form-control');
      form.find('LABEL').addClass('pt-10');

      form.find('INPUT[type=submit]').addClass('hide');

      form.find('UL').addClass('list-unstyled');
      form.find('INPUT[type=checkbox]').addClass('mr-10');
      form.find('.hs-form-booleancheckbox-display').css('font-weight', 'normal');

      form.find('SELECT').on('change', function () {
        self.styleForm();
      });
    }
  });
});