define('ui/signup/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      return {
        type: 'account',
        kind: 'user',
        name: '',
        email: ''
      };
    },
    activate: function activate() {
      $('BODY').addClass('container-farm'); // eslint-disable-line
    },
    deactivate: function deactivate() {
      $('BODY').removeClass('container-farm'); // eslint-disable-line
    }
  });
});