define('ui/fail-whale/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    storeReset: Ember.inject.service(),
    settings: Ember.inject.service(),

    model: function model() {
      return this.controllerFor('application').get('error');
    },
    afterModel: function afterModel(model) {
      if (model) {
        this.get('storeReset').reset();
      } else {
        this.transitionTo('authenticated');
      }
    },

    actions: {
      activate: function activate() {
        $('BODY').addClass('farm'); // eslint-disable-line
      },
      deactivate: function deactivate() {
        $('BODY').removeClass('farm'); // eslint-disable-line
      }
    }

  });
});