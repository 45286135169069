define('ui/ie/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    bootstrap: function () {
      Ember.run.schedule('afterRender', this, function () {
        Ember.$('#loading-overlay').hide();
        Ember.$('#loading-underlay').hide();
      });
    }.on('init')
  });
});