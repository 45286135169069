define('ui/components/cru-registry/component', ['exports', 'shared/mixins/view-new-edit', 'shared/mixins/optionally-namespaced', 'ui/components/cru-registry/template', 'ui/models/dockercredential'], function (exports, _viewNewEdit, _optionallyNamespaced, _template, _dockercredential) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_viewNewEdit.default, _optionallyNamespaced.default, {
    layout: _template.default,
    model: null,

    titleKey: 'cruRegistry.title',

    scope: 'project',
    namespace: null,
    asArray: null,

    projectType: 'dockerCredential',
    namespacedType: 'namespacedDockerCredential',

    init: function init() {
      this._super.apply(this, arguments);

      Ember.set(this, 'asArray', JSON.parse(JSON.stringify(Ember.get(this, 'model.asArray') || [])));

      if (Ember.get(this, 'model.type') === 'namespacedDockerCredential') {
        Ember.set(this, 'scope', 'namespace');
      }
    },


    arrayChanged: Ember.observer('asArray.@each.{preset,address,username,password,auth}', function () {
      var registries = {};

      Ember.get(this, 'asArray').forEach(function (obj) {
        var preset = Ember.get(obj, 'preset');
        var key = Ember.get(obj, 'address');

        if (_dockercredential.PRESETS_BY_NAME[preset]) {
          key = _dockercredential.PRESETS_BY_NAME[preset];
        }

        var val = {};

        ['username', 'password', 'auth'].forEach(function (k) {
          var v = Ember.get(obj, k);

          if (v) {
            val[k] = v;
          }
        });

        registries[key] = val;
      });

      Ember.set(this, 'model.registries', registries);

      return this._super.apply(this, arguments);
    }),

    willSave: function willSave() {
      var pr = Ember.get(this, 'primaryResource');

      // Namespace is required, but doesn't exist yet... so lie to the validator
      var nsId = Ember.get(pr, 'namespaceId');

      Ember.set(pr, 'namespaceId', '__TEMP__');
      var ok = this.validate();

      Ember.set(pr, 'namespaceId', nsId);

      return ok;
    },
    validate: function validate() {
      this._super();

      var errors = Ember.get(this, 'errors') || [];

      if (Ember.get(this, 'scope') !== 'project') {
        errors.pushObjects(Ember.get(this, 'namespaceErrors') || []);
      }
      Ember.set(this, 'errors', errors);

      return errors.length === 0;
    },
    doSave: function doSave() {
      var _arguments = arguments;

      var self = this;
      var sup = self._super;

      return this.namespacePromise().then(function () {
        return sup.apply(self, _arguments);
      });
    },
    doneSaving: function doneSaving() {
      this.sendAction('cancel');
    }
  });
});