define('ui/models/templateversion', ['exports', '@rancher/ember-api-store/utils/validate', '@rancher/ember-api-store/models/resource', 'shared/utils/evaluate', 'ui/utils/constants', 'js-yaml', 'shared/utils/flat-map'], function (exports, _validate, _resource, _evaluate, _constants, _jsYaml, _flatMap) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  exports.default = _resource.default.extend({
    scope: Ember.inject.service(),
    intl: Ember.inject.service(),

    headers: Ember.computed('project.current.id', function () {
      return _defineProperty({}, _constants.default.HEADER.PROJECT_ID, Ember.get(this, 'scope.currentProject.id'));
    }),

    filesAsArray: Ember.computed('files', function () {
      var obj = Ember.get(this, 'files') || {};
      var out = [];

      Object.keys(obj).forEach(function (key) {
        out.push({
          name: key,
          body: obj[key]
        });
      });

      return out;
    }),

    allQuestions: Ember.computed('questions', function () {
      var out = [];
      var originQuestions = Ember.get(this, 'questions') || [];

      originQuestions.forEach(function (q) {
        out.push(q);
        var subquestions = Ember.get(q, 'subquestions');

        if (subquestions) {
          subquestions.forEach(function (subq) {
            if (Ember.get(subq, 'showIf.length') > 0) {
              subq.showIf = q.variable + '=' + q.showSubquestionIf + '&&' + subq.showIf;
            } else {
              subq.showIf = q.variable + '=' + q.showSubquestionIf;
            }

            if (q.group) {
              subq.group = q.group;
            }
          });
          out.pushObjects(subquestions);
        }
      });

      return out;
    }),

    validationErrors: function validationErrors() {
      var intl = this.intl,
          allQuestions = this.allQuestions,
          valuesYaml = this.valuesYaml;


      var filteredQuestions = allQuestions.filter(function (q) {
        return (0, _evaluate.evaluate)(q, allQuestions);
      });
      var errors = [];
      var parsedYamlAnswers = null;

      if (valuesYaml) {
        try {
          parsedYamlAnswers = _jsYaml.default.safeLoad(valuesYaml);
        } catch (err) {
          return [err];
        }

        if (parsedYamlAnswers) {
          var flatParsed = (0, _flatMap.default)(parsedYamlAnswers);

          Object.keys(flatParsed).forEach(function (fp) {
            var questionMatch = filteredQuestions.findBy('variable', fp);

            if (questionMatch) {
              var answer = flatParsed[fp] || null;

              if (questionMatch.required && questionMatch.type !== 'boolean' && Ember.isEmpty(answer)) {
                errors.push(intl.t('validation.required', { key: questionMatch.label }));
              }

              if (answer) {
                (0, _validate.validateLength)(answer, questionMatch, questionMatch.label, intl, errors);
                (0, _validate.validateChars)(answer, questionMatch, questionMatch.label, intl, errors);
              }
            }
          });
        }
      } else {
        if (filteredQuestions) {
          filteredQuestions.forEach(function (item) {
            if (item.required && item.type !== 'boolean' && !item.answer) {
              errors.push(intl.t('validation.required', { key: item.label }));
            }

            if (item.answer) {
              (0, _validate.validateLength)(item.answer, item, item.label, intl, errors);
              (0, _validate.validateChars)(item.answer, item, item.label, intl, errors);
            }
          });
        }
      }

      if (errors.length > 0) {
        return errors;
      }

      errors = this._super.apply(this, arguments);

      return errors;
    }
  });
});