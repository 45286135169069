define('ui/authenticated/project/registries/index/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    prefs: Ember.inject.service(),
    scope: Ember.inject.service(),

    projectController: Ember.inject.controller('authenticated.project'),
    queryParams: ['sortBy'],
    sortBy: 'name',
    headers: [{
      name: 'state',
      sort: ['sortState', 'name', 'id'],
      type: 'string',
      searchField: 'displayState',
      translationKey: 'generic.state',
      width: 125
    }, {
      name: 'name',
      sort: ['name', 'id'],
      translationKey: 'generic.name'
    }, {
      name: 'namespace',
      translationKey: 'generic.namespace',
      searchField: 'namespace.displayName',
      sort: ['namespace.displayName', 'name', 'id']
    }, {
      name: 'registry',
      translationKey: 'cruRegistry.address.label',
      searchField: ['displayAddress', 'searchAddresses'],
      sort: ['displayAddress', 'name', 'id']
    }, {
      name: 'username',
      translationKey: 'cruRegistry.username.label',
      searchField: ['firstUsername', 'searchUsernames'],
      sort: ['firstUsername', 'name', 'id']
    }],

    group: Ember.computed.alias('projectController.group'),
    groupTableBy: Ember.computed.alias('projectController.groupTableBy'),

    rows: Ember.computed('model.projectDockerCredentials.[]', 'model.namespacedDockerCredentials.[]', function () {
      var proj = Ember.get(this, 'model.projectDockerCredentials').slice();
      var ns = Ember.get(this, 'model.namespacedDockerCredentials').slice();
      var out = proj.concat(ns);

      return out;
    })
  });
});