define('ui/authenticated/cluster/storage/classes/detail/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    clusterStore: Ember.inject.service(),

    model: function model(params) {
      var clusterStore = Ember.get(this, 'clusterStore');
      var storageClassId = params.storage_class_id;

      return Ember.RSVP.hash({
        storageclass: clusterStore.find('storageclass', storageClassId),
        persistentVolumes: clusterStore.findAll('persistentVolume').then(function (data) {
          return (data || []).filterBy('storageClassId', storageClassId);
        })
      });
    }
  });
});