define('ui/logout/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    access: Ember.inject.service(),

    beforeModel: function beforeModel(transition) {
      this._super.apply(this, arguments);
      transition.send('logout');
    }
  });
});