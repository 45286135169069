define('ui/authenticated/cluster/backups/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    scope: Ember.inject.service(),
    globalStore: Ember.inject.service(),
    currentCluster: Ember.computed.alias('scope.currentCluster'),

    model: function model() {
      return this.globalStore.findAll('etcdbackup');
    },
    setupController: function setupController(controller, model) {
      var currentCluster = this.currentCluster;


      var clusterId = Ember.get(currentCluster, 'id');

      Ember.set(controller, 'currentClusterId', clusterId);

      this._super(controller, model);
    }
  });
});