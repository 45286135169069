define('ui/components/accordion-container/component', ['exports', 'ui/components/accordion-container/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    model: null,
    expandOnInit: true,
    sortBy: 'displayState',
    descending: true,
    initExpand: true,
    headers: [{
      name: 'displayState',
      sort: ['displayState'],
      translationKey: 'generic.state',
      width: 120
    }, {
      name: 'name',
      sort: ['name'],
      translationKey: 'generic.name'
    }, {
      name: 'image',
      sort: ['image'],
      translationKey: 'generic.image'
    }, {
      name: 'restarts',
      sort: ['restarts'],
      translationKey: 'generic.restarts',
      width: 100
    }]
  });
});