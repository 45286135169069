define('ui/authenticated/cluster/projects/new-ns/controller', ['exports', 'ui/mixins/new-or-edit', 'ui/utils/constants'], function (exports, _newOrEdit, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_newOrEdit.default, {
    scope: Ember.inject.service(),

    queryParams: ['addTo', 'from'],
    addTo: null,
    from: null,

    primaryResource: Ember.computed.alias('model.namespace'),
    actions: {
      cancel: function cancel() {
        var backTo = Ember.get(this, 'session').get(_constants.default.SESSION.BACK_TO);

        if (backTo) {
          this.transitionToRoute('authenticated.project.ns.index', Ember.get(this, 'addTo'));
        } else {
          this.transitionToRoute('authenticated.cluster.projects.index');
        }
      },
      updateNsQuota: function updateNsQuota(quota) {
        if (quota) {
          Ember.set(this, 'primaryResource.resourceQuota', { limit: quota });
        } else {
          Ember.set(this, 'primaryResource.resourceQuota', null);
        }
      },
      updateContainerDefault: function updateContainerDefault(limit) {
        Ember.set(this, 'primaryResource.containerDefaultResourceLimit', limit);
      }
    },

    projectDidChange: Ember.observer('primaryResource.project.id', function () {
      var _this = this;

      Ember.set(this, 'switchingProject', true);
      Ember.run.next(function () {
        Ember.set(_this, 'switchingProject', false);
      });
      if (!Ember.get(this, 'primaryResource.project.resourceQuota')) {
        Ember.set(this, 'primaryResource.resourceQuota', null);
      }
    }),

    allProjects: Ember.computed('model.allProjects', 'scope.currentCluster.id', function () {
      return Ember.get(this, 'model.allProjects').filterBy('clusterId', Ember.get(this, 'scope.currentCluster.id'));
    }),

    projectLimit: Ember.computed('primaryResource.resourceQuota.{limit}', 'primaryResource.projectId', function () {
      var projectId = Ember.get(this, 'primaryResource.projectId');
      var project = Ember.get(this, 'allProjects').findBy('id', projectId);

      return Ember.get(project, 'resourceQuota.limit');
    }),

    projectUsedLimit: Ember.computed('primaryResource.resourceQuota.{limit}', 'primaryResource.projectId', function () {
      var projectId = Ember.get(this, 'primaryResource.projectId');
      var project = Ember.get(this, 'allProjects').findBy('id', projectId);

      return Ember.get(project, 'resourceQuota.usedLimit');
    }),

    nsDefaultQuota: Ember.computed('primaryResource.resourceQuota.{limit}', 'primaryResource.projectId', function () {
      var projectId = Ember.get(this, 'primaryResource.projectId');
      var project = Ember.get(this, 'allProjects').findBy('id', projectId);

      return Ember.get(project, 'namespaceDefaultResourceQuota.limit');
    }),

    nameExists: Ember.computed('primaryResource.name', 'model.namespaces.@each.name', function () {
      var name = Ember.get(this, 'primaryResource.name');

      if (name) {
        var found = Ember.get(this, 'model.namespaces').findBy('name', name);

        return found && Ember.get(this, 'primaryResource.id') !== Ember.get(found, 'id');
      }

      return false;
    }),

    validate: function validate() {
      this._super();

      var errors = Ember.get(this, 'errors') || [];
      var quotaErrors = Ember.get(this, 'primaryResource').validateResourceQuota();

      if (quotaErrors.length > 0) {
        errors.pushObjects(quotaErrors);
      }

      Ember.set(this, 'errors', errors);

      return Ember.get(this, 'errors.length') === 0;
    },
    doneSaving: function doneSaving() {
      this.send('cancel');
    }
  });
});