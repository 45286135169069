define('ui/components/modal-edit-password/component', ['exports', 'shared/mixins/modal-base', 'ui/components/modal-edit-password/template'], function (exports, _modalBase, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_modalBase.default, {
    router: Ember.inject.service(),
    settings: Ember.inject.service(),
    layout: _template.default,
    classNames: ['medium-modal'],
    showHelp: false,
    user: Ember.computed.alias('modalOpts.user'),
    actions: {
      complete: function complete(success) {
        if (success) {
          // get(this, 'router').replaceWith('authenticated');
          Ember.get(this, 'modalService').toggleModal();
        }
      },
      cancel: function cancel() {
        Ember.get(this, 'modalService').toggleModal();
      },
      goBack: function goBack() {
        Ember.get(this, 'modalService').toggleModal();
      }
    }
  });
});