define('ui/components/container/form-upgrade/component', ['exports', 'ui/components/container/form-upgrade/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    workload: null,
    scaleMode: null,
    editing: null,
    isUpgrade: null,

    classNames: ['accordion-wrapper'],

    didReceiveAttrs: function didReceiveAttrs() {
      if (!this.get('expandFn')) {
        this.set('expandFn', function (item) {
          item.toggleProperty('expanded');
        });
      }
    },


    workloadConfig: Ember.computed('scaleMode', function () {
      var scaleMode = Ember.get(this, 'scaleMode');
      var config = Ember.get(this, 'workload.' + scaleMode + 'Config');

      return config;
    }),

    componentName: Ember.computed('scaleMode', function () {
      return 'container/form-upgrade-' + Ember.get(this, 'scaleMode').dasherize();
    })
  });
});