define('ui/components/scheduling-toleration/component', ['exports', 'ui/components/scheduling-toleration/template', 'ui/utils/constants'], function (exports, _template, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,

    tolerate: null,
    title: null,
    tolerationArray: null,
    init: function init() {
      this._super.apply(this, arguments);
      this.initTolerationArray();
    },


    actions: {
      addToleration: function addToleration() {
        this.get('tolerationArray').pushObject({
          key: '',
          operator: '',
          value: '',
          effct: '',
          tolerationSeconds: ''
        });
      },
      removeToleration: function removeToleration(rule) {
        this.get('tolerationArray').removeObject(rule);
      }
    },

    inputChanged: Ember.observer('tolerationArray.@each.{key,value,operator,effct,tolerationSeconds}', function () {
      var _this = this;

      this.set('tolerate', this.get('tolerationArray').filter(function (t) {
        return _this.isTolerationValid(t);
      }).map(function (t) {
        return _this.convertToleration(t);
      }));
    }),

    initTolerationArray: function initTolerationArray() {
      var tolerate = this.get('tolerate') || [];

      this.set('tolerationArray', tolerate);
    },
    isTolerationValid: function isTolerationValid(toleration) {
      if (toleration.operator === 'Equal') {
        return toleration.key && toleration.value;
      } else if (toleration.operator === 'Exists') {
        return toleration.key;
      } else {
        return toleration.effct;
      }
    },
    convertToleration: function convertToleration(toleration) {
      var result = {};

      Object.keys(toleration).forEach(function (key) {
        if (toleration[key]) {
          result[key] = toleration[key];
        }
      });
      if (result.tolerationSeconds) {
        result.tolerationSeconds = parseInt(result.tolerationSeconds, 10);
      }
      if (result.operator === 'Exists') {
        delete result['value'];
      }

      return result;
    },

    operatorChoices: _constants.default.SCHED_TOLERATION_OPERATOR,
    effectChoices: _constants.default.SCHED_TOLERATION_EFFECT

  });
});