define('ui/initializers/extend-ember-route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize() /* application */{
    Ember.Route.reopen({
      beforeModel: function beforeModel() {
        this._super.apply(this, arguments);
        this.rememberPrevious();
      },
      rememberPrevious: function rememberPrevious() {
        // var appRoute = getOwner(this).lookup('route:application');
        var appRoute = window.l('route:application'); // The global one, not the per-engine application
        var infos = this._router._routerMicrolib.currentHandlerInfos;

        if (infos && infos.length) {
          var params = [];
          var info;

          for (var i = 0; i < infos.length; i++) {
            info = infos[i];
            if (info._names && info._names.length) {
              for (var j = 0; j < info._names.length; j++) {
                params.push(info.params[info._names[j]]);
              }
            }
          }

          if (!info || !info.name.match(/\.?loading$/)) {
            appRoute.set('previousRoute', info.name);
            appRoute.set('previousParams', params);
            // console.log('Set previous route to', info.name, params);
          }
        }
      },
      goToPrevious: function goToPrevious(def) {
        var _this = this;

        // var appRoute = getOwner(this).lookup('route:application');
        var appRoute = window.l('route:application'); // The global one, not the per-engine application

        var route = appRoute.get('previousRoute');

        if (route && route !== 'loading') {
          var args = (appRoute.get('previousParams') || []).slice();

          args.unshift(route);

          this.transitionTo.apply(this, args).catch(function () {
            _this.transitionTo('authenticated');
          });
        } else if (def) {
          this.transitionTo(def);
        } else {
          this.goToParent();
        }
      },
      goToParent: function goToParent() {
        var _this2 = this;

        var infos = this._router._routerMicrolib.currentHandlerInfos;

        var args = [];
        var info;
        var max = infos.length - 1;

        if (infos[infos.length - 1].name === infos[infos.length - 2].name + '.index') {
          max--;
        }

        for (var i = 0; i < max; i++) {
          info = infos[i];

          if (info._names && info._names.length) {
            for (var j = 0; j < info._names.length; j++) {
              args.push(info.params[info._names[j]]);
            }
          }
        }

        args.unshift(info.name);
        this.transitionTo.apply(this, args).catch(function () {
          _this2.transitionTo('authenticated');
        });
      }
    });
  }

  exports.default = {
    name: 'extend-ember-route',
    initialize: initialize
  };
});