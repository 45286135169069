define('ui/components/pod-dots/component', ['exports', 'ember-cli-pagination/computed/paged-array', 'shared/components/sortable-table/component', 'ui/components/pod-dots/template'], function (exports, _pagedArray, _component, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.searchFields = undefined;
  var searchFields = exports.searchFields = ['displayName', 'id:prefix', 'displayState', 'image', 'displayIp:ip'];

  exports.default = Ember.Component.extend({
    layout: _template.default,
    pod: null,
    pods: null,
    searchText: null,
    groupByPod: false,
    paging: true,
    sort: ['sortStr'],
    searchFields: searchFields,

    tagName: '',

    page: 1,
    perPage: 120,
    pageCountChanged: Ember.observer('indexFrom', 'filtered.length', function () {
      // Go to the last page if we end up past the last page
      var from = this.get('indexFrom');
      var last = this.get('filtered.length');
      var perPage = this.get('perPage');

      if (this.get('page') > 1 && from > last) {
        var page = Math.ceil(last / perPage);

        this.set('page', page);
      }
    }),
    // Ignore the setting because these are tiny dots.

    filtered: Ember.computed('pods.[]', 'pod', 'searchText', function () {
      var out = [];
      var pod = this.get('pod');
      var pods = this.get('pods');

      if (pods) {
        out.pushObjects(pods.slice());
      }

      if (pod) {
        out.pushObject(pod);
      }

      var searchFields = this.get('searchFields');
      var searchText = (this.get('searchText') || '').trim().toLowerCase();

      if (searchText.length) {
        var searchTokens = searchText.split(/\s*[, ]\s*/);

        for (var i = out.length - 1; i >= 0; i--) {
          var row = out[i].containers[0];

          for (var j = 0; j < searchTokens.length; j++) {
            var expect = true;
            var token = searchTokens[j];

            if (token.substr(0, 1) === '!') {
              expect = false;
              token = token.substr(1);
            }

            if (token && (0, _component.matches)(searchFields, token, row) !== expect) {
              out.removeAt(i);
              break;
            }
          }
        }
      }

      return out;
    }),

    pagedContent: (0, _pagedArray.default)('filtered', {
      page: Ember.computed.alias('parent.page'),
      perPage: Ember.computed.alias('parent.perPage')
    }),

    indexFrom: Ember.computed('page', 'perPage', function () {
      var current = this.get('page');
      var perPage = this.get('perPage');

      return Math.max(0, 1 + perPage * (current - 1));
    }),

    indexTo: Ember.computed('indexFrom', 'perPage', 'filtered.length', function () {
      return Math.min(this.get('filtered.length'), this.get('indexFrom') + this.get('perPage') - 1);
    })

  });
});