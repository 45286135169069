define('ui/authenticated/cluster/storage/index/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    redirect: function redirect() {
      this.transitionTo('authenticated.cluster.storage.persistent-volumes.index');
    }
  });
});