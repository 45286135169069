define('ui/components/form-endpoints/component', ['exports', 'ui/components/form-endpoints/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    tagName: '',
    model: null,
    expandAll: null,
    expandFn: null,
    sortBy: 'linkEndpoint',
    descending: true,
    headers: [{
      name: 'linkEndpoint',
      sort: ['linkEndpoint'],
      translationKey: 'appDetailPage.endpoints.endpoint',
      searchField: 'linkEndpoint'
    }, {
      name: 'protocol',
      sort: ['linkEndpoint', 'protocol'],
      translationKey: 'appDetailPage.endpoints.protocol',
      searchField: 'protocol'
    }]
  });
});