define('ui/mixins/sticky-table-header', ['exports', 'shared/mixins/sticky-table-header'], function (exports, _stickyTableHeader) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _stickyTableHeader.default;
    }
  });
});