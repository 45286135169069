define('ui/pod/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    beforeModel: function beforeModel() {
      if (window.ShellQuote) {
        return;
      } else {
        return emberAutoImportDynamic('shell-quote').then(function (module) {
          window.ShellQuote = module.default;

          return module.default;
        });
      }
    },
    model: function model(params) {
      var pod = Ember.get(this, 'store').find('pod', params.pod_id);

      if (!pod) {
        this.replaceWith('authenticated.project.index');
      }

      return pod;
    }
  });
});