define('ui/initializers/extend-resource', ['exports', '@rancher/ember-api-store/models/resource', 'ui/mixins/cattle-transitioning-resource'], function (exports, _resource, _cattleTransitioningResource) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize() /* application */{
    _resource.default.reopen(_cattleTransitioningResource.default);
    _resource.default.reopenClass({
      defaultStateIcon: 'icon icon-help',
      defaultStateColor: 'text-primary',
      defaultSortBy: 'name'
    });
  }

  exports.default = {
    name: 'extend-resource',
    initialize: initialize
  };
});