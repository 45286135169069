define('ui/models/cluster', ['exports', '@rancher/ember-api-store/models/resource', '@rancher/ember-api-store/utils/denormalize', 'shared/mixins/resource-usage', 'shared/mixins/grafana', 'ui/utils/constants', 'moment'], function (exports, _resource, _denormalize, _resourceUsage, _grafana, _constants, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _resource.default.extend(_grafana.default, _resourceUsage.default, {
    globalStore: Ember.inject.service(),
    growl: Ember.inject.service(),
    intl: Ember.inject.service(),
    router: Ember.inject.service(),
    scope: Ember.inject.service(),

    clusterRoleTemplateBindings: (0, _denormalize.hasMany)('id', 'clusterRoleTemplateBinding', 'clusterId'),
    etcdbackups: (0, _denormalize.hasMany)('id', 'etcdbackup', 'clusterId'),
    namespaces: (0, _denormalize.hasMany)('id', 'namespace', 'clusterId'),
    nodePools: (0, _denormalize.hasMany)('id', 'nodePool', 'clusterId'),
    nodes: (0, _denormalize.hasMany)('id', 'node', 'clusterId'),
    projects: (0, _denormalize.hasMany)('id', 'project', 'clusterId'),
    expiringCerts: null,
    grafanaDashboardName: 'Cluster',
    isMonitoringReady: false,
    machines: Ember.computed.alias('nodes'),
    roleTemplateBindings: Ember.computed.alias('clusterRoleTemplateBindings'),
    isAKS: Ember.computed.equal('driver', 'azureKubernetesService'),
    isGKE: Ember.computed.equal('driver', 'googleKubernetesEngine'),

    conditionsDidChange: Ember.on('init', Ember.observer('enableClusterMonitoring', 'conditions.@each.status', function () {
      if (!Ember.get(this, 'enableClusterMonitoring')) {
        return false;
      }
      var conditions = Ember.get(this, 'conditions') || [];

      var ready = conditions.findBy('type', 'MonitoringEnabled');

      var status = ready && Ember.get(ready, 'status') === 'True';

      if (status !== Ember.get(this, 'isMonitoringReady')) {
        Ember.set(this, 'isMonitoringReady', status);
      }
    })),

    getAltActionDelete: Ember.computed('action.remove', function () {
      // eslint-disable-line
      return Ember.get(this, 'canBulkRemove') ? 'delete' : null;
    }),

    hasSessionToken: Ember.computed('annotations', function () {
      var sessionTokenLabel = '' + (Ember.get(this, 'annotations') || {})[_constants.default.LABEL.EKS_SESSION_TOKEN];
      var hasSessionToken = false;

      if (sessionTokenLabel === 'undefined' || sessionTokenLabel === 'false') {
        hasSessionToken = false;
      } else {
        hasSessionToken = true;
      }

      return hasSessionToken;
    }),

    canBulkRemove: Ember.computed('action.remove', function () {
      // eslint-disable-line
      return Ember.get(this, 'hasSessionToken') ? false : true;
    }),

    configName: Ember.computed(function () {
      var keys = this.allKeys().filter(function (x) {
        return x.endsWith('Config');
      });

      for (var key, i = 0; i < keys.length; i++) {
        key = keys[i];
        if (Ember.get(this, key)) {
          return key;
        }
      }

      return null;
    }),

    isReady: Ember.computed('conditions.@each.status', function () {
      return this.hasCondition('Ready');
    }),

    isRKE: Ember.computed('configName', function () {
      return Ember.get(this, 'configName') === 'rancherKubernetesEngineConfig';
    }),

    provider: Ember.computed('configName', 'nodePools.@each.nodeTemplateId', 'driver', function () {
      var pools = Ember.get(this, 'nodePools') || [];
      var firstTemplate = Ember.get(pools, 'firstObject.nodeTemplate');

      switch (Ember.get(this, 'configName')) {
        case 'amazonElasticContainerServiceConfig':
          return 'amazoneks';
        case 'azureKubernetesServiceConfig':
          return 'azureaks';
        case 'googleKubernetesEngineConfig':
          return 'googlegke';
        case 'tencentEngineConfig':
          return 'tencenttke';
        case 'aliyunEngineConfig':
          return 'aliyunkcs';
        case 'huaweiEngineConfig':
          return 'huaweicce';
        case 'rancherKubernetesEngineConfig':
          if (pools.length > 0) {
            if (firstTemplate) {
              return Ember.get(firstTemplate, 'driver');
            } else {
              return null;
            }
          } else {
            return 'custom';
          }
        default:
          if (Ember.get(this, 'driver') && Ember.get(this, 'configName')) {
            return Ember.get(this, 'driver');
          } else {
            return 'import';
          }
      }
    }),

    displayProvider: Ember.computed('configName', 'nodePools.@each.displayProvider', 'intl.locale', 'driver', function () {
      var intl = Ember.get(this, 'intl');
      var pools = Ember.get(this, 'nodePools');
      var firstPool = (pools || []).objectAt(0);

      switch (Ember.get(this, 'configName')) {
        case 'amazonElasticContainerServiceConfig':
          return intl.t('clusterNew.amazoneks.shortLabel');
        case 'azureKubernetesServiceConfig':
          return intl.t('clusterNew.azureaks.shortLabel');
        case 'googleKubernetesEngineConfig':
          return intl.t('clusterNew.googlegke.shortLabel');
        case 'tencentEngineConfig':
          return intl.t('clusterNew.tencenttke.shortLabel');
        case 'aliyunEngineConfig':
          return intl.t('clusterNew.aliyunkcs.shortLabel');
        case 'huaweiEngineConfig':
          return intl.t('clusterNew.huaweicce.shortLabel');
        case 'rancherKubernetesEngineConfig':
          if (!!pools) {
            if (firstPool) {
              return Ember.get(firstPool, 'displayProvider') ? Ember.get(firstPool, 'displayProvider') : intl.t('clusterNew.rke.shortLabel');
            } else {
              return intl.t('clusterNew.rke.shortLabel');
            }
          } else {
            return intl.t('clusterNew.custom.shortLabel');
          }
        default:
          if (Ember.get(this, 'driver') && Ember.get(this, 'configName')) {
            return Ember.get(this, 'driver').capitalize();
          } else {
            return intl.t('clusterNew.import.shortLabel');
          }
      }
    }),

    systemProject: Ember.computed('projects.@each.isSystemProject', function () {
      var projects = (Ember.get(this, 'projects') || []).filterBy('isSystemProject', true);

      return Ember.get(projects, 'firstObject');
    }),

    defaultProject: Ember.computed('projects.@each.{name,clusterOwner}', function () {
      var projects = Ember.get(this, 'projects');

      var out = projects.findBy('isDefault');

      if (out) {
        return out;
      }

      out = projects.findBy('clusterOwner', true);
      if (out) {
        return out;
      }

      out = projects.objectAt(0);

      return out;
    }),

    certsExpiring: Ember.computed('certificatesExpiration', function () {
      var _certificatesExpirati = this.certificatesExpiration,
          certificatesExpiration = _certificatesExpirati === undefined ? {} : _certificatesExpirati,
          expiringCerts = this.expiringCerts;


      if (!expiringCerts) {
        expiringCerts = [];
      }

      if (!Ember.isEmpty(certificatesExpiration)) {
        var expKeys = Object.keys(certificatesExpiration);

        expKeys.forEach(function (kee) {
          var certDate = Ember.get(certificatesExpiration[kee], 'expirationDate');
          var expirey = (0, _moment.default)(certDate);
          var diff = expirey.diff((0, _moment.default)());

          if (diff < 2592000000) {
            // milliseconds in a month
            expiringCerts.pushObject({
              expiringCertName: kee,
              milliUntil: diff,
              exactDateTime: certDate
            });
          }
        });

        Ember.set(this, 'expiringCerts', expiringCerts);

        return expiringCerts.length > 0;
      }

      return false;
    }),

    availableActions: Ember.computed('actionLinks.{rotateCertificates}', function () {
      var a = Ember.get(this, 'actionLinks') || {};

      return [{
        label: 'action.rotate',
        icon: 'icon icon-history',
        action: 'rotateCertificates',
        enabled: !!a.rotateCertificates
      }, {
        label: 'action.backupEtcd',
        icon: 'icon icon-history',
        action: 'backupEtcd',
        enabled: !!a.backupEtcd
      }, {
        label: 'action.restoreFromEtcdBackup',
        icon: 'icon icon-history',
        action: 'restoreFromEtcdBackup',
        enabled: !!a.restoreFromEtcdBackup
      }];
    }),

    actions: {
      backupEtcd: function backupEtcd() {
        var _this = this;

        var getBackupType = function getBackupType() {
          var services = Ember.get(_this, 'rancherKubernetesEngineConfig.services.etcd');

          if (Ember.get(services, 'backupConfig')) {
            if (Ember.isEmpty(services.backupConfig.s3BackupConfig)) {
              return 'local';
            } else if (!Ember.isEmpty(services.backupConfig.s3BackupConfig)) {
              return 's3';
            }
          }
        };

        var backupType = getBackupType();
        var successTitle = this.intl.t('action.backupEtcdMessage.success.title');
        var successMessage = this.intl.t('action.backupEtcdMessage.success.message', {
          clusterId: this.displayName || this.id,
          backupType: backupType
        });

        this.doAction('backupEtcd').then(function () {
          return _this.growl.success(successTitle, successMessage);
        }).catch(function (err) {
          return _this.growl.fromError(err);
        });
      },
      restoreFromEtcdBackup: function restoreFromEtcdBackup() {
        Ember.get(this, 'modalService').toggleModal('modal-restore-backup', { cluster: this });
      },
      promptDelete: function promptDelete() {
        var hasSessionToken = Ember.get(this, 'canBulkRemove') ? false : true; // canBulkRemove returns true of the session token is set false

        if (hasSessionToken) {
          Ember.set(this, Ember.get(this, 'configName') + '.accessKey', null);
          Ember.get(this, 'modalService').toggleModal('modal-delete-eks-cluster', { model: this });
        } else {
          Ember.get(this, 'modalService').toggleModal('confirm-delete', {
            escToClose: true,
            resources: [this]
          });
        }
      },
      edit: function edit() {
        var provider = Ember.get(this, 'provider') || Ember.get(this, 'driver');

        Ember.get(this, 'router').transitionTo('authenticated.cluster.edit', Ember.get(this, 'id'), { queryParams: { provider: provider } });
      },
      scaleDownPool: function scaleDownPool(id) {
        var pool = (Ember.get(this, 'nodePools') || []).findBy('id', id);

        if (pool) {
          pool.incrementQuantity(-1);
        }
      },
      scaleUpPool: function scaleUpPool(id) {
        var pool = (Ember.get(this, 'nodePools') || []).findBy('id', id);

        if (pool) {
          pool.incrementQuantity(1);
        }
      },
      rotateCertificates: function rotateCertificates() {
        var model = this;

        Ember.get(this, 'modalService').toggleModal('modal-rotate-certificates', {
          model: model,
          serviceDefaults: Ember.get(this, 'globalStore').getById('schema', 'rotatecertificateinput').optionsFor('services')
        });
      }
    },

    clearProvidersExcept: function clearProvidersExcept(keep) {
      var keys = this.allKeys().filter(function (x) {
        return x.endsWith('Config');
      });

      for (var key, i = 0; i < keys.length; i++) {
        key = keys[i];
        if (key !== keep && Ember.get(this, key)) {
          Ember.set(this, key, null);
        }
      }
    },
    delete: function _delete() /* arguments*/{
      var _this2 = this;

      var promise = this._super.apply(this, arguments);

      return promise.then(function () /* resp */{
        if (Ember.get(_this2, 'scope.currentCluster.id') === Ember.get(_this2, 'id')) {
          Ember.get(_this2, 'router').transitionTo('global-admin.clusters');
        }
      });
    },
    getOrCreateToken: function getOrCreateToken() {
      var _this3 = this;

      var globalStore = Ember.get(this, 'globalStore');
      var id = Ember.get(this, 'id');

      return globalStore.findAll('clusterRegistrationToken', { forceReload: true }).then(function (tokens) {
        var token = tokens.filterBy('clusterId', id)[0];

        if (token) {
          return Ember.RSVP.resolve(token);
        } else {
          token = Ember.get(_this3, 'globalStore').createRecord({
            type: 'clusterRegistrationToken',
            clusterId: id
          });

          return token.save();
        }
      });
    }
  });
});