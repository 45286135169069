define('ui/components/modal-shortcuts/component', ['exports', 'shared/mixins/modal-base', 'ui/utils/constants', 'ui/components/modal-shortcuts/template'], function (exports, _modalBase, _constants, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var DEFAULT_TIME = 400;

  exports.default = Ember.Component.extend(_modalBase.default, {
    prefs: Ember.inject.service(),
    settings: Ember.inject.service(),
    access: Ember.inject.service(),

    layout: _template.default,
    classNames: ['generic', 'medium-modal', 'p-0'],
    time: DEFAULT_TIME,
    timer: null,

    isAdmin: Ember.computed.alias('access.admin'),

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      this.set('pods', this.get('store').all('pod'));

      this.set('timer', setInterval(function () {
        _this.updateTime();
      }, 1000));
    },
    willDestroyElement: function willDestroyElement() {
      clearInterval(this.get('timer'));
    },

    containerCount: function () {
      var count = this.get('pods.length');

      if (count > 9) {
        return count;
      } else {
        return '0' + count;
      }
    }.property('pods.length'),

    currentTheme: Ember.computed('prefs.' + _constants.default.PREFS.THEME, function () {
      return this.get('prefs.' + _constants.default.PREFS.THEME);
    }),

    updateTime: function updateTime() {
      var time = this.get('time');

      if (time > 0) {
        time--;
      } else {
        time = DEFAULT_TIME;
      }

      this.set('time', time);
    }
  });
});