define('ui/models/globalrole', ['exports', '@rancher/ember-api-store/models/resource'], function (exports, _resource) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var BASE = 'user-base';
  var USER = 'user';
  var ADMIN = 'admin';
  var SPECIAL = [BASE, ADMIN, USER];

  exports.default = _resource.default.extend({

    intl: Ember.inject.service(),
    router: Ember.inject.service(),

    canRemove: false,
    // I think its safe to hack around this - wjw
    _displayState: 'active',
    // because of this the state shows as "Unknown" with bright yellow background
    stateColor: 'text-success',

    isHidden: Ember.computed('id', function () {
      return SPECIAL.includes(Ember.get(this, 'id'));
    }),

    isBase: Ember.computed('id', function () {
      return Ember.get(this, 'id') === BASE;
    }),

    isUser: Ember.computed('id', function () {
      return Ember.get(this, 'id') === USER;
    }),

    isAdmin: Ember.computed('id', function () {
      return Ember.get(this, 'id') === ADMIN;
    }),

    isCustom: Ember.computed('isAdmin', 'isUser', 'isBase', function () {
      return !Ember.get(this, 'isAdmin') && !Ember.get(this, 'isBase') && !Ember.get(this, 'isUser');
    }),

    displayName: Ember.computed('id', 'name', 'intl.locale', function () {
      var intl = Ember.get(this, 'intl');
      var id = Ember.get(this, 'id');
      var key = 'formGlobalRoles.role.' + id + '.label';

      if (intl.exists(key)) {
        return intl.t(key);
      }

      var name = Ember.get(this, 'name');

      if (name) {
        return name;
      }

      return '(' + id + ')';
    }),

    detail: Ember.computed('name', 'intl.locale', function () {
      var intl = Ember.get(this, 'intl');
      var id = Ember.get(this, 'id');
      var key = 'formGlobalRoles.role.' + id + '.detail';

      if (intl.exists(key)) {
        return intl.t(key);
      }

      return '';
    }),

    // globalRoles can not be removed or changed as of now and do not have a state
    actions: {
      edit: function edit() {
        this.get('router').transitionTo('global-admin.security.roles.edit', this.get('id'), { queryParams: { type: 'global' } });
      }
    }
  });
});