define('ui/utils/used-percent-gauge', ['exports', 'shared/utils/used-percent-gauge'], function (exports, _usedPercentGauge) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _usedPercentGauge.default;
    }
  });
});