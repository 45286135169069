define('ui/authenticated/index/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    scope: Ember.inject.service(),

    redirect: function redirect() {
      // @TODO-2.0 go to appropriate place based on permissions
      this.replaceWith('global-admin.clusters');
    }
  });
});