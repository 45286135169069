define('ui/instance-initializers/global-store', ['exports', 'ui/mixins/store-tweaks'], function (exports, _storeTweaks) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(instance) {
    var application = instance.lookup('application:main');
    var store = instance.lookup('service:globalStore');
    var cookies = instance.lookup('service:cookies');

    store.reopen(_storeTweaks.default);
    store.baseUrl = application.apiEndpoint;

    var timeout = cookies.get('timeout');

    if (timeout) {
      store.defaultTimeout = timeout;
    }
  }

  exports.default = {
    name: 'global-store',
    initialize: initialize
  };
});