define('ui/mixins/upgrade-component', ['exports', 'shared/mixins/upgrade-component'], function (exports, _upgradeComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _upgradeComponent.default;
    }
  });
});