define('ui/components/settings/server-url/component', ['exports', 'ui/components/settings/server-url/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var SCHEME = 'https://';

  exports.default = Ember.Component.extend({
    router: Ember.inject.service(),

    layout: _template.default,

    cancel: null,
    popupMode: false,
    initServerUrl: null,
    serverUrl: null,
    serverUrlSetting: null,
    setServerUrl: false,
    showHeader: true,
    urlInvalid: false,
    urlWarning: null,
    scheme: SCHEME,

    init: function init() {
      this._super.apply(this, arguments);
      var initServerUrl = Ember.get(this, 'initServerUrl');

      if (Ember.isEmpty(initServerUrl)) {
        Ember.set(this, 'serverUrl', window.location.host);
      } else {
        Ember.set(this, 'serverUrl', initServerUrl);
      }
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      Ember.run.next(function () {
        if (_this.isDestroyed || _this.isDestroying) {
          return;
        }

        var elem = _this.$('INPUT')[0];

        if (elem) {
          elem.focus();
        }
      });
    },


    actions: {
      saveServerUrl: function saveServerUrl() {
        var _this2 = this;

        var setting = Ember.get(this, 'serverUrlSetting');

        Ember.set(setting, 'value', '' + SCHEME + Ember.get(this, 'serverUrl'));
        setting.save().then(function () {
          if (!Ember.get(_this2, 'popupMode')) {
            Ember.get(_this2, 'router').replaceWith('authenticated');
          } else {
            _this2.send('cancel');
          }
        });
      },
      cancel: function cancel() {
        this.sendAction('cancel');
      }
    }
  });
});