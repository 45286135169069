define('ui/volumes/detail/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(params) {
      var store = Ember.get(this, 'store');
      var clusterStore = Ember.get(this, 'clusterStore');

      return Ember.RSVP.hash({
        persistentVolumes: clusterStore.findAll('persistentVolume'),
        storageClasses: clusterStore.findAll('storageClass'),
        pvc: store.find('persistentVolumeClaim', params.volume_id)
      });
    }
  });
});