define('ui/models/nodepool', ['exports', '@rancher/ember-api-store/models/resource', '@rancher/ember-api-store/utils/denormalize'], function (exports, _resource, _denormalize) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var NodePool = _resource.default.extend({
    type: 'nodePool',
    quantityTimer: null,
    nodeTemplate: (0, _denormalize.reference)('nodeTemplateId'),

    displayProvider: Ember.computed.alias('nodeTemplate.displayProvider'),

    incrementQuantity: function incrementQuantity(by) {
      var quantity = Ember.get(this, 'quantity');

      quantity += by;
      quantity = Math.max(0, quantity);

      Ember.set(this, 'quantity', quantity);

      if (Ember.get(this, 'quantityTimer')) {
        Ember.run.cancel(Ember.get(this, 'quantityTimer'));
      }

      var timer = Ember.run.later(this, function () {
        var _this = this;

        this.save().catch(function (err) {
          Ember.get(_this, 'growl').fromError('Error updating node pool scale', err);
        });
      }, 500);

      Ember.set(this, 'quantityTimer', timer);
    }
  });

  NodePool.reopenClass({
    mangleOut: function mangleOut(data) {
      if (data && data.hostnamePrefix) {
        data.hostnamePrefix = data.hostnamePrefix.toLowerCase();
      }

      return data;
    }
  });

  exports.default = NodePool;
});