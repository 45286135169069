define('ui/components/pod-metrics/component', ['exports', 'shared/mixins/metrics', 'ui/components/pod-metrics/template'], function (exports, _metrics, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_metrics.default, {
    layout: _template.default,

    filters: { resourceType: 'pod' },

    projectScope: true,

    init: function init() {
      this._super.apply(this, arguments);
      Ember.set(this, 'metricParams', { podName: Ember.get(this, 'resourceId') });
    }
  });
});