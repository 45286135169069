define('ui/authenticated/cluster/projects/new/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    globalStore: Ember.inject.service(),
    scope: Ember.inject.service(),
    access: Ember.inject.service(),
    roleTemplateService: Ember.inject.service('roleTemplate'),

    model: function model() {
      var store = Ember.get(this, 'globalStore');
      var cluster = this.modelFor('authenticated.cluster');

      var project = store.createRecord({
        type: 'project',
        name: '',
        clusterId: Ember.get(cluster, 'id')
      });

      return Ember.RSVP.hash({
        me: Ember.get(this, 'access.principal'),
        project: project,
        projects: store.findAll('project'),
        psps: store.findAll('podSecurityPolicyTemplate'),
        roles: Ember.get(this, 'roleTemplateService').get('allFilteredRoleTemplates'),
        users: store.find('user', null, { forceReload: true })
      });
    }
  });
});