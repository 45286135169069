define('ui/models/globaldns', ['exports', '@rancher/ember-api-store/models/resource', '@rancher/ember-api-store/utils/denormalize'], function (exports, _resource, _denormalize) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _resource.default.extend({
    router: Ember.inject.service(),
    scope: Ember.inject.service(),

    multiClusterApp: (0, _denormalize.reference)('multiClusterAppId', 'multiClusterApp'),

    target: Ember.computed('multiClusterAppId', 'projectIds.[]', function () {
      // returns either a single multiClusterAppId or an array of project target ids
      var out = {
        type: null,
        data: null
      };

      var multiClusterAppId = Ember.get(this, 'multiClusterAppId');
      var projectIds = Ember.get(this, 'projectIds');

      if (multiClusterAppId && !projectIds) {
        Ember.setProperties(out, {
          type: 'single',
          data: multiClusterAppId
        });
      } else if (projectIds && projectIds.length && !multiClusterAppId) {
        Ember.setProperties(out, {
          type: 'multi',
          data: projectIds
        });
      }

      return out;
    }),

    linkedProjects: Ember.computed('projectIds.[]', 'scope.allProjects.@each.{id}', function () {
      var allProjects = Ember.get(this, 'scope.allProjects') || [];
      var projectIds = Ember.get(this, 'projectIds') || [];

      var myProjects = [];

      projectIds.forEach(function (projectId) {
        var match = allProjects.findBy('id', projectId);

        if (match) {
          Ember.run.next(function () {
            Ember.set(match, 'accessible', true);
            myProjects.pushObject(match);
          });
        } else {
          Ember.run.next(function () {
            myProjects.pushObject({
              id: projectId,
              accessible: false
            });
          });
        }
      });

      return myProjects;
    }),

    canEdit: Ember.computed('links.update', function () {
      return !!Ember.get(this, 'links.update');
    }),

    canRemove: Ember.computed('links.remove', function () {
      return !!Ember.get(this, 'links.remove');
    }),

    actions: {
      edit: function edit() {
        this.router.transitionTo('global-admin.global-dns.entries.new', { queryParams: { id: this.id } });
      }
    }

  });
});