define('ui/models/token', ['exports', '@rancher/ember-api-store/models/resource'], function (exports, _resource) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _resource.default.extend({
    growl: Ember.inject.service(),

    state: Ember.computed('expired', function () {
      if (Ember.get(this, 'expired')) {
        return 'expired';
      }

      return 'active';
    }),

    availableActions: Ember.computed('enabled', function () {
      // eslint-disable-line

      return [{
        label: 'action.activate',
        icon: 'icon icon-play',
        action: 'activate',
        // enabled: !this.enabled,
        enabled: false, // backend was supposed to be ready but its not ready, when it is flip this switch and bingo bango yor're off to the races
        bulkable: false
      }, {
        label: 'action.deactivate',
        icon: 'icon icon-pause',
        action: 'deactivate',
        // enabled: this.enabled,
        enabled: false, // backend was supposed to be ready but its not ready, when it is flip this switch and bingo bango yor're off to the races
        bulkable: false
      }];
    }),

    actions: {
      deactivate: function deactivate() {
        var _this = this;

        Ember.run.next(function () {
          Ember.set(_this, 'enabled', false);
          _this.save().catch(function (err) {
            Ember.set(_this, 'enabled', true);
            Ember.get(_this, 'growl').fromError('Error deactivating token', err);
          });
        });
      },
      activate: function activate() {
        var _this2 = this;

        Ember.run.next(function () {
          Ember.set(_this2, 'enabled', true);
          _this2.save().catch(function (err) {
            Ember.set(_this2, 'enabled', false);
            Ember.get(_this2, 'growl').fromError('Error activating token', err);
          });
        });
      }
    }
  });
});