define('ui/initializers/extend-ember-link', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize() /* application */{
    Ember.LinkComponent.reopen({
      attributeBindings: ['tooltip', 'data-placement'],

      // Set activeParent=LI on a {{link-to}} to automatically propagate the active
      // class to the parent element of that tag name (like <li>{{link-to}}</li>)
      activeParent: null,

      addActiveObserver: function () {
        if (this.get('activeParent')) {
          this.addObserver('active', this, 'activeChanged');
          this.addObserver('application.currentRouteName', this, 'activeChanged');
          this.activeChanged();
        }
      }.on('didInsertElement'),

      activeChanged: function activeChanged() {
        if (this.isDestroyed || this.isDestroying) {
          return;
        }

        var parent = this.$().closest(Ember.get(this, 'activeParent'));

        if (!parent || !parent.length) {
          return;
        }

        var active = !!Ember.get(this, 'active');
        var more = Ember.get(this, 'currentWhen');

        if (!active && more && more.length) {
          var currentRouteName = Ember.get(this, 'application.currentRouteName');

          for (var i = 0; i < Ember.get(more, 'length'); i++) {
            var entry = more.objectAt(i);

            if (currentRouteName === entry || currentRouteName.startsWith(entry + '.')) {
              active = true;
              break;
            }
          }
        }

        parent.toggleClass('active', active);
      }
    });
  }

  exports.default = {
    name: 'extend-ember-link',
    initialize: initialize
  };
});