define('ui/components/container/form-volume-row/component', ['exports', 'ui/components/container/form-volume-row/template', 'ui/components/container/form-volumes/component'], function (exports, _template, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    modalService: Ember.inject.service('modal'),

    layout: _template.default,
    tagName: '',
    editing: true,
    pvcs: null,
    workload: null,

    init: function init() {
      this._super.apply(this, arguments);
      Ember.set(this, 'pvcs', Ember.get(this, 'store').all('persistentVolumeClaim'));
    },
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      var mode = Ember.get(this, 'model.mode');

      if (mode === _component.NEW_VOLUME) {
        Ember.run.next(function () {
          _this.send('defineNewVolume');
        });
      } else if (mode === _component.NEW_VCT) {
        Ember.run.next(function () {
          _this.send('defineNewVct');
        });
      } else if (mode === _component.NEW_PVC) {
        Ember.run.next(function () {
          _this.send('defineNewPvc');
        });
      }
    },


    actions: {
      defineNewVolume: function defineNewVolume() {
        var _this2 = this;

        Ember.get(this, 'modalService').toggleModal('modal-new-volume', {
          model: Ember.get(this, 'model.volume').clone(),
          callback: function callback(volume) {
            Ember.set(_this2, 'model.volume', volume);
          }
        });
      },
      defineNewPvc: function defineNewPvc() {
        var _this3 = this;

        Ember.get(this, 'modalService').toggleModal('modal-new-pvc', {
          model: Ember.get(this, 'model.pvc'),
          namespace: Ember.get(this, 'namespace'),
          callback: function callback(pvc) {
            Ember.set(_this3, 'model.pvc', pvc);
            if (!Ember.get(_this3, 'model.volume.name')) {
              Ember.set(_this3, 'model.volume.name', Ember.get(pvc, 'name'));
            }
          }
        });
      },
      defineNewVct: function defineNewVct() {
        var _this4 = this;

        var modalService = this.modalService;


        modalService.toggleModal('modal-new-vct', {
          model: Ember.get(this, 'model.vct'),
          namespace: Ember.get(this, 'namespace'),
          callback: function callback(vct) {
            Ember.set(_this4, 'model.vct', vct);

            if (!Ember.get(_this4, 'model.name')) {
              Ember.set(_this4, 'model.name', Ember.get(vct, 'name'));
            }
          }
        });
      },
      remove: function remove() {
        this.sendAction('remove');
      },
      addMount: function addMount() {
        var mount = Ember.get(this, 'store').createRecord({ type: 'volumeMount' });

        Ember.get(this, 'model.mounts').pushObject(mount);
      },
      removeMount: function removeMount(mount) {
        Ember.get(this, 'model.mounts').removeObject(mount);
      }
    },

    pvcChoices: Ember.computed('pvcs.@each.{name,state}', 'namespace.id', function () {
      return Ember.get(this, 'pvcs').filterBy('namespaceId', Ember.get(this, 'namespace.id')).map(function (v) {
        var label = Ember.get(v, 'displayName');
        var state = Ember.get(v, 'state');
        var disabled = false;

        if (disabled) {
          label += ' (' + state + ')';
        }

        return {
          label: label,
          disabled: disabled,
          value: Ember.get(v, 'id')
        };
      }).sortBy('label');
    })

  });
});