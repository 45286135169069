define('ui/utils/azure-choices', ['exports', 'shared/utils/azure-choices'], function (exports, _azureChoices) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'regions', {
    enumerable: true,
    get: function () {
      return _azureChoices.regions;
    }
  });
  Object.defineProperty(exports, 'aksRegions', {
    enumerable: true,
    get: function () {
      return _azureChoices.aksRegions;
    }
  });
  Object.defineProperty(exports, 'sizes', {
    enumerable: true,
    get: function () {
      return _azureChoices.sizes;
    }
  });
  Object.defineProperty(exports, 'storageTypes', {
    enumerable: true,
    get: function () {
      return _azureChoices.storageTypes;
    }
  });
  Object.defineProperty(exports, 'environments', {
    enumerable: true,
    get: function () {
      return _azureChoices.environments;
    }
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _azureChoices.default;
    }
  });
});