define('ui/components/modal-new-volume/component', ['exports', 'shared/mixins/modal-base', 'ui/components/modal-new-volume/template'], function (exports, _modalBase, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_modalBase.default, {
    layout: _template.default,
    classNames: ['large-modal'],

    editing: true,

    callback: Ember.computed.alias('modalService.modalOpts.callback'),
    model: Ember.computed.alias('modalService.modalOpts.model'),
    init: function init() {
      this._super.apply(this, arguments);
      if (!this.get('model')) {
        this.set('model', {});
      }
    },


    actions: {
      doSave: function doSave() {
        var callback = this.get('callback');

        if (callback) {
          callback(this.get('model'));
        }

        this.send('cancel');
      }
    }
  });
});