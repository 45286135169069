define('ui/models/statefulset', ['exports', 'ui/models/workload'], function (exports, _workload) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var StatefulSet = _workload.default.extend({});

  exports.default = StatefulSet;
});