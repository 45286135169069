define('ui/components/optionally-namespaced/component', ['exports', 'ui/components/optionally-namespaced/template', 'shared/mixins/view-new-edit'], function (exports, _template, _viewNewEdit) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    tagName: '',

    mode: null,
    scope: null,
    namespace: null,
    model: null,
    namespaceErrors: null,

    isView: Ember.computed.equal('mode', _viewNewEdit.VIEW),
    isNew: Ember.computed.equal('mode', _viewNewEdit.NEW),
    isEdit: Ember.computed.equal('mode', _viewNewEdit.EDIT),
    notView: Ember.computed.or('isNew', 'isEdit')

  });
});