define('ui/app', ['exports', 'ui/resolver', 'ember-load-initializers', 'ui/config/environment'], function (exports, _resolver, _emberLoadInitializers, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var App = Ember.Application.extend({
    modulePrefix: _environment.default.modulePrefix,
    podModulePrefix: _environment.default.podModulePrefix,
    Resolver: _resolver.default,
    engines: {
      login: {
        dependencies: {
          services: ['app', 'access', 'user-language', 'intl', 'modal', 'settings', 'session', 'globalStore', 'router'],
          externalRoutes: {
            index: 'index',
            authenticated: 'authenticated',
            'update-password': 'update-password',
            'update-critical-settings': 'update-critical-settings'
          }
        }
      },
      nodes: {
        dependencies: {
          services: ['access', 'app', 'azureAd', 'catalog', 'clusterStore', 'digitalOcean', 'endpoint', 'github', 'globalStore', 'intl', 'modal', 'resource-actions', 'router', 'scope', 'session', 'settings', 'store', 'tooltip', 'user-language', 'user-theme'],
          externalRoutes: {
            index: 'index',
            failWhale: 'failWhale',
            authenticated: 'authenticated',
            'authenticated.cluster': 'authenticated.cluster',
            'authenticated.cluster.projects': 'authenticated.cluster.projects',
            'authenticated.project': 'authenticated.project',
            'authenticated.prefs': 'authenticated.prefs',
            'authenticated.cluster.nodes': 'authenticated.cluster.nodes',
            'authenticated.cluster.security.members.index': 'authenticated.cluster.security.members.index',
            'global-admin.security.cloud-credentials': 'global-admin.security.cloud-credentials',
            'logout': 'logout'
          }
        }
      },
      globalAdmin: {
        dependencies: {
          services: ['access', 'app', 'azureAd', 'catalog', 'clusterStore', 'digitalOcean', 'endpoint', 'github', 'globalStore', 'intl', 'modal', 'resource-actions', 'router', 'scope', 'session', 'settings', 'store', 'tooltip', 'user-language', 'user-theme'],
          externalRoutes: {
            index: 'index',
            failWhale: 'failWhale',
            authenticated: 'authenticated',
            'authenticated.cluster': 'authenticated.cluster',
            'authenticated.cluster.projects': 'authenticated.cluster.projects',
            'authenticated.project': 'authenticated.project',
            'authenticated.project.apps-tab': 'authenticated.project.apps-tab',
            'authenticated.project.apps-tab.detail': 'authenticated.project.apps-tab.detail',
            'apps-tab': 'apps-tab',
            'apps-tab.detail': 'apps-tab.detail',
            'authenticated.prefs': 'nauthenticated.prefs',
            'authenticated.cluster.nodes': 'authenticated.cluster.nodes',
            'authenticated.cluster.security.members.index': 'authenticated.cluster.security.members.index',
            'nodes.node-templates': 'nodes.node-templates',
            'logout': 'logout'
          }
        }
      },
      logging: {
        dependencies: {
          services: ['app', 'clusterStore', 'globalStore', 'intl', 'modal', 'router', 'resource-actions', 'scope', 'session', 'store', 'tooltip'],
          externalRoutes: {}
        }
      },
      alert: {
        dependencies: {
          services: ['app', 'clusterStore', 'globalStore', 'resource-actions', 'intl', 'modal', 'router', 'scope', 'session', 'store', 'tooltip'],
          externalRoutes: {
            notifier: 'authenticated.cluster.notifier',
            'authenticated.cluster.monitoring.cluster-setting': 'authenticated.cluster.monitoring.cluster-setting',
            'authenticated.project.monitoring.project-setting': 'authenticated.project.monitoring.project-setting'
          }
        }
      },
      pipeline: {
        dependencies: {
          services: ['app', 'clusterStore', 'github', 'globalStore', 'intl', 'modal', 'router', 'resource-actions', 'scope', 'session', 'store', 'tooltip'],
          externalRoutes: {
            index: 'index',
            failWhale: 'failWhale',
            authenticated: 'authenticated',
            'authenticated.cluster': 'authenticated.cluster',
            'authenticated.cluster.index': 'authenticated.cluster.index',
            'authenticated.cluster.projects': 'authenticated.cluster.projects',
            'authenticated.project': 'authenticated.project',
            'authenticated.prefs': 'authenticated.prefs',
            'logout': 'logout',
            'volumes.index': 'volumes.index',
            'authenticated.project.dns.index': 'authenticated.project.dns.index',
            'ingresses.index': 'ingresses.index',
            'containers.index': 'containers.index',
            'authenticated.project.pipeline.pipeline': 'authenticated.project.pipeline.pipeline',
            'authenticated.project.pipeline.pipelines': 'authenticated.project.pipeline.pipelines',
            'notifier': 'authenticated.cluster.notifier'
          }
        }
      },
      monitoring: {
        dependencies: {
          services: ['app', 'intl', 'grafana', 'scope', 'store', 'session', 'modal', 'globalStore', 'router', 'k8s', 'clusterStore', 'tooltip'],
          externalRoutes: {}
        }
      }
    }
  });

  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);

  exports.default = App;
});