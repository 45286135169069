define('ui/components/container-table/component', ['exports', 'ui/components/container-table/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.headersWithStats = exports.headersWithoutHost = exports.headersWithNode = exports.headersAll = undefined;
  var headersAll = exports.headersAll = [{
    name: 'state',
    sort: ['sortState', 'sortName', 'id'],
    searchField: 'displayState',
    translationKey: 'generic.state',
    width: 150
  }, {
    name: 'name',
    sort: ['sortName', 'id'],
    translationKey: 'generic.name'
  }, {
    name: 'image',
    sort: ['image', 'sortName', 'id'],
    searchField: 'image',
    translationKey: 'generic.image'
  }];

  var headersWithNode = exports.headersWithNode = headersAll.filter(function (x) {
    return x.name !== 'stats';
  });
  var headersWithoutHost = exports.headersWithoutHost = headersWithNode.filter(function (x) {
    return x.name !== 'hostName';
  });
  var headersWithStats = exports.headersWithStats = headersAll.filter(function (x) {
    return x.name !== 'hostName';
  });

  exports.default = Ember.Component.extend({
    prefs: Ember.inject.service(),

    layout: _template.default,
    stickyHeader: true,

    showNode: true,
    showStats: false,
    showInstanceState: true,
    pagingLabel: 'pagination.container',
    paging: true,

    sortBy: 'name',

    extraSearchFields: ['displayIp', 'primaryHost.displayName'],

    headers: function () {
      if (this.get('showStats')) {
        return headersWithStats;
      } else if (this.get('showNode')) {
        return headersWithNode;
      } else {
        return headersWithoutHost;
      }
    }.property(),

    filtered: function () {
      var out = this.get('body') || [];

      return out;
    }.property('body.@each.isSystem')
  });
});