define('ui/utils/parse-unit', ['exports', 'shared/utils/parse-unit'], function (exports, _parseUnit) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _parseUnit.default;
    }
  });
  Object.defineProperty(exports, 'formatSi', {
    enumerable: true,
    get: function () {
      return _parseUnit.formatSi;
    }
  });
  Object.defineProperty(exports, 'parseSi', {
    enumerable: true,
    get: function () {
      return _parseUnit.parseSi;
    }
  });
});