define('ui/authenticated/cluster/backups/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    scope: Ember.inject.service(),
    sortBy: 'created',
    currentClusterId: null,

    headers: [{
      name: 'state',
      sort: ['sortState', 'displayName'],
      searchField: 'displayState',
      translationKey: 'generic.state',
      width: 120
    }, {
      name: 'target',
      translationKey: 'backupsPage.table.target.label'
    }, {
      name: 'name',
      sort: ['name', 'id'],
      translationKey: 'backupsPage.table.name'
    }, {
      classNames: 'text-right pr-20',
      name: 'created',
      sort: ['created', 'name', 'id'],
      searchField: false,
      translationKey: 'backupsPage.table.created'
    }],

    rows: Ember.computed('model.[]', function () {
      var currentClusterId = this.currentClusterId;


      return Ember.get(this, 'model').filterBy('clusterId', currentClusterId);
    })
  });
});