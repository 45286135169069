define('ui/models/member', ['exports', '@rancher/ember-api-store/models/resource'], function (exports, _resource) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _resource.default.extend({
    globalStore: Ember.inject.service(),

    principal: Ember.computed('userPrincipalId', 'groupPrincipalId', function () {
      if (Ember.get(this, 'userPrincipalId')) {
        return this.globalStore.getById('principal', this.userPrincipalId);
      } else if (Ember.get(this, 'groupPrincipalId')) {
        return this.globalStore.getById('principal', this.groupPrincipalId);
      }

      return;
    }),

    displayType: Ember.computed('principal.{id}', function () {
      var principal = Ember.get(this, 'principal');
      var type = null;

      if (principal && Ember.get(principal, 'displayType')) {
        type = Ember.get(principal, 'displayType');
      } else if (principal && Ember.get(principal, 'principalType')) {
        type = Ember.get(this, 'principalType');
      }

      return type;
    }),

    displayName: Ember.computed('principal.{id}', function () {
      var principal = Ember.get(this, 'principal');
      var name = null;

      if (principal && Ember.get(principal, 'displayName')) {
        name = Ember.get(principal, 'displayName');
      } else if (Ember.get(this, 'userPrincipalId')) {
        name = Ember.get(this, 'userPrincipalId');
      } else if (Ember.get(this, 'groupPrincipalId')) {
        name = Ember.get(this, 'groupPrincipalId');
      }

      return name;
    })

  });
});