define('ui/models/daemonset', ['exports', 'ui/models/workload'], function (exports, _workload) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var DaemonSet = _workload.default.extend({});

  exports.default = DaemonSet;
});