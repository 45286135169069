define('ui/instance-initializers/lookup', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(application) {
    // Shortcuts for debugging.  These should never be used in code.
    window.l = function (name) {
      return application.lookup(name);
    };

    window.lc = function (name) {
      return application.lookup('controller:' + name);
    };

    window.ls = function (name) {
      return application.lookup('service:' + name);
    };

    window.s = application.lookup('service:store');
    window.cs = application.lookup('service:clusterStore');
    window.gs = application.lookup('service:globalStore');
  }

  exports.default = {
    name: 'lookup',
    initialize: initialize
  };
});